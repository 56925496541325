import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// service
import {
  showPatientTypodont,
  updatePatientTypodontActivityJawUpper,
  updatePatientTypodontActivityJawUnder,
} from "services/Requirement/Dosen";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputPatient from "components/Requirement/InputPatient";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";
import InputStatus from "components/Requirement/InputStatus";

const PasienTypodontAktivasiRahang = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalTitleEn, setModalTitleEn] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    unready: false,
    process: false,
    finish: false,
    date: 0,
    type: "",
  });

  const openModal = (name, name_en, data) => {
    setModalTitle(name);
    setModalTitleEn(name_en);
    setModalData(data);
    setShowModal(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientTypodont(searchParams.get("id"));

      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_typodont
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_typodont
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (modalData.type == "upper") {
            await updatePatientTypodontActivityJawUpper(
              searchParams.get("id"),
              modalData.id,
              modalData
            );
          } else {
            await updatePatientTypodontActivityJawUnder(
              searchParams.get("id"),
              modalData.id,
              modalData
            );
          }

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div className="grid grid-cols-1 gap-y-5">
              {aktivasiRahangAtas.map((value, index) =>
                getStatus(value) == "Selesai" ? (
                  <InputStatus
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                    name_en={`Date of maxillary activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    inputClassName="mb-4"
                    status={getStatus(value, true)}
                    data={value}
                  />
                ) : (
                  <InputPatient
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                    name_en={`Date of maxillary activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    onClick={() => {
                      value.type = "upper";
                      openModal(
                        `Tanggal Aktivasi Rahang Atas ${index + 1}`,
                        `Date of maxillary activation ${index + 1}`,
                        value
                      );
                    }}
                    inputClassName="mb-4"
                  />
                )
              )}
            </div>

            <div className="grid grid-cols-1 gap-y-5">
              {aktivasiRahangBawah.map((value, index) =>
                getStatus(value) == "Selesai" ? (
                  <InputStatus
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                    name_en={`Date of mandibular activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    inputClassName="mb-4"
                    status={getStatus(value, true)}
                    data={value}
                  />
                ) : (
                  <InputPatient
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                    name_en={`Date of mandibular activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    onClick={() => {
                      value.type = "under";
                      openModal(
                        `Tanggal Aktivasi Rahang Bawah ${index + 1}`,
                        `Date of mandibular activation ${index + 1}`,
                        value
                      );
                    }}
                    inputClassName="mb-4"
                  />
                )
              )}
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
      <Modal
        name={`Validasi ${modalTitle}`}
        name_en={`${modalTitleEn} Validation`}
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <>
            <div className="mb-6 flex gap-5 self-center">
              <div
                className={`relative z-0 block  w-1/2 cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-12 pb-2 text-base font-medium text-black transition`}
              >
                {moment(modalData.date).format("D MMMM yyy")}
                <label
                  className={`absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base text-accent-gray duration-300`}
                >
                  {modalTitle} <i className="text-en">{modalTitleEn}</i>
                </label>
              </div>
            </div>
            <div className="grid gap-x-5 lg:grid-cols-3">
              <div>
                <InputToggle
                  name={
                    <>
                      Belum / <i>Incomplete</i>
                    </>
                  }
                  enabled={modalData.unready}
                  onChange={(value) => setModal("unready", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Proses / <i>Process</i>
                    </>
                  }
                  enabled={modalData.process}
                  onChange={(value) => setModal("process", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Sudah / <i>Complete</i>
                    </>
                  }
                  enabled={modalData.finish}
                  onChange={(value) => setModal("finish", value)}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PasienTypodontAktivasiRahang;
