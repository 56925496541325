import axios from "axios";
import headers from "services/Headers";

const createMemberPpdgs = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/surat-perjanjian-peserta-ppdgs`,
    form,
    headers()
  );
};

const showMemberPpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/surat-perjanjian-peserta-ppdgs/student/${id}`,
    headers()
  );
};

export { createMemberPpdgs, showMemberPpdgs };
