import propTypes from "prop-types";
import parse from "html-react-parser";

// components
import { Tooltip } from "@material-tailwind/react";

// assets
import ic_history_log from "assets/icons/ic_history_log.svg";
import moment from "moment";

const InputStatus = (props) => {
  const { name, name_en, value, status, tooltip, data } = props;
  let text = `<span className="leading-snug">${name}`;

  if (status === "success")
    text += ` <span className="text-green">Sudah Divalidasi</span>`;
  else if (status === "failed")
    text += ` <span className="text-red">Belum Divalidasi</span>`;
  else
    text += ` <span className="text-primary-yellow">Sedang Proses Validasi</span>`;

  text += `<i class='text-en'>${name_en}`;

  if (status === "success")
    text += ` <span className="text-green">Validated</span>`;
  else if (status === "failed")
    text += ` <span className="text-red">Not Validated yet</span>`;
  else
    text += ` <span className="text-primary-yellow">Currently Validated</span>`;

  if (data.lecture_log) {
    text += `</i><div className="flex items-center gap-2 mt-2">
      <img src="${ic_history_log}" alt="Icon History Log" />
      <div className="text-white">
        <span className="font-bold block">${data.lecture_log}</span>
        <span className="font-medium">${moment(data.updatedAt).format(
          "D MMMM yyy - hh:mm"
        )}</span>
      </div>
    </div>`;
  }

  return (
    <div className="flex items-center gap-2">
      <div
        className={`w-full rounded-[10px] px-4 py-2  ${
          status === "success"
            ? "bg-[#59CE8F33]"
            : status === "failed"
            ? "bg-[#F3787833]"
            : "bg-[#F4AE0033]"
        }`}
      >
        <span className="block text-base leading-normal text-accent-gray">
          {name} <i className="text-en">{name_en}</i>
        </span>
        <span className="text-lg font-medium text-accent-gray">{value}</span>
      </div>

      <Tooltip content={parse(text)} placement={tooltip}>
        <div
          className={`h-full w-[25px] cursor-pointer rounded-[10px] ${
            status === "success"
              ? "bg-green"
              : status === "failed"
              ? "bg-red"
              : "bg-primary-yellow"
          }`}
        ></div>
      </Tooltip>
    </div>
  );
};

InputStatus.defaultProps = {
  status: "process",
  tooltip: "bottom-start",
};

InputStatus.propTypes = {
  name: propTypes.string.isRequired,
  name_en: propTypes.string,
  value: propTypes.string.isRequired,
  data: propTypes.object.isRequired,
  status: propTypes.oneOf(["success", "failed", "process"]),
  tooltip: propTypes.string,
};

export default InputStatus;
