import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { getSocialService } from "services/Requirement/Prodi";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import InputSearch from "components/Form/InputSearch";
import Detail from "components/Action/Detail";
import InputExport from "components/Requirement/InputExport";

// assets
import ic_search from "assets/icons/ic_search.svg";
import NotFound from "components/NotFound";

const BaktiSosial = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getSocialService(searchParams.get("id"));

      setData(response.data.body.data.social_service);
      setFilteredData(response.data.body.data.social_service);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query) => {
    // Create copy of item list
    var updatedList = [...data];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setSearch(query);
    setFilteredData(updatedList);
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <InputExport className="mb-5" />

          {/* Filter */}
          <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
            <InputSearch
              name="search"
              value={search}
              required={false}
              placeholder="Search judul / title.."
              prepend={<img src={ic_search} alt="Icon search" />}
              onChange={(e) => filterBySearch(e.target.value)}
            />
          </div>

          <div className="mt-5 overflow-x-scroll md:overflow-visible">
            <div className="w-max md:w-full">
              <div className="mb-3 flex justify-between gap-4">
                <span className="text-medium-gray">
                  Keterangan / <i>Information</i>
                </span>
                <span className="ml-64 text-medium-gray">Status</span>
                <span className="mr-5 text-medium-gray">
                  Aksi / <i>Action</i>
                </span>
              </div>

              {data.length ? (
                filteredData.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Tanggal Bakti Sosial {index + 1}
                          <i className="text-en">
                            Date of social service {index + 1}
                          </i>
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {value.title}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Judul Bakti Sosial {index + 1}
                          <i className="text-en">
                            Title of social service {index + 1}
                          </i>
                        </span>
                      </div>
                    </div>

                    <div className="grid w-auto grid-cols-2">
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.unready}
                      />
                      <Status
                        title="Softcopy"
                        className="my-1.5 mx-3"
                        status={value.soft_copy}
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.process}
                      />
                      <Status
                        title="Hardcopy"
                        className="my-1.5 mx-3"
                        status={value.hard_copy}
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.finish}
                      />
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(
                            `detail?no=${index + 1}&id=${
                              value.id
                            }&name=${searchParams.get(
                              "name"
                            )}&npm=${searchParams.get(
                              "npm"
                            )}&avatar=${searchParams.get("avatar")}`
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </TFade>
    </>
  );
};

export default BaktiSosial;
