import axios from "axios";
import headers from "services/Headers";

const createExamMiniCex = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-mini-cex`,
    form,
    headers()
  );
};

const showExamMiniCex = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-mini-cex/student/${id}`,
    headers()
  );
};

export { createExamMiniCex, showExamMiniCex };
