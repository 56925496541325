import axios from "axios";
import headers from "services/Headers";

const showManuscriptJournal = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/manuskrip-jurnal/student/${id}`,
    headers()
  );
};

const updateManuscriptJournal = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/manuskrip-jurnal/${id}`,
    form,
    headers()
  );
};

export { showManuscriptJournal, updateManuscriptJournal };
