import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// services
import { getStudents } from "services/Requirement/Dosen";

// components
import NotFound from "components/NotFound";
import Detail from "components/Action/Detail";
import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";

// assets
import ic_search from "assets/icons/ic_search.svg";

const ListMahasiswa = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [searchName, setSearchName] = useState("");
  const [searchNpm, setSearchNpm] = useState("");
  const [sort, setSort] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fields = [
    {
      name: "Sort",
      value: "",
    },
    {
      name: "Online",
      value: "login",
    },
    {
      name: "Offline",
      value: "logout",
    },
  ];

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const response = await getStudents();

      setData(response.data.body.data.users);
      setFilteredData(response.data.body.data.users);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query, isName = true) => {
    // Create copy of item list
    var updatedList = [...data];
    // Include all elements which includes the search query
    if (isName) {
      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      // Trigger render with updated values
      setSearchName(query);
    } else {
      updatedList = updatedList.filter((item) => {
        return item.npm.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      // Trigger render with updated values
      setSearchNpm(query);
    }
    setFilteredData(updatedList);
  };

  const filterBySort = (sort) => {
    var updatedList = [...data];
    updatedList = updatedList.filter((item) => {
      return item.status_login.toLowerCase().indexOf(sort.toLowerCase()) !== -1;
    });

    setSort(sort);
    setSearchName("");
    setSearchNpm("");
    setFilteredData(updatedList);
  };

  return (
    <>
      {/* Filter */}
      <div className="mb-6 flex flex-wrap gap-5 md:flex-nowrap">
        <InputSearch
          inputClassName="md:w-1/2"
          name="searchName"
          value={searchName}
          required={false}
          placeholder="Search mahasiswa / student.."
          prepend={<img src={ic_search} alt="Icon search" />}
          onChange={(e) => filterBySearch(e.target.value)}
        />

        <InputSearch
          inputClassName="md:w-1/2"
          name="searchNpm"
          value={searchNpm}
          required={false}
          placeholder="Search NPM / registration student number.."
          prepend={<img src={ic_search} alt="Icon search" />}
          onChange={(e) => filterBySearch(e.target.value, false)}
        />

        <InputSelect
          inputClassName="md:w-1/4"
          name="sort"
          value={sort}
          required={false}
          fields={fields}
          isSmall
          onChange={(e) => filterBySort(e.target.value)}
        />
      </div>

      {/* List Mahasiswa */}
      {data.length ? (
        filteredData.map((value, index) => (
          <div
            key={`mahasiswa-${index}`}
            className="mb-4 grid grid-cols-2 items-center justify-between gap-5 rounded-[20px] bg-white px-8 py-5 md:flex"
          >
            {/* Title */}
            <div className="flex gap-5">
              <img
                src={`${process.env.REACT_APP_STORAGE}avatars/${value.avatar}`}
                alt="Avatar"
                className=" h-[50px] w-[50px] rounded-full object-cover"
              />

              <div>
                <h3 className="text-xl font-semibold">{value.name}</h3>
                <span className="block text-base text-accent-gray">
                  {value.npm}
                </span>

                <div
                  className={`mt-2 inline-block rounded-[10px] px-4 py-1 font-medium capitalize text-accent-gray ${
                    value.status_login == "login"
                      ? "bg-[#59CE8F1A]"
                      : "bg-[#F3787833]"
                  }`}
                >
                  {value.status_login == "login" ? "Online" : "Offline"}
                </div>
              </div>
            </div>

            <div>
              <Detail
                onClick={() =>
                  navigate(
                    `/dosen/requirement?id=${value.id}&name=${value.name}&npm=${value.npm}&avatar=${value.avatar}&status_login=${value.status_login}`
                  )
                }
              />
            </div>
          </div>
        ))
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ListMahasiswa;
