import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// services
import headers from "services/Headers";

const initialState = {
  notRead: 0,
};

export const CheckNotification = createAsyncThunk(
  "user/checkNotification",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/notifications/check`,
        headers()
      );
      return response.data.body.data.not_read;
    } catch (error) {
      if (error.response) {
        const response = error.response.data.body;
        return thunkAPI.rejectWithValue(response.message);
      }
    }
  }
);

export const ReadNotification = createAsyncThunk(
  "user/readNotification",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/notifications/${id}/read`,
        headers()
      );
      return response.data.body.message;
    } catch (error) {
      if (error.response) {
        const response = error.response.data.body;
        return thunkAPI.rejectWithValue(response.message);
      }
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Check Notification
    builder.addCase(CheckNotification.fulfilled, (state, action) => {
      state.notRead = action.payload;
    });
  },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;
