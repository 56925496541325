import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import moment from "moment";

// service
import {
  getRequirementById,
  createWireBending,
  getWireBending,
} from "services/Requirement/Mahasiswa";
import { confirmModal, getNilai, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputText from "components/Form/InputText";
import InputFolder from "components/Requirement/InputFolder";
import Status from "components/Requirement/Status";
import Detail from "components/Action/Detail";
import InputSearch from "components/Form/InputSearch";
import InputDate from "components/Form/InputDate";
import InputStatusBig from "components/Requirement/InputStatusBig";

// assets
import ic_search from "assets/icons/ic_search.svg";

const WireBending = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [name, setName] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const responseProgress = await getRequirementById(
        searchParams.get("rqm-id"),
        user.id
      );
      const response = await getWireBending(user.id);

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.wire_bending);
      setFilteredData(response.data.body.data.wire_bending);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query) => {
    // Create copy of item list
    var updatedList = [...data];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setSearch(query);
    setFilteredData(updatedList);
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createWireBending({
            date: moment(date).format("yyy-MM-DD"),
            name: name,
          });

          resetForm();
          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const resetForm = () => {
    setDate("");
    setName("");
  };

  return (
    <>
      <TFade>
        <Navbar title="Requirement" subtitle={<span>Wire Bending</span>} />

        {!data.length ? (
          <Input
            name="Wire Bending"
            name_en="Wire Bending"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
              <InputStatusBig
                name={
                  <>
                    Belum <i className="text-en">Incomplete</i>
                  </>
                }
                enabled={progress.unfinish}
              />
              <InputStatusBig
                name={
                  <>
                    Proses <i className="text-en">Process</i>
                  </>
                }
                enabled={progress.process}
              />
              <InputStatusBig name="100%" enabled={progress.finish} />
            </div>

            <InputFolder
              name="Wire Bending"
              name_en="Wire Bending"
              onClick={() => setShowModal(true)}
            />

            {/* Filter */}
            <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
              <InputSearch
                name="search"
                value={search}
                required={false}
                placeholder="Search nama / name.."
                prepend={<img src={ic_search} alt="Icon search" />}
                onChange={(e) => filterBySearch(e.target.value)}
              />
            </div>

            <div className="mt-5 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-36 text-medium-gray lg:ml-80">
                    Status
                  </span>
                  <span className="ml-12 text-medium-gray">
                    Nilai / <i>Score</i>
                  </span>
                  <span className="mr-5 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {filteredData.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between gap-10 rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">{value.name}</h3>
                        <span className="text-base text-accent-gray">
                          Nama Pola
                          <i className="text-en">Name of pattern</i>
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Tanggal Mulai <i className="text-en">Date of start</i>
                        </span>
                      </div>
                    </div>

                    <div>
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        status={value.unready}
                        className="mx-3 mb-3"
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        status={value.process}
                        className="mx-3 mb-3"
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        status={value.finish}
                        className="mx-3 mb-3"
                      />
                    </div>

                    <div>
                      <span className="flex flex-col items-center justify-center text-xl">
                        {value.value}
                        <svg
                          width="25"
                          height="2"
                          viewBox="0 0 25 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="25" height="1.5" fill="#FFF1CE" />
                        </svg>
                        <b className="text-primary-yellow">
                          {getNilai(value.value)}
                        </b>
                      </span>
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(`detail?no=${index + 1}&id=${value.id}`)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Wire Bending"
        name_en="Wire Bending"
        textSubmit="Kehadiran"
        textSubmitEn="Attendance"
        form={
          <>
            <InputText
              name="name"
              type="text"
              inputClassName="mb-4"
              placeholder={
                <>
                  Nama Pola / <i>Name of pattern</i>
                </>
              }
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputDate
              name="date"
              placeholder={
                <>
                  Tanggal Mulai / <i>Date of start</i>
                </>
              }
              value={date}
              onChange={(value) => setDate(value)}
            />
          </>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default WireBending;
