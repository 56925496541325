import propTypes from "prop-types";

const InputRadio = (props) => {
  const {
    value,
    placeholder,
    name,
    inputClassName,
    required,
    selected,
    disabled,
    isSmall,
  } = props;

  if (isSmall) {
    return (
      <div
        id={name}
        className={[
          inputClassName,
          "flex cursor-pointer select-none items-center gap-4 rounded-[5px] px-3 py-2 text-lg text-accent-gray transition",
        ].join(" ")}
        onClick={() => props.onClick(value)}
      >
        <span
          className={`h-[17px] w-[17px] rounded-full border-[4px] border-transparent bg-[#0000000D] transition ${
            selected && "!border-primary-yellow !bg-white"
          }`}
        ></span>
        <span>{placeholder}</span>
      </div>
    );
  }

  return (
    <div
      id={name}
      className={[
        inputClassName,
        "flex cursor-pointer select-none items-center gap-4 rounded-[5px] border-[0.5px] border-transparent bg-[#d9d9d95b] px-4 py-3 text-lg font-medium text-accent-gray transition",
        selected && "!border-primary-yellow !bg-[#F4AE0033] !text-accent-black",
      ].join(" ")}
      onClick={() => props.onClick(value)}
    >
      <span
        className={`h-[17px] w-[17px] rounded-full border-[4px] border-transparent bg-[#0000000D] transition ${
          selected && "!border-primary-yellow !bg-white"
        }`}
      ></span>
      <span>{placeholder}</span>
    </div>
  );
};

InputRadio.defaultProps = {
  type: "text",
  placeholder: "Please type here..",
  required: true,
  selected: false,
  disabled: false,
  isSmall: false,
};

InputRadio.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onClick: propTypes.func.isRequired,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.element]),
  inputClassName: propTypes.string,
  required: propTypes.bool,
  selected: propTypes.bool,
  disabled: propTypes.bool,
  isSmall: propTypes.bool,
};

export default InputRadio;
