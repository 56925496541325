import { useState, useCallback } from "react";
import propTypes from "prop-types";
import Cropper from "react-easy-crop";

// components
import getCroppedImg from "./CropImage";
import InputRange from "components/Auth/InputRange";
import Button from "components/Button";

const ImageCrop = (props) => {
  const { srcImage } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(srcImage, croppedAreaPixels);
      // console.log("donee", { croppedImage });

      props.onSuccess(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div>
      <div className="relative h-[400px] w-full rounded-[20px] border-[3px] border-[#FFF1CE] bg-[#00000061] ">
        <Cropper
          image={srcImage}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{
            containerStyle: {
              borderRadius: "20px",
            },
          }}
        />
      </div>
      <div>
        <div className="mx-10 mt-4 flex items-center justify-between gap-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM14.14 11.86L11.14 15.73L9 13.14L6 17H18L14.14 11.86Z"
              fill="black"
            />
          </svg>

          <InputRange
            min={1}
            max={3}
            step={0.1}
            value={parseInt(zoom)}
            onChange={(value) => setZoom(value)}
          />

          <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.9583 11.0417V41.9583H11.0417V11.0417H41.9583ZM41.9583 6.625H11.0417C8.6125 6.625 6.625 8.6125 6.625 11.0417V41.9583C6.625 44.3875 8.6125 46.375 11.0417 46.375H41.9583C44.3875 46.375 46.375 44.3875 46.375 41.9583V11.0417C46.375 8.6125 44.3875 6.625 41.9583 6.625ZM31.2258 26.1908L24.6008 34.7371L19.875 29.0175L13.25 37.5417H39.75L31.2258 26.1908Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="mt-10 flex justify-between">
          <Button onClick={props.onCancel}>
            <h2 className="text-left text-lg font-medium text-[#626262] md:text-xl">
              Batal <i className="text-en">Cancel</i>
            </h2>
          </Button>

          <Button onClick={showCroppedImage} isPrimary>
            <h2 className="text-left text-lg font-medium md:text-xl">
              Terapkan <i className="text-en">Apply</i>
            </h2>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageCrop;

ImageCrop.propTypes = {
  srcImage: propTypes.string.isRequired,
  onCancel: propTypes.func.isRequired,
  onSuccess: propTypes.func.isRequired,
};
