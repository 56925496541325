import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { showEducationPpdgs } from "services/Requirement/Prodi";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Export from "components/Action/Export";
import NotFound from "components/NotFound";

const STRPendidikanPPDGS = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showEducationPpdgs(searchParams.get("id"));

      setData(response.data.body.data.ppdgs);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data ? (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-40 text-medium-gray">Status</span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Tanggal STR Pendidikan PPDGS
                      <i className="text-en">Date of PPDGS STR of education</i>
                    </span>

                    <h3 className="mt-5 text-xl font-semibold">
                      {data.no_seri}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Nomor STR Pendidikan PPDGS
                      <i className="text-en">PPDGS STR of education number</i>
                    </span>

                    <h3 className="mt-5 text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")} -{" "}
                      {moment(data.expired).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Masa Berlaku STR Pendidikan PPDGS
                      <i className="text-en">
                        Validity period of PPDGS STR of education
                      </i>
                    </span>
                  </div>

                  <div className="grid w-auto grid-cols-2">
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.unready}
                    />
                    <Status
                      title="Softcopy"
                      className="my-1.5 mx-3"
                      status={data.soft_copy}
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.process}
                    />
                    <Status
                      title="Hardcopy"
                      className="my-1.5 mx-3"
                      status={data.hard_copy}
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.finish}
                    />
                  </div>

                  <div>
                    <Export />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        ) : (
          <NotFound />
        )}

        <Footer />
      </TFade>
    </>
  );
};

export default STRPendidikanPPDGS;
