import axios from "axios";
import headers from "services/Headers";

const createPresenceStudy = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/kehadiran-kuliah-pakar`,
    form,
    headers()
  );
};

const getPresenceStudy = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/kehadiran-kuliah-pakar/student/${id}`,
    headers()
  );
};

const showPresenceStudy = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/kehadiran-kuliah-pakar/task/${id}`,
    headers()
  );
};

export { createPresenceStudy, getPresenceStudy, showPresenceStudy };
