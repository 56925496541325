// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import ListRequirementBackup from "components/Mahasiswa/ListRequirementBackup";

const Backup = () => {
  return (
    <TFade>
      <Navbar title="Backup" />
      <ListRequirementBackup />
      <Footer />
    </TFade>
  );
};

export default Backup;
