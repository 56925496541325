import axios from "axios";
import headers from "services/Headers";

const getPresenceStudy = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/kehadiran-kuliah-pakar/student/${id}`,
    headers()
  );
};

const showPresenceStudy = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/kehadiran-kuliah-pakar/task/${id}`,
    headers()
  );
};

export { getPresenceStudy, showPresenceStudy };
