import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Service
import { toUpperCaseRequirement } from "services/general";
import { getRequirements } from "services/Requirement/Mahasiswa";

// components
import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";

// assets
import ic_search from "assets/icons/ic_search.svg";
import ic_history from "assets/icons/ic_history.svg";

const ListRequirement = () => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fields = [
    {
      name: "Sort",
      value: "",
    },
    {
      name: "Sudah / Complete",
      value: "Selesai",
    },
    {
      name: "Belum / Incomplete",
      value: "Belum Tuntas",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const data = await getRequirements();
      setData(data.data.body.data.requirements);
      setFilteredData(data.data.body.data.requirements);
    } catch (error) {
      console.log(error);
    }
  };

  const filterBySearch = (query) => {
    var updatedList = [...data];
    updatedList = updatedList.filter((item) => {
      return (
        item.requirement.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });

    setSearch(query);
    setSort("");
    setFilteredData(updatedList);
  };

  const filterBySort = (sort) => {
    var updatedList = [...data];
    updatedList = updatedList.filter((item) => {
      return item.status.toLowerCase().indexOf(sort.toLowerCase()) !== -1;
    });

    setSort(sort);
    setSearch("");
    setFilteredData(updatedList);
  };

  return (
    <>
      {/* Filter */}
      <div className="flex flex-wrap gap-5 md:flex-nowrap">
        <InputSearch
          inputClassName="md:w-3/4"
          name="search"
          value={search}
          required={false}
          placeholder="Search requirement.."
          prepend={<img src={ic_search} alt="Icon search" />}
          onChange={(e) => filterBySearch(e.target.value)}
        />

        <InputSelect
          inputClassName="md:w-1/4"
          name="sort"
          value={sort}
          required={false}
          fields={fields}
          isSmall
          onChange={(e) => filterBySort(e.target.value)}
        />
      </div>

      {/* List Requirement */}
      <div className="list-requirement mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
        {filteredData.map((item, index) => (
          <Link
            to={`/mahasiswa/requirement/${item.requirement.slug}?rqm-id=${item.requirement_id}`}
            key={`${item.requirement.name}-${index}`}
            className={`relative grid cursor-pointer grid-cols-[2fr_1fr] items-center justify-between overflow-hidden rounded-[20px] border-2 border-transparent px-5 transition hover:border-primary-yellow ${
              item.status != "Belum Tuntas" ? "bg-[#D7F1E3]" : "bg-white"
            }`}
          >
            <div className="py-8">
              <h3 className="text-xl font-semibold">
                {toUpperCaseRequirement(item.requirement.name)}
                <i className="text-en">{item.requirement.name_en}</i>
              </h3>

              <div className="mt-3 flex items-center gap-4">
                <div
                  className={`flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#0000000A]`}
                >
                  {item.status != "Belum Tuntas" ? (
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7L7 13L17 1"
                        stroke="#59CE8F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <img src={ic_history} alt="Icon History" />
                  )}
                </div>

                <span className="text-sm font-medium text-accent-gray">
                  {item.status}
                  <i className="text-en">{item.status_en}</i>
                </span>
              </div>
            </div>

            <img
              src={`${process.env.REACT_APP_STORAGE}requirements/${item.requirement.image}`}
              alt={item.requirement.name}
              className="h-[200px] object-contain"
            />
          </Link>
        ))}
      </div>
    </>
  );
};

export default ListRequirement;
