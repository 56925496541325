import axios from "axios";
import headers from "services/Headers";

const createExamCbt = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-cbt`,
    form,
    headers()
  );
};

const showExamCbt = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-cbt/student/${id}`,
    headers()
  );
};

export { createExamCbt, showExamCbt };
