import propTypes from "prop-types";

const InputStatusBig = (props) => {
  const { name, enabled, inputClassName } = props;

  return (
    <div
      className={[
        `flex items-center justify-center rounded-[20px] text-center text-3xl font-semibold text-white md:py-8`,
        !enabled ? "bg-red" : "bg-green",
        inputClassName,
      ].join(" ")}
    >
      <span>{name}</span>
    </div>
  );
};

InputStatusBig.propTypes = {
  name: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
  enabled: propTypes.bool.isRequired,
  inputClassName: propTypes.string,
};

export default InputStatusBig;
