import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

// language
import languages from "languages/id_en";

// service
import {
  getRequirementById,
  createPatientClinic,
  getPatientClinic,
  exportJawUndersPatient,
  exportToolsPatient,
  exportMaterialsPatient,
  exportJawUppersPatient,
} from "services/Requirement/Mahasiswa";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputText from "components/Form/InputText";
import InputFolder from "components/Requirement/InputFolder";
import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import InputStatusBig from "components/Requirement/InputStatusBig";

// assets
import ic_search from "assets/icons/ic_search.svg";
import ic_eye_green from "assets/icons/ic_eye_green.svg";
import InputExport from "components/Requirement/InputExport";

const PasienKlinik = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState("");

  const genderFields = [
    {
      name: "Jenis Kelamin / Gender",
      value: "",
    },
    {
      name: "Laki-laki / Male",
      value: "Laki-laki",
    },
    {
      name: "Perempuan / Female",
      value: "Perempuan",
    },
  ];

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const responseProgress = await getRequirementById(
        searchParams.get("rqm-id"),
        user.id
      );
      const response = await getPatientClinic(user.id);

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.patient);
      setFilteredData(response.data.body.data.patient);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query) => {
    var updatedList = [...data];
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setSearch(query);
    setFilteredData(updatedList);
  };

  const submit = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPatientClinic({
            name: name,
            gender: gender,
          });

          resetForm();
          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const resetForm = () => {
    setName("");
    setGender("");
  };

  const exportMaterial = async () => {
    const response = await exportMaterialsPatient(
      searchParams.get("rqm-id"),
      user.id
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${response.data.body.data.file}`,
      "_blank"
    );
  };

  const exportTool = async () => {
    const response = await exportToolsPatient(
      searchParams.get("rqm-id"),
      user.id
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${response.data.body.data.file}`,
      "_blank"
    );
  };

  const exportJaw = async () => {
    const responseUnders = await exportJawUndersPatient(
      searchParams.get("rqm-id"),
      user.id
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${responseUnders.data.body.data.file}`,
      "_blank"
    );

    const responseUppers = await exportJawUppersPatient(
      searchParams.get("rqm-id"),
      user.id
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${responseUppers.data.body.data.file}`,
      "_blank"
    );
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Pasien Klinik <i className="block">Clinic Patient</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Pasien Klinik"
            name_en="Clinic Patient"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <div className="mb-5 grid grid-cols-1 gap-5 md:grid-cols-3">
              <InputExport
                text="Pengambilan Bahan"
                textEn="Material Pickup"
                isExport={false}
                onClick={exportMaterial}
              />
              <InputExport
                text="Jenis Peranti Ortodonti"
                textEn="Type of orthodontics appliance"
                isExport={false}
                onClick={exportTool}
              />
              <InputExport
                text="Aktivasi Rahang"
                textEn="Upper & Lower Activation"
                isExport={false}
                onClick={exportJaw}
              />
            </div>

            <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
              <InputStatusBig
                name={
                  <>
                    Belum <i className="text-en">Incomplete</i>
                  </>
                }
                enabled={progress.unfinish}
              />
              <InputStatusBig
                name={
                  <>
                    Proses <i className="text-en">Process</i>
                  </>
                }
                enabled={progress.process}
              />
              <InputStatusBig name="100%" enabled={progress.finish} />
            </div>

            <InputFolder
              name="Pasien Klinik"
              name_en="Clinic Patient"
              onClick={() => setShowModal(true)}
            />

            {/* Filter */}
            <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
              <InputSearch
                name="search"
                value={search}
                required={false}
                placeholder="Search pasien / patient.."
                prepend={<img src={ic_search} alt="Icon search" />}
                onChange={(e) => filterBySearch(e.target.value)}
              />
            </div>

            <div className="mt-5 overflow-x-scroll md:overflow-visible">
              <div className="grid w-max grid-cols-1 gap-4 md:w-full md:grid-cols-2">
                {filteredData.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="grid grid-cols-2 justify-between gap-6 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <div>
                      <h3 className="text-xl font-semibold">{value.name}</h3>
                      <span className="text-base text-accent-gray">
                        Pasien ke {index + 1} | {value.gender}
                        <i className="text-en">
                          Patient to {index + 1} | {languages[value.gender]}
                        </i>
                      </span>

                      {value.finish ? (
                        <div className="mt-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-2 font-medium leading-[18px]">
                          Sudah Divalidasi
                          <i className="text-en">Validated</i>
                        </div>
                      ) : value.process ? (
                        <div className="mt-3 rounded-[10px] bg-[#F4AE0040] px-4 py-2 font-medium leading-[18px]">
                          Sedang Divalidasi
                          <i className="text-en">Currently Validated</i>
                        </div>
                      ) : (
                        <div className="mt-3 rounded-[10px] bg-[#F3787840] px-4 py-2 font-medium leading-[18px]">
                          Belum Divalidasi
                          <i className="text-en">Not Validated yet</i>
                        </div>
                      )}
                    </div>

                    <div>
                      <div
                        onClick={() => navigate(`detail?id=${value.id}`)}
                        className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                      >
                        Detail
                        <img src={ic_eye_green} alt="Icon Eye" />
                      </div>

                      <div className="flex items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray">
                        <span>
                          {getStatus(value)}{" "}
                          <i className="text-en">{getStatusEn(value)}</i>
                        </span>
                        <span className="h-[20px] w-[20px] rounded-full bg-green"></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Pasien Klinik"
        name_en="Clinic Patient"
        textSubmit="Pasien"
        textSubmitEn="Patient"
        form={
          <>
            <InputText
              name="name"
              type="text"
              placeholder={
                <>
                  Nama Pasien / <i>Patient’s name</i>
                </>
              }
              inputClassName="mb-4"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputSelect
              name="gender"
              placeholder={
                <>
                  Jenis Kelamin / <i>Gender</i>
                </>
              }
              value={gender}
              fields={genderFields}
              onChange={(e) => setGender(e.target.value)}
            />
          </>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default PasienKlinik;
