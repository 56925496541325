import axios from "axios";
import headers from "services/Headers";

const createSocialService = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/bakti-sosial`,
    form,
    headers()
  );
};

const getSocialService = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/bakti-sosial/student/${id}`,
    headers()
  );
};

const showSocialService = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/bakti-sosial/task/${id}`,
    headers()
  );
};

export { createSocialService, getSocialService, showSocialService };
