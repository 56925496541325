import { useState } from "react";
import propTypes from "prop-types";
import { templateFormatter, templateParser, parseDigit } from "input-format";
import ReactInput from "input-format/react";

const InputFormat = (props) => {
  const {
    name,
    template,
    placeholder,
    value,
    inputClassName,
    errorResponse,
    required,
    disabled,
    isError,
  } = props;

  const [hasError, setHasError] = useState(null);

  const onChange = (value) => {
    if (isError) {
      if (value.length !== template.split(" - ").join("").length)
        setHasError(errorResponse);
      else setHasError(null);
    }

    props.onChange(value);
  };

  return (
    <div className={[inputClassName].join(" ")}>
      <div className="relative z-0 overflow-hidden">
        <ReactInput
          id={name}
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          onChange={(value) => onChange(value)}
          format={templateFormatter(template)}
          parse={templateParser(template, parseDigit)}
          className={`peer block w-full appearance-none rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-2 text-base font-medium text-black transition placeholder-shown:pt-3 focus:border-gray-500 focus:pt-5 focus:outline-none focus:ring-0 ${
            hasError && "border-red focus:border-red"
          }`}
          placeholder=" "
        />
        <label
          htmlFor={name}
          className={`absolute top-3 left-3 -z-10 origin-[0] -translate-y-2 scale-75 transform text-base text-accent-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-accent-gray ${
            hasError && "text-red peer-focus:text-red"
          }`}
        >
          {placeholder}
        </label>
      </div>

      {hasError && <span className="mt-1 text-sm text-red">{hasError}</span>}
    </div>
  );
};

export default InputFormat;

InputFormat.defaultProps = {
  placeholder: "Please type here..",
  errorResponse: "Please match the requested format.",
  required: true,
  disabled: false,
  isError: true,
};

InputFormat.propTypes = {
  name: propTypes.string.isRequired,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.element])
    .isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  inputClassName: propTypes.string,
  template: propTypes.string,
  required: propTypes.bool,
  disabled: propTypes.bool,
  isError: propTypes.bool,
};
