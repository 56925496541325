import axios from "axios";
import headers from "services/Headers";

const createCompetencePpdgs = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/sertifikat-kompetensi-ppdgs`,
    form,
    headers()
  );
};

const showCompetencePpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/sertifikat-kompetensi-ppdgs/student/${id}`,
    headers()
  );
};

export { createCompetencePpdgs, showCompetencePpdgs };
