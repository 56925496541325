import React from "react";
import propTypes from "prop-types";

const StepHeader = (props) => {
  return (
    <div className="hidden h-full w-full items-center lg:flex lg:w-1/2">
      <div>
        <div
          className={`group mb-8 flex items-center ${
            props.currentStep === 1 ? "active" : "opacity-50"
          }`}
        >
          <span className="mr-10 h-[4px] w-[210px] bg-accent-black opacity-0 transition duration-300 group-[&.active]:opacity-100"></span>
          <h1 className="flex text-3xl font-semibold text-accent-gray group-[&.active]:text-accent-black xl:text-4xl">
            1.
            <span className="ml-2">
              Isi Data Diri <i className="text-en">Fill in Personal Data</i>
            </span>
          </h1>
        </div>

        <div
          className={`group mb-8 flex items-center ${
            props.currentStep === 2 ? "active" : "opacity-50"
          }`}
        >
          <span className="mr-10 h-[4px] w-[210px] bg-accent-black opacity-0 transition duration-300 group-[&.active]:opacity-100"></span>
          <h1 className="flex text-3xl font-semibold text-accent-gray group-[&.active]:text-accent-black xl:text-4xl">
            2.
            <span className="ml-2">
              Isi Avatar <i className="text-en">Fill in Avatar</i>
            </span>
          </h1>
        </div>

        <div
          className={`group mb-8 flex items-center ${
            props.currentStep === 3 ? "active" : "opacity-50"
          }`}
        >
          <span className="mr-10 h-[4px] w-[210px] bg-accent-black opacity-0 transition duration-300 group-[&.active]:opacity-100"></span>
          <h1 className="flex text-3xl font-semibold text-accent-gray group-[&.active]:text-accent-black xl:text-4xl">
            3.
            <span className="ml-2">
              Verifikasi Data <i className="text-en">Verification Data</i>
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default StepHeader;

StepHeader.propTypes = {
  currentStep: propTypes.number.isRequired,
};
