import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { showExamDops } from "services/Requirement/Prodi";
import { getNilai } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Export from "components/Action/Export";
import NotFound from "components/NotFound";

const UjianDOPS = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showExamDops(searchParams.get("id"));

      setData(response.data.body.data.dops);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data ? (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Tanggal Ujian DOPS / <i>Date of DOPS exam</i>
                  </span>
                  <span className="mr-36 text-medium-gray">Status</span>
                  <span className="text-medium-gray">
                    Nilai / <i>Score</i>
                  </span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Ujian DOPS <i className="text-en">DOPS Exam</i>
                    </span>
                  </div>

                  <div>
                    <Status
                      title={
                        <>
                          Lulus <i className="text-en">Passed</i>
                        </>
                      }
                      status={data.status}
                    />
                  </div>

                  <span className="ml-16 flex flex-col items-center justify-center text-xl">
                    {data.value}
                    <svg
                      width="25"
                      height="2"
                      viewBox="0 0 25 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="25" height="1.5" fill="#FFF1CE" />
                    </svg>
                    <b className="text-primary-yellow">
                      {getNilai(data.value)}
                    </b>
                  </span>

                  <div className="mr-2">
                    <Export />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        ) : (
          <NotFound />
        )}

        <Footer />
      </TFade>
    </>
  );
};

export default UjianDOPS;
