import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// service
import { showPatientTypodont } from "services/Requirement/Prodi";
import { getStatus } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputStatus from "components/Requirement/InputStatus";

const PasienTypodontAktivasiRahang = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientTypodont(searchParams.get("id"));

      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_typodont
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_typodont
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div className="grid grid-cols-1 gap-y-5">
              {aktivasiRahangAtas.map((value, index) => (
                <InputStatus
                  key={`value-${index}`}
                  name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                  name_en={`Date of maxillary activation ${index + 1}`}
                  value={
                    value.date ? moment(value.date).format("D MMMM yyy") : "-"
                  }
                  inputClassName="mb-4"
                  status={getStatus(value, true)}
                  data={value}
                />
              ))}
            </div>

            <div className="grid grid-cols-1 gap-y-5">
              {aktivasiRahangBawah.map((value, index) => (
                <InputStatus
                  key={`value-${index}`}
                  name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                  name_en={`Date of mandibular activation ${index + 1}`}
                  value={
                    value.date ? moment(value.date).format("D MMMM yyy") : "-"
                  }
                  inputClassName="mb-4"
                  status={getStatus(value, true)}
                  data={value}
                />
              ))}
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienTypodontAktivasiRahang;
