import { useEffect, useState } from "react";
import propTypes from "prop-types";
import toast from "react-hot-toast";

// components
import { Fade } from "react-awesome-reveal";
import InputFile from "components/Auth/InputFile";
import ImageCrop from "components/ImageCrop";

// assets
import ic_arrow_left_black from "assets/icons/ic_arrow_left_black.svg";
import ic_arrow_right_black from "assets/icons/ic_arrow_right_black.svg";

const PersonalAvatar = (props) => {
  let { form } = props;

  const [avatar, setAvatar] = useState(null);
  const [src, setSrc] = useState("");
  const [onCrop, setOnCrop] = useState(false);

  useEffect(() => {
    setAvatarUser(form.avatar);
  }, [form]);

  const setAvatarUser = (avatar) => {
    setAvatar(avatar);

    if (!src && avatar) {
      const fileReader = new FileReader();

      // File on load
      fileReader.onload = () => {
        setSrc(fileReader.result);
        setOnCrop(true);
      };

      fileReader.readAsDataURL(avatar);
    } else {
      setSrc("");
      setOnCrop(false);
    }
  };

  const createAvatar = (image) => {
    const newAvatar = new File([image], "image.jpeg", {
      type: "image/jpeg",
    });

    setAvatar(newAvatar);
    setOnCrop(false);
  };

  const submit = (e) => {
    e.preventDefault();

    if (avatar === null) {
      toast.error(
        <span>
          Mohon masukkan avatar terlebih dahulu!
          <i className="text-en">Please enter the avatar first!</i>
        </span>
      );
      return;
    }

    form = {
      ...form,
      avatar,
    };

    props.updateForm(form);
  };

  return (
    <Fade duration={750} className="w-full px-5 xl:px-14">
      {/* Form */}
      <form onSubmit={submit}>
        <h1 className="mb-5 text-center text-3xl font-semibold text-accent-black lg:hidden">
          Isi Avatar
          <i className="text-en">Isi Avatar</i>
        </h1>

        {onCrop ? (
          <ImageCrop
            srcImage={src}
            onCancel={() => setAvatarUser(null)}
            onSuccess={(image) => createAvatar(image)}
          />
        ) : (
          <InputFile
            name="avatar"
            value={avatar}
            onChange={(file) => setAvatarUser(file)}
          />
        )}

        {!onCrop && (
          <div className="mt-10 flex justify-between">
            <button
              type="button"
              className="flex items-center"
              onClick={props.onBack}
            >
              <div className="icon-arrow mr-3 border-black">
                <img src={ic_arrow_left_black} alt="Icon Arrow Left" />
              </div>
              <h2 className="text-lg font-medium md:text-xl">
                Kembali <i className="text-en">Back</i>
              </h2>
            </button>

            <button type="submit" className="flex items-center">
              <h2 className="text-lg font-medium md:text-xl">
                Lanjut <i className="text-en">Next</i>
              </h2>
              <div className="icon-arrow ml-3 border-black">
                <img src={ic_arrow_right_black} alt="Icon Arrow Right" />
              </div>
            </button>
          </div>
        )}
      </form>
    </Fade>
  );
};

export default PersonalAvatar;

PersonalAvatar.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
