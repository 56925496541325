import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import {
  showPatientClinic,
  createPatientClinicActivityJawUpper,
  createPatientClinicActivityJawUnder,
  updateDataRadiografyPatientClinic,
} from "services/Requirement/Mahasiswa";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
  toCapitalizeEachWord,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import InputFolder from "components/Requirement/InputFolder";
import InputStatus from "components/Requirement/InputStatus";
import InputDateTypodont from "components/Form/InputDateTypodont";
import Min from "components/Action/Min";

const PasienKlinikAktivasiRahang = () => {
  const [hasData, setHasData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [radiografy, setRadiografy] = useState([]);
  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([{ date: "" }]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([
    { date: "" },
  ]);

  const changeAktivasiRahangAtas = (value, index) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPatientClinicActivityJawUpper(searchParams.get("id"), {
            date: moment(value).format("yyy-MM-DD"),
          });

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const addAktivasiRahangAtas = () => {
    const newValues = [...aktivasiRahangAtas];
    newValues.push({ date: "" });

    setAktivasiRahangAtas(newValues);
  };

  const removeAktivasiRahangAtas = (index) => {
    const newValues = [...aktivasiRahangAtas];
    newValues.splice(index, 1);

    setAktivasiRahangAtas(newValues);
  };

  const changeAktivasiRahangBawah = (value, index) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPatientClinicActivityJawUnder(searchParams.get("id"), {
            date: moment(value).format("yyy-MM-DD"),
          });

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });

    // setAktivasiRahangBawah(newValues);
  };

  const addAktivasiRahangBawah = () => {
    const newValues = [...aktivasiRahangBawah];
    newValues.push({ date: "" });

    setAktivasiRahangBawah(newValues);
  };

  const removeAktivasiRahangBawah = (index) => {
    const newValues = [...aktivasiRahangBawah];
    newValues.splice(index, 1);

    setAktivasiRahangBawah(newValues);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinic(searchParams.get("id"));

      setRadiografy(response.data.body.data.patient.date_radiografy);
      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_clinic
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_clinic
      );
    } catch (error) {
      console.error(error);
    }
  };

  const changeDataRadiografy = async (value, index) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateDataRadiografyPatientClinic(
            searchParams.get("id"),
            radiografy[index].id,
            {
              value: moment(value).format("yyy-MM-DD"),
            }
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Pasien Klinik <i className="block">Clinic Patient</i>
            </span>
          }
        />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div>
              <div className="grid grid-cols-1 gap-y-5">
                {radiografy.length &&
                  (radiografy[0].value ? (
                    <InputStatus
                      key={`radiografy-1`}
                      name={toCapitalizeEachWord(radiografy[0].name)}
                      name_en={languages[radiografy[0].name]}
                      value={moment(radiografy[0].value).format("D MMMM yyy")}
                      status={getStatus(radiografy[0], true)}
                      data={radiografy[0]}
                    />
                  ) : (
                    <InputDateTypodont
                      key={`radiografy-1`}
                      name={radiografy[0].name}
                      value={radiografy[0].value ? radiografy[0].value : ""}
                      placeholder={
                        <>
                          {toCapitalizeEachWord(radiografy[0].name)}
                          <i className="text-en">
                            {languages[radiografy[0].name]}
                          </i>
                        </>
                      }
                      onChange={(value) => changeDataRadiografy(value, 0)}
                    />
                  ))}
              </div>

              <InputFolder
                name="Aktivasi Rahang Atas"
                name_en="Maxillary activation"
                className="mt-5 mb-8 !py-6"
                onClick={() => addAktivasiRahangAtas()}
              />
              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangAtas.map((value, index) =>
                  value.id ? (
                    <InputStatus
                      key={`aktivasiRahangAtas-${index}`}
                      name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                      name_en={`Date of maxillary activation ${index + 1}`}
                      value={moment(value.date).format("D MMMM yyy")}
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <div className="flex gap-3">
                      <InputDateTypodont
                        key={`aktivasiRahangAtas-${index}`}
                        name={`aktivasiRahangAtas-${index}`}
                        value={value.date ? value.date : ""}
                        inputClassName="mb-4 w-full"
                        placeholder={
                          <>
                            Tanggal Aktivasi Rahang Atas {index + 1}
                            <i className="text-en">
                              Date of maxillary activation {index + 1}
                            </i>
                          </>
                        }
                        onChange={(value) =>
                          changeAktivasiRahangAtas(value, index)
                        }
                      />
                      <Min onClick={() => removeAktivasiRahangAtas(index)} />
                    </div>
                  )
                )}
              </div>
            </div>

            <div>
              <div className="grid grid-cols-1 gap-y-5">
                {radiografy.length &&
                  (radiografy[1].value ? (
                    <InputStatus
                      key={`radiografy-2`}
                      name={toCapitalizeEachWord(radiografy[1].name)}
                      name_en={languages[radiografy[1].name]}
                      value={moment(radiografy[1].value).format("D MMMM yyy")}
                      status={getStatus(radiografy[1], true)}
                      data={radiografy[1]}
                    />
                  ) : (
                    <InputDateTypodont
                      key={`radiografy-2`}
                      name={radiografy[1].name}
                      value={radiografy[1].value ? radiografy[1].value : ""}
                      placeholder={
                        <>
                          {toCapitalizeEachWord(radiografy[1].name)}
                          <i className="text-en">
                            {languages[radiografy[1].name]}
                          </i>
                        </>
                      }
                      onChange={(value) => changeDataRadiografy(value, 1)}
                    />
                  ))}
              </div>

              <InputFolder
                name="Aktivasi Rahang Bawah"
                name_en="Mandibular activation"
                className="mt-5 mb-8 !py-6"
                onClick={() => addAktivasiRahangBawah()}
              />

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangBawah.map((value, index) =>
                  value.id ? (
                    <InputStatus
                      key={`aktivasiRahangBawah-${index}`}
                      name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                      name_en={`Date of mandibular activation ${index + 1}`}
                      value={moment(value.date).format("D MMMM yyy")}
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <div className="flex items-center gap-3">
                      <InputDateTypodont
                        key={`aktivasiRahangBawah-${index}`}
                        name={`aktivasiRahangBawah-${index}`}
                        value={value.date ? value.date : ""}
                        inputClassName="mb-4 w-full"
                        placeholder={
                          <>
                            Tanggal Aktivasi Rahang Bawah {index + 1}
                            <i className="text-en">
                              Date of mandibular activation {index + 1}
                            </i>
                          </>
                        }
                        onChange={(value) =>
                          changeAktivasiRahangBawah(value, index)
                        }
                      />

                      <Min onClick={() => removeAktivasiRahangBawah(index)} />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienKlinikAktivasiRahang;
