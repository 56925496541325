import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import {
  showOrientationRsgm,
  updateOrientationRsgm,
} from "services/Requirement/Dosen";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Edit from "components/Action/Edit";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";
import NotFound from "components/NotFound";

const OrientasiRSGM = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    unready: false,
    process: false,
    finish: false,
    soft_copy: false,
    hard_copy: false,
    date: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showOrientationRsgm(searchParams.get("id"));

      setData(response.data.body.data.rsgm);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateOrientationRsgm(modalData.id, modalData);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data ? (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-24 text-medium-gray">Status</span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {searchParams.get("name")}
                    </h3>
                    <span className="block text-base text-accent-gray">
                      {searchParams.get("npm")}
                    </span>

                    <h3 className="mt-5 text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Tanggal Orientasi RSGM
                      <i className="text-en">Date of RSGM orientation</i>
                    </span>
                  </div>

                  <div className="grid w-auto grid-cols-2">
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.unready}
                    />
                    <Status
                      title="Softcopy"
                      className="my-1.5 mx-3"
                      status={data.soft_copy}
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.process}
                    />
                    <Status
                      title="Hardcopy"
                      className="my-1.5 mx-3"
                      status={data.hard_copy}
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.finish}
                    />
                  </div>

                  <div className="mr-5">
                    <Edit
                      onClick={() => {
                        setModalData(data);
                        setShowModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        ) : (
          <NotFound />
        )}

        <Footer />
      </TFade>
      <Modal
        name="Validasi Orientasi RSGM"
        name_en="RSGM orientation validation"
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <div className="grid gap-x-14 gap-y-10 lg:grid-cols-2">
            <div>
              <h3 className="text-xl font-semibold">
                {searchParams.get("name")}
              </h3>
              <span className="block text-base text-accent-gray">
                {searchParams.get("npm")}
              </span>
            </div>

            {/* Title */}
            <div>
              <h3 className="text-xl font-semibold">
                {moment(modalData.date).format("D MMMM yyy")}
              </h3>
              <span className="text-base text-accent-gray">
                Tanggal Orientasi RSGM
                <i className="text-en">Date of RSGM orientation</i>
              </span>
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Belum / <i>Incomplete</i>
                  </>
                }
                inputClassName="mb-3"
                enabled={modalData.unready}
                onChange={(value) => setModal("unready", value)}
              />

              <InputToggle
                name={
                  <>
                    Proses / <i>Process</i>
                  </>
                }
                inputClassName="mb-3"
                enabled={modalData.process}
                onChange={(value) => setModal("process", value)}
              />

              <InputToggle
                name={
                  <>
                    Sudah / <i>Complete</i>
                  </>
                }
                enabled={modalData.finish}
                onChange={(value) => setModal("finish", value)}
              />
            </div>

            <div>
              <InputToggle
                name="Softcopy"
                inputClassName="mb-3"
                enabled={modalData.soft_copy}
                onChange={(value) => setModal("soft_copy", value)}
              />

              <InputToggle
                name="Hardcopy"
                enabled={modalData.hard_copy}
                onChange={(value) => setModal("hard_copy", value)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default OrientasiRSGM;
