import axios from "axios";
import headers from "services/Headers";

const showOrientationRsgm = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/orientasi-rsgm/student/${id}`,
    headers()
  );
};

export { showOrientationRsgm };
