import axios from "axios";
import headers from "services/Headers";

const createPresentationPoster = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/presentasi-poster-atau-pembicara`,
    form,
    headers()
  );
};

const showPresentationPoster = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/presentasi-poster-atau-pembicara/student/${id}`,
    headers()
  );
};

export { createPresentationPoster, showPresentationPoster };
