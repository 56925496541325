import axios from "axios";
import headers from "services/Headers";

const createPatientTypodont = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont`,
    form,
    headers()
  );
};

const getPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/student/${id}`,
    headers()
  );
};

const showPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/task/${id}`,
    headers()
  );
};

const getToolsPatientTypodont = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/tools`,
    headers()
  );
};

const createPatientTypodontActivityJawUpper = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/activity-jaw-upper/${id}`,
    form,
    headers()
  );
};

const createPatientTypodontActivityJawUnder = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/activity-jaw-under/${id}`,
    form,
    headers()
  );
};

const updateValueDataPatientTypodont = (id, idData, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/data-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const createActivityMaterialPatientTypodont = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/activity-material/${id}`,
    form,
    headers()
  );
};

const createToolPatientTypodont = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-typodont/patient-tools/${id}`,
    form,
    headers()
  );
};

const exportToolsPatient = (id, studentId) => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/tools`,
    {
      student: studentId,
      requirement_id: id,
    },
    headers()
  );
};

const exportMaterialsPatient = (id, studentId) => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/materials`,
    {
      student: studentId,
      requirement_id: id,
    },
    headers()
  );
};

const exportJawUndersPatient = (id, studentId) => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/jaw-unders`,
    {
      student: studentId,
      requirement_id: id,
    },
    headers()
  );
};

const exportJawUppersPatient = (id, studentId) => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/jaw-uppers`,
    {
      student: studentId,
      requirement_id: id,
    },
    headers()
  );
};

export {
  createPatientTypodont,
  getPatientTypodont,
  showPatientTypodont,
  getToolsPatientTypodont,
  createPatientTypodontActivityJawUpper,
  createPatientTypodontActivityJawUnder,
  updateValueDataPatientTypodont,
  createActivityMaterialPatientTypodont,
  createToolPatientTypodont,
  exportToolsPatient,
  exportMaterialsPatient,
  exportJawUndersPatient,
  exportJawUppersPatient,
};
