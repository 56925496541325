import { useState } from "react";
import propTypes from "prop-types";
import OtpInput from "react18-input-otp";
import Countdown from "react-countdown";
import toast from "react-hot-toast";

const InputOtp = (props) => {
  const { value, numInputs } = props;

  const [time, setTime] = useState(Date.now() + 5 * 60 * 1000);

  const handleChange = (value) => {
    props.onChange(value);

    if (value.length === numInputs) props.onComplete(value);
  };

  const renderer = ({ minutes, seconds }) => {
    return (
      <>
        {minutes}:{seconds}
      </>
    );
  };

  const resend = () => {
    setTime(Date.now() + 5 * 60 * 1000);
    toast.success(
      <span>
        Kode OTP berhasil dikirim!
        <i className="text-en">OTP code sent successfully!</i>
      </span>
    );
    props.onResend();
  };

  return (
    <div>
      <OtpInput
        value={value}
        onChange={handleChange}
        numInputs={numInputs}
        separator={<span className="mx-3"></span>}
        containerStyle="justify-center"
        inputStyle="border-b-2 border-accent-gray text-2xl font-semibold outline-none bg-transparent md:text-[5rem] lg:!w-[125px]"
        focusStyle="border-green"
      />

      <div className="mt-3 items-center justify-between lg:flex">
        <div className="block text-base">
          <span>
            Tidak Mendapatkan Kode OTP?{" "}
            <span
              onClick={resend}
              className="cursor-pointer font-semibold text-primary-yellow"
            >
              Klik disini
            </span>
          </span>

          <span className="text-en">
            Not Getting OTP Code?{" "}
            <span
              onClick={resend}
              className="cursor-pointer font-semibold text-primary-yellow"
            >
              Click here
            </span>
          </span>
        </div>

        <span className="text-base text-accent-gray">
          <Countdown date={time} renderer={renderer} />
        </span>
      </div>
    </div>
  );
};

export default InputOtp;

InputOtp.defaultProps = {
  numInputs: 6,
};

InputOtp.propTypes = {
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  onComplete: propTypes.func.isRequired,
  onResend: propTypes.func.isRequired,
  numInputs: propTypes.number,
};
