import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

// features
import { RegisterUser, resetMsg, resetSuccess } from "features/authSlice";

// components
import TFade from "components/Transition/Fade";
import StepHeader from "components/Step/StepHeader";
import PersonalData from "components/Step/PersonalData";
import PersonalAvatar from "components/Step/PersonalAvatar";
import VerificationData from "components/Step/VerificationData";

// assets
import logo from "assets/images/logo.png";
import chilling from "assets/images/chilling.svg";
import sittingReading from "assets/images/sitting-reading.svg";
import readingSide from "assets/images/reading-side.svg";

const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [form, setForm] = useState({
    fullName: "",
    npm: "",
    phoneNumber: "",
    email: "",
    password: "",
    avatar: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isLoading, message, listMessage } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (message) toast.error(message);
    if (listMessage) {
      listMessage.forEach((message) => toast.error(message.msg));
    }
    dispatch(resetMsg());
  }, [message, listMessage, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Register success");
      navigate("/login");
    }
  }, [isSuccess, navigate]);

  const updateForm = (value) => {
    setForm(value);

    if (currentStep < 3) setCurrentStep((state) => state + 1);
    else Auth(value);
  };

  const Auth = (value) => {
    dispatch(
      RegisterUser({
        name: value.fullName,
        email: value.email,
        password: value.password,
        phone: value.phoneNumber,
        npm: value.npm,
        avatar: value.avatar,
      })
    );
  };

  return (
    <>
      <div className="auth-wrap mb-10 flex h-full flex-wrap">
        {/* Header */}
        <div className="absolute mt-5 flex w-full items-center justify-between px-5">
          <Link to="/">
            <img src={logo} alt="FKG Unpad" />
          </Link>

          <div className="flex items-end">
            <span className="text-accent-gray">3</span>
            <svg
              className="mx-1.5 mb-1.5"
              width="2"
              height="11"
              viewBox="0 0 2 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2" height="11" fill="#191919" />
            </svg>

            <span className="text-3xl font-medium">{currentStep}</span>
          </div>
        </div>

        {/* Step */}
        <StepHeader currentStep={currentStep} />

        {/* Form */}
        <div className="flex w-full bg-[#19191908] pt-28 pb-10 lg:w-1/2 lg:items-center lg:py-0">
          {currentStep === 1 ? (
            <PersonalData
              form={form}
              updateForm={(value) => updateForm(value)}
            />
          ) : currentStep === 2 ? (
            <PersonalAvatar
              form={form}
              updateForm={(value) => updateForm(value)}
              onBack={() => setCurrentStep((state) => state - 1)}
            />
          ) : (
            <VerificationData
              form={form}
              updateForm={(value) => updateForm(value)}
              onBack={() => setCurrentStep((state) => state - 1)}
            />
          )}
        </div>

        {/* Illustration */}
        {currentStep === 1 ? (
          <TFade className="absolute bottom-0 left-0 -z-10 hidden w-[500px] lg:block">
            <img src={chilling} alt="Chilling" width="75%" />
          </TFade>
        ) : currentStep === 2 ? (
          <TFade className="absolute bottom-0 left-0 -z-10 hidden w-[500px] lg:block">
            <img src={sittingReading} alt="Sitting Reading" width="75%" />
          </TFade>
        ) : (
          <TFade className="absolute bottom-0 left-0 -z-10 hidden w-[500px] lg:block">
            <img src={readingSide} alt="Reading Side" width="75%" />
          </TFade>
        )}
      </div>
    </>
  );
};

export default Register;
