import propTypes from "prop-types";

const Status = (props) => {
  const { title, status, className } = props;

  return (
    <div
      className={`flex w-max items-center justify-between gap-3 rounded-[10px] px-4 py-2 ${className} ${
        status ? "bg-[#59CE8F1A]" : "bg-[#F378781A]"
      }`}
    >
      <span className="text-base font-medium">{title}</span>
      <div
        className={`h-[20px] w-[20px] rounded-full ${
          status ? "bg-green" : "bg-red"
        }`}
      ></div>
    </div>
  );
};

export default Status;

Status.defaultProps = {
  status: false,
};

Status.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
  status: propTypes.bool,
  className: propTypes.string,
};
