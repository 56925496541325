import axios from "axios";
import headers from "services/Headers";

const showEducationPpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/sertifikat-pendidikan-ppdgs/student/${id}`,
    headers()
  );
};

const updateEducationPpdgs = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/sertifikat-pendidikan-ppdgs/${id}`,
    form,
    headers()
  );
};

export { showEducationPpdgs, updateEducationPpdgs };
