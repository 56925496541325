import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import moment from "moment";

// services
import {
  createMemberPpdgs,
  showMemberPpdgs,
} from "services/Requirement/Mahasiswa";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import InputDate from "components/Form/InputDate";

const SuratPerjanjianPesertaPPDGS = () => {
  const { user } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const response = await showMemberPpdgs(user.id);

      setData(response.data.body.data.ppdgs);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createMemberPpdgs({
            date: moment(date).format("yyy-MM-DD"),
          });

          getData();

          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Surat Perjanjian Peserta PPDGS
              <i className="block">PPDGS student agreement letter </i>
            </span>
          }
        />

        {!data ? (
          <Input
            name="Surat Perjanjian Peserta PPDGS"
            name_en="PPDGS student agreement letter "
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="mr-44 text-medium-gray">Status</span>
                </div>

                <div className="flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Tanggal Surat Perjanjian peserta PPDGS
                      <i className="text-en">Date of agreement letter</i>
                    </span>
                  </div>

                  <div className="grid w-auto grid-cols-2">
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.unready}
                    />
                    <Status
                      title="Softcopy"
                      className="my-1.5 mx-3"
                      status={data.soft_copy}
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.process}
                    />
                    <Status
                      title="Hardcopy"
                      className="my-1.5 mx-3"
                      status={data.hard_copy}
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.finish}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Surat Perjanjian Peserta PPDGS"
        name_en="PPDGS student agreement letter"
        form={
          <InputDate
            name="date"
            placeholder={
              <>
                Tanggal Surat Perjanjian / <i>Date of agreement letter</i>
              </>
            }
            value={date}
            onChange={(value) => setDate(value)}
          />
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default SuratPerjanjianPesertaPPDGS;
