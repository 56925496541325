import propTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

// services
import { exportStudentRequirementById } from "services/Requirement/Prodi";

// assets
import ic_download from "assets/icons/ic_download.svg";

const InputExport = (props) => {
  const { className, isExport, text, textEn } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const onClick = async () => {
    const response = await exportStudentRequirementById(
      searchParams.get("rqm-id"),
      searchParams.get("id")
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${response.data.body.data.file}`,
      "_blank"
    );
  };

  return (
    <div
      onClick={isExport ? onClick : props.onClick}
      className={[
        className,
        "flex cursor-pointer select-none flex-wrap items-center justify-center gap-4 rounded-[20px] border-2 border-dashed border-green bg-[#59CE8F1A] px-4 py-5 text-center md:px-0",
      ].join(" ")}
    >
      <img src={ic_download} alt="Icon Download" />
      <span className="text-medium text-xl text-accent-gray underline">
        {text ?? "Unduh Data"}{" "}
        <i className="text-en">{textEn ?? "Download Data"}</i>
      </span>
    </div>
  );
};

export default InputExport;

InputExport.defaultProps = {
  isExport: true,
};

InputExport.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  isExport: propTypes.bool,
  text: propTypes.string,
  textEn: propTypes.string,
};
