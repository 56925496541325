import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import {
  showPatientTypodont,
  updatePatientTypodontItem,
} from "services/Requirement/Dosen";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
  toCapitalizeEachWord,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import InputStatus from "components/Requirement/InputStatus";
import Footer from "components/Footer";

import InputPatient from "components/Requirement/InputPatient";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";

// assets
import ic_pencil_yellow from "assets/icons/ic_pencil_yellow.svg";

const PasienTypodontDetail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalTitleEn, setModalTitleEn] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    unready: false,
    process: false,
    finish: false,
    value: 0,
    isDate: true,
  });

  const openModal = (name, name_en, data, isDate = true) => {
    setModalTitle(name);
    setModalTitleEn(name_en);

    if (isDate) setModalData({ ...data, isDate: true });
    else setModalData({ ...data, isDate: false });

    setShowModal(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientTypodont(searchParams.get("id"));
      setData(response.data.body.data.patient.data_patient_typodont);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePatientTypodontItem(
            searchParams.get("id"),
            modalData.id,
            modalData
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data.length && (
          <div className="mb-4 rounded-[20px] bg-white px-4 py-5">
            <div className="grid grid-cols-1 gap-x-12 gap-y-5 md:grid-cols-2">
              {data.map(
                (item, index) =>
                  index < 54 &&
                  (getStatus(item) == "Selesai" ? (
                    <InputStatus
                      key={`item-${index}`}
                      name={toCapitalizeEachWord(item.key)}
                      name_en={languages[item.key]}
                      value={moment(item.value).format("D MMMM yyy")}
                      status={getStatus(item, true)}
                      data={item}
                    />
                  ) : (
                    <InputPatient
                      key={`item-${index}`}
                      name={toCapitalizeEachWord(item.key)}
                      name_en={languages[item.key]}
                      value={
                        item.value
                          ? moment(item.value).format("D MMMM yyy")
                          : "-"
                      }
                      onClick={() =>
                        openModal(
                          toCapitalizeEachWord(item.key),
                          languages[item.key],
                          item
                        )
                      }
                      isDisabled={item.value ? false : true}
                    />
                  ))
              )}

              <div
                className={`relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#F4AE0033] px-2.5 py-3.5 text-base font-medium text-accent-gray transition`}
                onClick={() =>
                  navigate(
                    `tanggal-pengambilan-bahan?id=${searchParams.get(
                      "id"
                    )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                      "npm"
                    )}&avatar=${searchParams.get("avatar")}`
                  )
                }
              >
                Tanggal Pengambilan Bahan
                <i className="text-en">Date of material pickup</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>
            </div>

            <hr className="my-5" />

            <div className="grid grid-cols-1 gap-x-12 gap-y-5 md:grid-cols-2">
              <div
                className={`relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#F4AE0033] px-2.5 py-3.5 text-base font-medium text-accent-gray transition`}
                onClick={() =>
                  navigate(
                    `alat-ortodonti?id=${searchParams.get(
                      "id"
                    )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                      "npm"
                    )}&avatar=${searchParams.get("avatar")}`
                  )
                }
              >
                Jenis Peranti Ortodonti
                <i className="text-en">Type of orthodontics appliance</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>

              {getStatus(data[54]) == "Selesai" ? (
                <InputStatus
                  name="Klasifikasi Maloklusi Angle"
                  name_en="Angle Malocclusion"
                  value={data[54].value}
                  status={getStatus(data[54], true)}
                  data={data[54]}
                />
              ) : (
                <InputPatient
                  name="Klasifikasi Maloklusi Angle"
                  name_en="Angle Malocclusion"
                  value={data[54].value ?? "-"}
                  onClick={() =>
                    openModal(
                      "Klasifikasi Maloklusi Angle",
                      "Angle Malocclusion",
                      data[54],
                      false
                    )
                  }
                  isDisabled={data[54].value ? false : true}
                />
              )}

              {getStatus(data[55]) == "Selesai" ? (
                <InputStatus
                  name="Klasifikasi Skeletal"
                  name_en="Angle Malocclusion"
                  value={data[55].value}
                  status={getStatus(data[55], true)}
                  data={data[55]}
                />
              ) : (
                <InputPatient
                  name="Klasifikasi Skeletal"
                  name_en="Angle Malocclusion"
                  value={data[55].value ?? "-"}
                  onClick={() =>
                    openModal(
                      "Klasifikasi Skeletal",
                      "Angle Malocclusion",
                      data[55],
                      false
                    )
                  }
                  isDisabled={data[55].value ? false : true}
                />
              )}
            </div>

            <div
              onClick={() =>
                navigate(
                  `aktivasi-rahang?id=${searchParams.get(
                    "id"
                  )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                    "npm"
                  )}&avatar=${searchParams.get("avatar")}`
                )
              }
              className="mt-5 flex cursor-pointer select-none items-center justify-between rounded-[10px] bg-cream px-2 py-2 text-xl font-medium"
            >
              <span>
                Tanggal Aktivasi Rahang Atas & Bawah
                <i className="text-en">Upper & Lower Activation Dates</i>
              </span>
              <div className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                <img src={ic_pencil_yellow} alt="Icon Pencil" />
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name={`Validasi ${modalTitle}`}
        name_en={`${modalTitleEn} Validation`}
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <>
            <div className="mb-6 flex gap-5 self-center">
              <div
                className={`relative z-0 block  w-1/2 cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-12 pb-2 text-base font-medium text-black transition`}
              >
                {modalData.isDate
                  ? moment(modalData.value).format("D MMMM yyy")
                  : modalData.value}
                <label
                  className={`absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base text-accent-gray duration-300`}
                >
                  {modalTitle} <i className="text-en">{modalTitleEn}</i>
                </label>
              </div>
            </div>
            <div className="grid gap-x-5 lg:grid-cols-3">
              <div>
                <InputToggle
                  name={
                    <>
                      Belum / <i>Incomplete</i>
                    </>
                  }
                  enabled={modalData.unready}
                  onChange={(value) => setModal("unready", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Proses / <i>Process</i>
                    </>
                  }
                  enabled={modalData.process}
                  onChange={(value) => setModal("process", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Sudah / <i>Complete</i>
                    </>
                  }
                  enabled={modalData.finish}
                  onChange={(value) => setModal("finish", value)}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PasienTypodontDetail;
