import { useState } from "react";
import { Link } from "react-router-dom";

// components
import Button from "components/Button";
import Slider from "components/Slider";

// assets
import logo from "assets/images/logo.png";
import ic_microscope from "assets/icons/ic_microscope.svg";
import ic_stethoscope from "assets/icons/ic_stethoscope.svg";
import ic_hand_right from "assets/icons/ic_hand_right.svg";

const ChooseRole = () => {
  const [role, setRole] = useState("");

  return (
    <div className="auth-wrap flex-wrap lg:flex">
      <div className="mt-5 w-full px-5 lg:w-1/2 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Pilih Akses
            <i className="text-en">Select access</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan pilih akses akun terlebih dahulu.
            <i className="text-en">Please select the account access first.</i>
          </h4>

          <div className="mt-10">
            {/* Mahasiswa */}
            <div
              title="Select account mahasiswa"
              onClick={() => setRole("mahasiswa")}
              className={`group mb-5 flex cursor-pointer items-center justify-between rounded-2xl border border-primary-yellow p-4 opacity-50 transition duration-300 hover:opacity-100 [&.active]:opacity-100  ${
                role === "mahasiswa" && "active"
              }`}
            >
              <div className="flex flex-wrap items-center">
                <div className="mr-3 mb-2 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-cream md:mb-0">
                  <img src={ic_microscope} alt="Icon microscope" />
                </div>

                <div>
                  <h5 className="text-2xl font-semibold">
                    Mahasiswa
                    <i className="text-en">Student</i>
                  </h5>
                  <span className="text-base text-accent-gray">
                    Akun yang digunakan mahasiswa.
                    <i className="text-en">The account used by the student.</i>
                  </span>
                </div>
              </div>

              <div className="h-[20px] w-[20px] rounded-full border border-primary-yellow transition duration-300 group-[&.active]:border-[6px]"></div>
            </div>

            {/* Dosen & Prodi */}
            <div
              title="Select account dosen & prodi"
              onClick={() => setRole("dosen-prodi")}
              className={`group flex cursor-pointer items-center justify-between rounded-2xl border border-primary-yellow p-4 opacity-50 transition duration-300 hover:opacity-100 [&.active]:opacity-100 ${
                role === "dosen-prodi" && "active"
              }`}
            >
              <div className="flex flex-wrap items-center">
                <div className="mr-3 mb-2 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-cream md:mb-0">
                  <img src={ic_stethoscope} alt="Icon stethoscope" />
                </div>

                <div>
                  <h5 className="text-2xl font-semibold">
                    Dosen & Prodi
                    <i className="text-en">Lecturer & Study Programme</i>
                  </h5>
                  <span className="text-base text-accent-gray">
                    Akun yang digunakan dosen & prodi.
                    <i className="text-en">
                      The account used by the lecturer & study programme.
                    </i>
                  </span>
                </div>
              </div>

              <div className="h-[20px] w-[20px] rounded-full border border-primary-yellow transition duration-300 group-[&.active]:border-[6px]"></div>
            </div>

            {/* Button */}
            <Button
              isPrimary
              isBlock
              isDisabled={role === ""}
              type={role === "" ? "button" : "link"}
              href="/login"
              className="mt-16 mb-10 flex items-center justify-between"
            >
              <div>
                Lanjut <i className="text-en">Next</i>
              </div>
              <img src={ic_hand_right} alt="Icon Hand Right" />
            </Button>
          </div>
        </form>
      </div>

      <div className="fixed right-0 -z-[1] hidden lg:block lg:w-1/2">
        <Slider />
      </div>
    </div>
  );
};

export default ChooseRole;
