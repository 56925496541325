import { useState, useRef, useEffect, useCallback } from "react";
import propTypes from "prop-types";
import toast from "react-hot-toast";

// components
import InputText from "components/Auth/InputText";
import InputPassword from "components/Auth/InputPassword";
import InputFormat from "components/Auth/InputFormat";

// assets
import ic_arrow_left_black from "assets/icons/ic_arrow_left_black.svg";
import ic_arrow_right_black from "assets/icons/ic_arrow_right_black.svg";
import { Fade } from "react-awesome-reveal";
import ModalData from "components/Admin/ModalData";
import ImageCrop from "components/ImageCrop";

const VerificationData = (props) => {
  let { form } = props;

  const [fullName, setFullName] = useState("");
  const [npm, setNpm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [src, setSrc] = useState("");
  const [srcCrop, setSrcCrop] = useState("");
  const [showModal, setShowModal] = useState(false);

  const input = useRef(null);

  useEffect(() => {
    setFullName(form.fullName);
    setNpm(form.npm);
    setPhoneNumber(form.phoneNumber);
    setEmail(form.email);
    setPassword(form.password);

    if (!form.avatar) return;

    const fileReader = new FileReader();

    // File on load
    fileReader.onload = () => {
      setSrc(fileReader.result);
    };

    fileReader.readAsDataURL(form.avatar);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    form = {
      fullName,
      npm,
      phoneNumber,
      email,
      password,
      avatar: avatar == null ? form.avatar : avatar,
    };

    props.updateForm(form);
  };

  const handleInput = (e) => {
    e.preventDefault();
    showFile(e.target.files[0]);
  };

  const showFile = useCallback(
    (file) => {
      const fileType = file.type;
      const fileSize = Math.round(file.size / 1024);
      const validExtensions = ["image/jpeg", "image/jpg", "image/png"];

      // Check extension
      if (validExtensions.includes(fileType)) {
        // Check size
        if (fileSize > 5120) {
          toast.error(
            <span>
              Gambar melebihi batas size 5 MB!
              <i className="text-en">The image exceeds the 5MB size limit!</i>
            </span>
          );
          return;
        }

        const fileReader = new FileReader();

        // File on load
        fileReader.onload = () => {
          setSrcCrop(fileReader.result);
          setShowModal(true);
        };

        fileReader.readAsDataURL(file);
      } else {
        toast.error(
          <span>
            Yang anda unggah bukan gambar!
            <i className="text-en">What you uploaded is not a picture!</i>
          </span>
        );
      }
    },
    [props]
  );

  const createAvatar = (image) => {
    const newAvatar = new File([image], "image.jpeg", {
      type: "image/jpeg",
    });

    const fileReader = new FileReader();

    // File on load
    fileReader.onload = () => {
      setAvatar(newAvatar);
      setShowModal(false);
      setSrc(fileReader.result);
    };

    fileReader.readAsDataURL(image);
  };

  return (
    <>
      <Fade duration={750} className="w-full px-5 xl:px-14">
        {/* Form */}
        <form onSubmit={submit}>
          <h1 className="mb-5 text-center text-3xl font-semibold text-accent-black lg:hidden">
            Verifikasi Data
            <i className="text-en">Verification Data</i>
          </h1>

          <h1 className="mb-5 text-center text-xl font-semibold text-accent-black">
            Profil Foto
            <i className="text-en">Photo Profile</i>
          </h1>

          {/* Avatar */}
          <div
            className="group relative mx-auto mb-5 h-[100px] w-[100px] cursor-pointer overflow-hidden rounded-full border-[3px] border-gray-500 bg-gray-200"
            onClick={() => input.current.click()}
          >
            <img src={src} alt="Avatar" className="object-cover" />
            {/* Change */}
            <div
              className="absolute top-1/2 left-1/2 z-10 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black opacity-[.45] transition group-hover:opacity-100"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              <svg
                className="w-[20px]"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33268 10.4167H35.416V25H39.5827V10.4167C39.5827 8.11875 37.7139 6.25 35.416 6.25H8.33268C6.03477 6.25 4.16602 8.11875 4.16602 10.4167V35.4167C4.16602 37.7146 6.03477 39.5833 8.33268 39.5833H24.9994V35.4167H8.33268V10.4167Z"
                  fill="white"
                />
                <path
                  d="M16.666 22.9167L10.416 31.25H33.3327L24.9993 18.75L18.7493 27.0833L16.666 22.9167Z"
                  fill="white"
                />
                <path
                  d="M39.5827 29.167H35.416V35.417H29.166V39.5837H35.416V45.8337H39.5827V39.5837H45.8327V35.417H39.5827V29.167Z"
                  fill="white"
                />
              </svg>
            </div>
            <input
              type="file"
              ref={input}
              value=""
              accept="image/png, image/jpeg, image/jpg"
              hidden
              onChange={handleInput}
            />
          </div>

          <InputText
            name="fullName"
            type="text"
            placeholder={
              <>
                Nama Lengkap / <i>Full Name</i>
              </>
            }
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            onDelete={() => setFullName("")}
          />

          <InputFormat
            name="npm"
            placeholder={
              <>
                NPM / <i>Registration student number</i>
              </>
            }
            inputClassName="mt-5"
            template="xxxx - xx - xx - xx - xx"
            value={npm}
            onChange={(value) => setNpm(value)}
            onDelete={() => setNpm("")}
          />

          <InputText
            name="phoneNumber"
            type="number"
            placeholder="Nomor Handphone"
            inputClassName="mt-5"
            prepend="+62"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onDelete={() => setPhoneNumber("")}
          />

          <InputText
            name="email"
            type="email"
            placeholder="Email"
            inputClassName="mt-5"
            hint={
              <>
                Catatan: Gunakan email yang diberikan kampus.
                <i className="text-en">
                  Note: Use the email provided by the campus.
                </i>
              </>
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onDelete={() => setEmail("")}
          />

          <InputPassword
            name="password"
            placeholder="Password"
            inputClassName="mt-5"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="mt-5 block text-center text-sm text-red">
            <span>
              Periksa kembali data yang telah dimasukkan untuk menghindari
              kesalahan, karena setelah dikonfirmasi, tidak dapat diubah.
            </span>

            <i className="text-en">
              Please review the entered data carefully for any mistakes, as once
              it has been confirmed, it cannot be changed.
            </i>
          </div>

          <div className="mt-10 flex justify-between">
            <button
              type="button"
              className="flex items-center"
              onClick={props.onBack}
            >
              <div className="icon-arrow mr-3 border-black">
                <img src={ic_arrow_left_black} alt="Icon Arrow Left" />
              </div>
              <h2 className="text-lg font-medium md:text-xl">
                Kembali <i className="text-en">Back</i>
              </h2>
            </button>

            <button type="submit" className="flex items-center">
              <h2 className="text-lg font-medium md:text-xl">
                Lanjut <i className="text-en">Next</i>
              </h2>
              <div className="icon-arrow ml-3 border-black">
                <img src={ic_arrow_right_black} alt="Icon Arrow Right" />
              </div>
            </button>
          </div>
        </form>
      </Fade>
      <ModalData
        name="Crop Image"
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        body={
          <>
            <ImageCrop
              srcImage={srcCrop}
              onCancel={() => {
                setSrcCrop("");
                setShowModal(false);
              }}
              onSuccess={(image) => createAvatar(image)}
            />
          </>
        }
      />
    </>
  );
};

export default VerificationData;

VerificationData.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
