import axios from "axios";
import headers from "services/Headers";

const getPresenceSeminar = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/kehadiran-seminar/student/${id}`,
    headers()
  );
};

const updatePresenceSeminar = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/kehadiran-seminar/${id}`,
    form,
    headers()
  );
};

export { getPresenceSeminar, updatePresenceSeminar };
