import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// features
import { ForgotPasswordUser, resetMsg, resetSuccess } from "features/authSlice";

// components
import InputText from "components/Auth/InputText";

// assets
import logo from "assets/images/logo.png";
import petting from "assets/images/petting.svg";
import ic_hand_left_black from "assets/icons/ic_hand_left_black.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isLoading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Kode OTP berhasil dikirim!");
      navigate(`/verification?email=${email}`);
    }
  }, [isSuccess, navigate]);

  const sendVerification = (e) => {
    e.preventDefault();
    dispatch(ForgotPasswordUser(email));
  };

  return (
    <div className="auth-wrap flex flex-wrap">
      <div className="mt-5 w-full px-5 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form onSubmit={sendVerification} className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Buat Kata Sandi Baru
            <i className="text-en">Create New Password</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan masukan kata sandi baru anda.
            <i className="text-en">Please enter your new password.</i>
          </h4>

          <div className="mx-auto mt-24 lg:w-[800px]">
            <InputText
              name="email"
              placeholder="Email"
              inputClassName="mt-5"
              hint={
                <>
                  Catatan: Gunakan email yang diberikan kampus.
                  <i className="text-en">
                    Note: Use the email provided by the campus.
                  </i>
                </>
              }
              hintClassName="text-[#3DB2FF]"
              icRemove={false}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div className="absolute bottom-0 right-0 -z-10 hidden w-[500px] lg:block">
        <img src={petting} alt="petting" />
      </div>

      <div
        onClick={() => navigate(-1)}
        className="fixed bottom-10 left-10 flex cursor-pointer items-center"
      >
        <img src={ic_hand_left_black} alt="Icon Hand Left" className="mr-2" />
        <span>
          Kembali
          <i className="text-en">Back</i>
        </span>
      </div>
    </div>
  );
};

export default ForgotPassword;
