import propTypes from "prop-types";

// services
import { getNilai } from "services/general";

const InputNilai = (props) => {
  const { value, placeholder, name, inputClassName, required, disabled } =
    props;

  return (
    <div className={["flex items-center gap-3", inputClassName].join(" ")}>
      <div className="relative z-0 w-full overflow-hidden">
        <input
          id={name}
          name={name}
          type="number"
          value={value}
          required={required}
          disabled={disabled}
          onChange={props.onChange}
          min={0}
          max={100}
          className={`peer block w-full appearance-none rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-2 text-base font-medium text-black transition placeholder-shown:pt-3 focus:border-gray-500 focus:pt-5 focus:outline-none focus:ring-0`}
          placeholder=" "
        />

        <label
          htmlFor={name}
          className={`absolute top-3 left-3 -z-10 origin-[0] -translate-y-2 scale-75 transform text-base text-accent-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-accent-gray`}
        >
          {placeholder}
        </label>
      </div>

      <div className="flex items-center justify-center rounded-[10px] bg-[#D9D9D933] px-3 py-2">
        <span className="text-2xl font-semibold text-primary-yellow">
          {getNilai(value)}
        </span>
      </div>
    </div>
  );
};

export default InputNilai;

InputNilai.defaultProps = {
  placeholder: "Please type here..",
  required: true,
  disabled: false,
};

InputNilai.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.element]),
  inputClassName: propTypes.string,
  required: propTypes.bool,
  disabled: propTypes.bool,
};
