import { useState, useRef, useEffect, useCallback } from "react";
import propTypes from "prop-types";
import toast from "react-hot-toast";

// services
import { formatNumber } from "services/general";

const InputFile = (props) => {
  const { value } = props;

  const [title, setTitle] = useState("Tambah Gambar");
  const [desc, setDesc] = useState(
    <>
      atau seret dan lepas di sini.
      <i className="text-en">or drag and drop here.</i>
    </>
  );

  const fileWrap = useRef(null);
  const input = useRef(null);

  const handleInput = (e) => {
    e.preventDefault();
    showFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    if (e.dataTransfer.files.length) {
      showFile(e.dataTransfer.files[0]);
    } else {
      toast.error(
        <span>
          Yang anda unggah bukan gambar!
          <i className="text-en">What you uploaded is not a picture!</i>
        </span>
      );
    }
  };

  const showFile = useCallback(
    (file) => {
      const fileType = file.type;
      const fileSize = Math.round(file.size / 1024);
      const validExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];

      // Check extension
      if (validExtensions.includes(fileType)) {
        // Check size
        if (fileSize > 5120) {
          toast.error(
            <span>
              File melebihi batas size 5 MB!
              <i className="text-en">The file exceeds the 5MB size limit!</i>
            </span>
          );
          return;
        }

        const fileReader = new FileReader();

        setTitle(file.name);
        if (fileSize >= 1000) {
          setDesc(`Size - ${formatNumber(fileSize)} MB`);
        } else {
          setDesc(`Size - ${fileSize} KB`);
        }

        fileReader.readAsDataURL(file);

        // Send Event
        props.onChange(file);
      } else {
        // toast.error(
        //   <span>
        //     Yang anda unggah bukan file!
        //     <i className="text-en">What you uploaded is not a file!</i>
        //   </span>
        // );
      }
    },
    [props]
  );

  useEffect(() => {
    if (value) {
      showFile(value);
    }
  }, [value]);

  return (
    <div
      ref={fileWrap}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onClick={() => input.current.click()}
      className={`flex h-[35vh] cursor-pointer flex-col items-center justify-center rounded-[20px] border-[3px] border-dashed border-[#0000001F] text-center transition duration-300 hover:border-accent-gray ${
        !title === "Tambah Gambar" && "!border-accent-gray"
      }`}
    >
      <svg
        className="mb-3 w-20"
        width="94"
        height="118"
        viewBox="0 0 94 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.6654 0.666992H11.9987C5.58203 0.666992 0.390366 5.91699 0.390366 12.3337L0.332031 105.667C0.332031 112.084 5.5237 117.334 11.9404 117.334H81.9987C88.4154 117.334 93.6654 112.084 93.6654 105.667V35.667L58.6654 0.666992ZM11.9987 105.667V12.3337H52.832V41.5003H81.9987V105.667H11.9987Z"
          fill="black"
          fillOpacity="0.12"
        />
      </svg>

      <h3 className="mb-1 text-2xl font-medium text-accent-gray underline">
        {title === "Tambah Avatar" ? (
          <span>
            Tambah Avatar <i className="text-en">Add Avatar</i>
          </span>
        ) : (
          title
        )}
      </h3>
      <span className="text-accent-gray">{desc}</span>

      <input
        type="file"
        value=""
        ref={input}
        accept="image/png, image/jpeg, image/jpg, image/svg+xml"
        hidden
        onChange={handleInput}
      />
    </div>
  );
};

export default InputFile;

InputFile.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.object,
};
