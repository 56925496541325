import axios from "axios";
import headers from "services/Headers";

const getRequirements = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/requirements`,
    headers()
  );
};

const getRequirementById = (id, userId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/requirements/${userId}/${id}`,
    headers()
  );
};

export { getRequirements, getRequirementById };
