import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetMsg, ResetPasswordUser, resetSuccess } from "features/authSlice";
import toast from "react-hot-toast";

// components
import InputPassword from "components/Auth/InputPassword";

// assets
import logo from "assets/images/logo.png";
import jumping from "assets/images/jumping.svg";
import ic_hand_left_black from "assets/icons/ic_hand_left_black.svg";
import ic_hand_right_black from "assets/icons/ic_hand_right_black.svg";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isLoading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Reset Password success");
      navigate("/login");
    }
  }, [isSuccess, navigate]);

  const changePassword = (e) => {
    e.preventDefault();

    if (password !== passwordConf) {
      setError("Kata sandi tidak cocok!");
      toast.error("Kata sandi tidak cocok!");
      return;
    }

    dispatch(
      ResetPasswordUser({
        email: searchParams.get("email"),
        code: searchParams.get("otp"),
        password,
      })
    );
  };

  return (
    <div className="auth-wrap flex flex-wrap">
      <div className="mt-5 w-full px-5 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form onSubmit={changePassword} className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Buat Kata Sandi Baru
            <i className="text-en">Create New Password</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan masukan kata sandi baru anda.
            <i className="text-en">Please enter your new password.</i>
          </h4>

          <div className="mx-auto mt-24 lg:w-[800px]">
            <InputPassword
              name="password"
              placeholder="Password"
              inputClassName="mt-5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputPassword
              name="passwordConf"
              placeholder={<>Konfirmasi Password / Password Confirmation</>}
              inputClassName="mt-5"
              value={passwordConf}
              onChange={(e) => setPasswordConf(e.target.value)}
            />

            <div className="mt-5 flex items-center justify-between">
              <span className="italic text-red">{error}</span>

              <button
                type="submit"
                className="flex cursor-pointer items-center"
              >
                <div>
                  Ganti Password
                  <i className="text-en">Change Password</i>
                </div>
                <img
                  src={ic_hand_right_black}
                  alt="Icon Hand Right"
                  className="ml-2"
                />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="absolute bottom-0 right-0 -z-10 hidden w-[500px] lg:block">
        <img src={jumping} alt="jumping" />
      </div>

      <div
        onClick={() => navigate(-1)}
        className="fixed bottom-10 left-10 flex cursor-pointer items-center"
      >
        <img src={ic_hand_left_black} alt="Icon Hand Left" className="mr-2" />
        <div>
          Kembali
          <i className="text-en">Back</i>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
