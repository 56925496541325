import axios from "axios";
import headers from "services/Headers";

const createManuscriptJournal = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/manuskrip-jurnal`,
    form,
    headers()
  );
};

const showManuscriptJournal = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/manuskrip-jurnal/student/${id}`,
    headers()
  );
};

export { createManuscriptJournal, showManuscriptJournal };
