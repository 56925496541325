import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import {
  getStudentRequirementById,
  updateStudentProgressReport,
  getWireBending,
} from "services/Requirement/Dosen";
import { confirmModal, getNilai, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import Detail from "components/Action/Detail";
import InputSearch from "components/Form/InputSearch";
import InputToggleBig from "components/Form/InputToggleBig";

// assets
import ic_search from "assets/icons/ic_search.svg";
import NotFound from "components/NotFound";

const WireBending = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseProgress = await getStudentRequirementById(
        searchParams.get("rqm-id"),
        searchParams.get("id")
      );
      const response = await getWireBending(searchParams.get("id"));

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.wire_bending);
      setFilteredData(response.data.body.data.wire_bending);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query) => {
    // Create copy of item list
    var updatedList = [...data];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setSearch(query);
    setFilteredData(updatedList);
  };

  const setProgressData = (key, value) => {
    const newProgressData = { ...progress };
    newProgressData[key] = value;

    // setProgress(newProgressData);

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateStudentProgressReport(
            searchParams.get("rpt-id"),
            newProgressData
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputToggleBig
              name={
                <>
                  Belum <i className="text-en">Incomplete</i>
                </>
              }
              enabled={progress.unfinish}
              onChange={(value) => setProgressData("unfinish", value)}
            />
            <InputToggleBig
              name={
                <>
                  Proses <i className="text-en">Process</i>
                </>
              }
              enabled={progress.process}
              onChange={(value) => setProgressData("process", value)}
            />
            <InputToggleBig
              name="100%"
              enabled={progress.finish}
              onChange={(value) => setProgressData("finish", value)}
            />
          </div>

          {/* Filter */}
          <div className="flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
            <InputSearch
              name="search"
              value={search}
              required={false}
              placeholder="Search nama / name.."
              prepend={<img src={ic_search} alt="Icon search" />}
              onChange={(e) => filterBySearch(e.target.value)}
            />
          </div>

          <div className="mt-5 overflow-x-scroll md:overflow-visible">
            <div className="w-max md:w-full">
              <div className="mb-3 flex justify-between gap-4">
                <span className="text-medium-gray">
                  Keterangan / <i>Information</i>
                </span>
                <span className="ml-80 text-medium-gray">Status</span>
                <span className="ml-12 text-medium-gray">
                  Nilai / <i>Score</i>
                </span>
                <span className="mr-5 text-medium-gray">
                  Aksi / <i>Action</i>
                </span>
              </div>

              {data.length ? (
                filteredData.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">{value.name}</h3>
                        <span className="text-base text-accent-gray">
                          Nama Pola <i className="text-en">Name of pattern</i>
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Tanggal Mulai <i className="text-en">Date of start</i>
                        </span>
                      </div>
                    </div>

                    <div>
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        status={value.unready}
                        className="mx-3 mb-3"
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        status={value.process}
                        className="mx-3 mb-3"
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        status={value.finish}
                        className="mx-3 mb-3"
                      />
                    </div>

                    <div>
                      <span className="flex flex-col items-center justify-center text-xl">
                        {value.value}
                        <svg
                          width="25"
                          height="2"
                          viewBox="0 0 25 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="25" height="1.5" fill="#FFF1CE" />
                        </svg>
                        <b className="text-primary-yellow">
                          {getNilai(value.value)}
                        </b>
                      </span>
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(
                            `detail?no=${index + 1}&id=${
                              value.id
                            }&name=${searchParams.get(
                              "name"
                            )}&npm=${searchParams.get(
                              "npm"
                            )}&avatar=${searchParams.get("avatar")}`
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </TFade>
    </>
  );
};

export default WireBending;
