import axios from "axios";
import headers from "services/Headers";

const getAdministrasi = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/administrasi/student/${id}`,
    headers()
  );
};

const showAdministrasi = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/administrasi/task/${id}`,
    headers()
  );
};

const updateAdministrasi = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/administrasi/${id}`,
    form,
    headers()
  );
};

export { getAdministrasi, showAdministrasi, updateAdministrasi };
