import propTypes from "prop-types";

// components
import InputFolder from "components/Requirement/InputFolder";

// assets
import laying from "assets/images/laying.svg";

const Input = (props) => {
  const { name, name_en, onClick, hasDescription } = props;

  return (
    <>
      <InputFolder name={name} name_en={name_en} onClick={onClick} />

      <div className="mt-20 mb-20 flex flex-col items-center justify-center px-5 text-center lg:mt-28 lg:px-52">
        <img src={laying} alt="Laying" />
        <h3 className="text-2xl font-semibold">
          Kamu Belum Membuat {name}!{" "}
          <i className="text-en">You Haven't Made a {name_en} yet!</i>
        </h3>
        {hasDescription && (
          <p className="text-base text-accent-gray">
            Tambahkan {name} terlebih dahulu, lalu tunggu dosen pembimbing
            memberikan validasi mengenai tanggal yang kamu berikan.
            <i className="text-en">
              Add {name_en} first, then wait for the lecture provide validation
              regarding the date you provided.
            </i>
          </p>
        )}
      </div>
    </>
  );
};

export default Input;

Input.defaultProps = {
  hasDescription: true,
};

Input.propTypes = {
  name: propTypes.string.isRequired,
  name_en: propTypes.string,
  onClick: propTypes.func.isRequired,
  hasDescription: propTypes.bool,
};
