import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import { showPatientClinic } from "services/Requirement/Prodi";
import { getStatus, getStatusEn, toCapitalizeEachWord } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import InputStatus from "components/Requirement/InputStatus";
import Footer from "components/Footer";

// assets
import ic_pencil_yellow from "assets/icons/ic_pencil_yellow.svg";

const PasienKlinikDetail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinic(searchParams.get("id"));
      setData(response.data.body.data.patient.data_patient_clinic);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data.length && (
          <div className="mb-4 rounded-[20px] bg-white px-4 py-5">
            <div className="grid grid-cols-1 gap-x-12 gap-y-5 md:grid-cols-2">
              {data.map(
                (item, index) =>
                  index < 56 && (
                    <InputStatus
                      key={`item-${index}`}
                      name={toCapitalizeEachWord(item.key)}
                      name_en={languages[item.key]}
                      value={
                        item.value
                          ? moment(item.value).format("D MMMM yyy")
                          : "-"
                      }
                      status={getStatus(item, true)}
                      data={item}
                    />
                  )
              )}

              <div
                className={`relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#F4AE0033] px-2.5 py-3.5 text-base font-medium text-accent-gray transition`}
                onClick={() =>
                  navigate(
                    `tanggal-pengambilan-bahan?id=${searchParams.get(
                      "id"
                    )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                      "npm"
                    )}&avatar=${searchParams.get("avatar")}
                    &user-id=${searchParams.get("user-id")}
                    &rqm-id=${searchParams.get("rqm-id")}`
                  )
                }
              >
                Tanggal Pengambilan Bahan
                <i className="text-en">Date of material pickup</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>
            </div>

            <hr className="my-5" />

            <div className="grid grid-cols-1 gap-x-12 gap-y-5 md:grid-cols-2">
              <div
                className={`relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#F4AE0033] px-2.5 py-3.5 text-base font-medium text-accent-gray transition`}
                onClick={() =>
                  navigate(
                    `alat-ortodonti?id=${searchParams.get(
                      "id"
                    )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                      "npm"
                    )}&avatar=${searchParams.get("avatar")}
                    &user-id=${searchParams.get("user-id")}
                    &rqm-id=${searchParams.get("rqm-id")}`
                  )
                }
              >
                Jenis Peranti Ortodonti
                <i className="text-en">Type of orthodontics appliance</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>

              <InputStatus
                name="Klasifikasi Maloklusi Angle"
                name_en="Angle Malocclusion"
                value={data[56].value ?? "-"}
                status={getStatus(data[56], true)}
                data={data[56]}
              />

              <InputStatus
                name="Klasifikasi Skeletal"
                name_en="Skeletal classification"
                value={data[57].value ?? "-"}
                status={getStatus(data[57], true)}
                data={data[57]}
              />
            </div>

            <div
              onClick={() =>
                navigate(
                  `aktivasi-rahang?id=${searchParams.get(
                    "id"
                  )}&name=${searchParams.get("name")}&npm=${searchParams.get(
                    "npm"
                  )}&avatar=${searchParams.get("avatar")}
                  &user-id=${searchParams.get("user-id")}
                  &rqm-id=${searchParams.get("rqm-id")}`
                )
              }
              className="mt-5 flex cursor-pointer select-none items-center justify-between rounded-[10px] bg-cream px-2 py-2 text-xl font-medium"
            >
              <span>
                Tanggal Aktivasi Rahang Atas & Bawah
                <i className="text-en">Upper & Lower Activation Dates</i>
              </span>
              <div className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                <img src={ic_pencil_yellow} alt="Icon Pencil" />
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
    </>
  );
};

export default PasienKlinikDetail;
