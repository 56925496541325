import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import moment from "moment";

// services
import { createExamCbt, showExamCbt } from "services/Requirement/Mahasiswa";
import { confirmModal, getNilai, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import InputDate from "components/Form/InputDate";

const UjianCBT = () => {
  const { user } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const response = await showExamCbt(user.id);

      setData(response.data.body.data.cbt);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createExamCbt({
            date: moment(date).format("yyy-MM-DD"),
          });

          getData();

          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Ujian CBT <i className="block">CBT Exam</i>
            </span>
          }
        />

        {!data ? (
          <Input
            name="Ujian CBT"
            name_en="CBT Exam"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Tanggal Ujian CBT / <i>Date of CBT exam</i>
                  </span>
                  <span className="mr-24 text-medium-gray">Status</span>
                  <span className="mr-8 text-medium-gray">
                    Nilai / <i>Score</i>
                  </span>
                </div>

                <div className="flex items-center justify-between gap-16 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Ujian CBT <i className="text-en">Exam CBT</i>
                    </span>
                  </div>

                  <div>
                    <Status
                      title={
                        <>
                          Lulus <i className="text-en">Passed</i>
                        </>
                      }
                      status={data.status}
                    />
                  </div>

                  <span className="mr-7 flex flex-col items-center justify-center text-xl">
                    {data.value}
                    <svg
                      width="25"
                      height="2"
                      viewBox="0 0 25 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="25" height="1.5" fill="#FFF1CE" />
                    </svg>
                    <b className="text-primary-yellow">
                      {getNilai(data.value)}
                    </b>
                  </span>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        )}
        <Footer />
      </TFade>
      <Modal
        name="Tanggal Ujian"
        name_en="Date Exam"
        form={
          <>
            <InputDate
              name="date"
              placeholder={
                <>
                  Tanggal Ujian CBT / <i>Date of CBT exam</i>
                </>
              }
              value={date}
              onChange={(value) => setDate(value)}
            />
          </>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default UjianCBT;
