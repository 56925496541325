import { useEffect, useState } from "react";

// services
import {
  createImageLogin,
  getImageLogin,
  updateImageLogin,
  deleteImageLogin,
  activateImageLogin,
} from "services/Admin";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputFolder from "components/Requirement/InputFolder";
import Edit from "components/Action/Edit";
import Delete from "components/Action/Delete";
import InputFile from "components/Form/InputFile";
import Active from "components/Action/Active";

const ImageLogin = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    image: null,
    method: "POST",
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getImageLogin();
      setData(response.data.body.data.images);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e?.preventDefault();

    confirmModal().then((result) => {
      if (result.isConfirmed) {
        if (modalData.method === "POST") create();
        else if (modalData.method === "PATCH") update();
        else if (modalData.method === "DELETE") destroy();
      }
    });
  };

  const create = async () => {
    try {
      await createImageLogin({
        image: modalData.image,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    try {
      await updateImageLogin(modalData.id, {
        image: modalData.image ?? modalData.image_temporary,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const destroy = async () => {
    try {
      await deleteImageLogin(modalData.id);

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil dihapus! <i class='text-en'>Data deleted successfully</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = () => {
    setModalData({
      image: null,
      method: "POST",
    });
  };

  const setActive = (id) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateImageLogin(id);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  useEffect(() => {
    if (modalData.method === "DELETE") submit();
  }, [modalData]);

  return (
    <>
      <TFade>
        <Navbar
          title="Image Login"
          subtitle={
            <span>
              Daftar Image Login <i className="block">List Image Login</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Image Login"
            name_en="Image Login"
            hasDescription={false}
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <InputFolder
              name="Image Login"
              name_en="Image Login"
              onClick={() => {
                reset();
                setShowModal(true);
              }}
            />

            <div className="mt-5 mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">Image</span>
                  <span className="mr-44 text-medium-gray">Status</span>
                  <span className="mr-20 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <img
                      src={`${process.env.REACT_APP_STORAGE}image-logins/${value.image}`}
                      alt="Image"
                      className="w-[100px] rounded-sm"
                    />

                    <div className="w-1/4">
                      {value.status ? (
                        <span className="font-medium text-green">
                          Aktif <i className="text-en">Active</i>
                        </span>
                      ) : (
                        <span className="font-medium text-red">
                          Tidak Aktif <i className="text-en">Not active</i>
                        </span>
                      )}
                    </div>

                    <div className="mr-5 flex gap-3">
                      {!value.status && (
                        <Active
                          onClick={() => {
                            setActive(value.id);
                          }}
                        />
                      )}

                      <Edit
                        onClick={() => {
                          setModalData({
                            ...value,
                            image: null,
                            image_temporary: value.image,
                            method: "PATCH",
                          });
                          setShowModal(true);
                        }}
                      />
                      <Delete
                        onClick={() => {
                          setModalData({
                            ...value,
                            method: "DELETE",
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name={modalData.method === "POST" ? "Tambah Gambar" : "Ubah Gambar"}
        name_en={modalData.method === "POST" ? "Add Image" : "Edit Image"}
        textSubmit="Gambar"
        textSubmitEn="Image"
        isSave={true}
        form={
          <InputFile
            name="image"
            value={modalData.image}
            onChange={(file) => setModal("image", file)}
          />
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default ImageLogin;
