import propTypes from "prop-types";
import moment from "moment";

const LogActivity = (props) => {
  const { data } = props;

  return (
    <div>
      <h3 className="mb-4 text-2xl font-medium">
        Log Aktivitas <i className="text-en">Log Activities</i>
      </h3>

      {data.length ? (
        data.map((value, index) => (
          <div
            key={`log-${index}`}
            className="mb-5 flex flex-wrap items-center justify-end gap-3 md:justify-between"
          >
            <div className="order-2 flex gap-3 md:order-1 md:items-center">
              <img
                src={`${process.env.REACT_APP_STORAGE}avatars/${value.lecture.avatar}`}
                alt="Avatar"
                className="h-[60px] w-[60px] rounded-full border-[6px] border-white object-cover"
              />
              <div>
                <h5 className="text-xl font-medium">{value.lecture.name}</h5>
                <span className="text-base text-medium-gray">{value.body}</span>
              </div>
            </div>

            <span className="order-1 text-lg font-medium text-primary-yellow md:order-2">
              {moment(value.createdAt).startOf("hour").fromNow()}
            </span>
          </div>
        ))
      ) : (
        <span className="block text-center font-medium text-accent-gray">
          Data tidak ada.
          <i className="text-en">Data not found.</i>
        </span>
      )}
    </div>
  );
};

export default LogActivity;

LogActivity.defaultProps = {
  data: [],
};

LogActivity.propTypes = {
  data: propTypes.array,
};
