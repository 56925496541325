import axios from "axios";
import headers from "services/Headers";

const createImageLogin = (form) => {
  const formData = new FormData();
  formData.append("image", form.image);

  headers.contentType = "multipart/form-data";

  return axios.post(
    `${process.env.REACT_APP_API}/administrator/image-login`,
    formData,
    headers()
  );
};

const getImageLogin = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/image-login-all`,
    headers()
  );
};

const showImageLogin = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/image-login/list-images`,
    headers()
  );
};

const updateImageLogin = (id, form) => {
  const formData = new FormData();
  formData.append("image", form.image);

  headers.contentType = "multipart/form-data";

  return axios.patch(
    `${process.env.REACT_APP_API}/administrator/image-login/${id}`,
    formData,
    headers()
  );
};

const deleteImageLogin = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API}/administrator/image-login/${id}`,
    headers()
  );
};

const activateImageLogin = (id) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/administrator/image-login/activate/${id}`,
    {},
    headers()
  );
};

export {
  createImageLogin,
  getImageLogin,
  showImageLogin,
  updateImageLogin,
  deleteImageLogin,
  activateImageLogin,
};
