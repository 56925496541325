import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { confirmModal, successModal } from "services/general";
import {
  showPresenceStudy,
  updatePresenceStudy,
} from "services/Requirement/Dosen";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Edit from "components/Action/Edit";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";

const KehadiranKuliahPakarDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    title: "",
    name_lecture: "",
    validation_1: false,
    validation_2: false,
    unready: false,
    process: false,
    finish: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPresenceStudy(searchParams.get("id"));

      setData(response.data.body.data.presence);
      setLogs(response.data.body.data.logs);

      setHasData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePresenceStudy(modalData.id, modalData);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {hasData && (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-72 text-medium-gray">Status</span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5">
                  {/* Title */}
                  <div className="w-1/3">
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Tanggal Kehadiran Kuliah Pakar
                      <i className="text-en">Date of attendance</i>
                    </span>

                    <h3 className="mt-5 text-xl font-semibold">{data.title}</h3>
                    <span className="text-base text-accent-gray">
                      Judul Materi Kuliah Pakar
                      <i className="text-en">
                        Title of expert lecture material
                      </i>
                    </span>

                    <h3 className="mt-5 text-xl font-semibold">
                      {data.name_lecture}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Nama Pemberi Materi Kuliah Pakar
                      <i className="text-en">Name of the expert lecturer</i>
                    </span>
                  </div>

                  <div className="grid grid-cols-3">
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      status={data.unready}
                      className="mx-3 mb-3"
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      status={data.process}
                      className="mx-3 mb-3"
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      status={data.finish}
                      className="mx-3 mb-3"
                    />
                  </div>

                  <div className="mr-5">
                    <Edit
                      onClick={() => {
                        setModalData(data);
                        setShowModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        )}
        <Footer />
      </TFade>
      <Modal
        name="Validasi Kehadiran Kuliah Pakar"
        name_en="Attendance of expert lectures validation"
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <div className="grid gap-x-14 gap-y-10 lg:grid-cols-2">
            <div>
              <h3 className="text-xl font-semibold">
                {searchParams.get("name")}
              </h3>
              <span className="block text-base text-accent-gray">
                {searchParams.get("npm")}
              </span>
            </div>

            {/* Title */}
            <div>
              <h3 className="text-xl font-semibold">
                {moment(modalData.date).format("D MMMM yyy")}
              </h3>
              <span className="text-base text-accent-gray">
                Tanggal Kehadiran Kuliah Pakar
                <i className="text-en">Date of attendance</i>
              </span>

              <h3 className="mt-5 text-xl font-semibold">{modalData.title}</h3>
              <span className="text-base text-accent-gray">
                Judul Materi Kuliah Pakar
                <i className="text-en">Title of expert lecture material</i>
              </span>

              <h3 className="mt-5 text-xl font-semibold">
                {modalData.name_lecture}
              </h3>
              <span className="text-base text-accent-gray">
                Nama Pemberi Materi Kuliah Pakar
                <i className="text-en">Name of the expert lecturer</i>
              </span>
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Validasi 1 / <i>Validation 1</i>
                  </>
                }
                enabled={modalData.validation_1}
                inputClassName="mb-3"
                onChange={(value) => setModal("validation_1", value)}
              />

              <InputToggle
                name={
                  <>
                    Validasi 2 / <i>Validation 2</i>
                  </>
                }
                enabled={modalData.validation_2}
                onChange={(value) => setModal("validation_2", value)}
              />
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Belum / <i>Incomplete</i>
                  </>
                }
                enabled={modalData.unready}
                inputClassName="mb-3"
                onChange={(value) => setModal("unready", value)}
              />

              <InputToggle
                name={
                  <>
                    Proses / <i>Process</i>
                  </>
                }
                enabled={modalData.process}
                inputClassName="mb-3"
                onChange={(value) => setModal("process", value)}
              />

              <InputToggle
                name={
                  <>
                    Sudah / <i>Complete</i>
                  </>
                }
                enabled={modalData.finish}
                onChange={(value) => setModal("finish", value)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default KehadiranKuliahPakarDetail;
