import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// features
import {
  LoginUser,
  ChangeStatusLogin,
  reset,
  resetMsg,
  resetSuccess,
} from "features/authSlice";

// components
import InputText from "components/Auth/InputText";
import InputPassword from "components/Auth/InputPassword";
import Button from "components/Button";
import ImageAuth from "components/ImageAuth";

// assets
import logo from "assets/images/logo.png";
import ic_hand_right from "assets/icons/ic_hand_right.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(resetSuccess());

      if (user.status == "disabled") {
        navigate("/activation");
        return;
      }

      toast.success("Login success");

      if (user.role.name === "Mahasiswa") navigate("/mahasiswa/dashboard");
      else if (user.role.name === "Dosen") navigate("/dosen/dashboard");
      else if (user.role.name === "Prodi") navigate("/prodi/dashboard");
      else if (user.role.name === "Administrator") navigate("/admin/dashboard");

      return;
    }

    dispatch(reset());
  }, [user, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password })).then(() => {
      dispatch(ChangeStatusLogin("login"));
    });
  };

  return (
    <div className="auth-wrap flex flex-wrap">
      <div className="mt-5 w-full px-5 lg:w-1/2 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form onSubmit={Auth} className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Masuk Akun <i className="text-en">Account Login</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan masukan data akun yang dimiliki.
            <i className="text-en">Please enter your account data.</i>
          </h4>

          <div className="mt-10">
            <InputText
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onDelete={() => setEmail("")}
            />

            <InputPassword
              name="password"
              placeholder="Password"
              inputClassName="mt-5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="h-5 w-5"
                />
                <label htmlFor="remember" className="ml-2 text-base">
                  Ingat saya
                  <i className="text-en">Remember me</i>
                </label>
              </div>

              <div className="text-base">
                <span>
                  Lupa Kata Sandi?{" "}
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-primary-yellow hover:underline"
                  >
                    Klik disini
                  </Link>
                </span>

                <span className="text-en">
                  Forgot your password?{" "}
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-primary-yellow hover:underline"
                  >
                    Click here
                  </Link>
                </span>
              </div>
            </div>

            <div className="mt-16 block text-center text-base">
              <span>
                Belum punya akun?{" "}
                <span className="text-accent-gray">
                  (Siapkan foto berwarna maks.5MB)
                </span>{" "}
                <Link
                  to="/register"
                  className="font-semibold text-primary-yellow hover:underline"
                >
                  Klik disini
                </Link>
              </span>

              <span className="text-en">
                Don't have an account yet?{" "}
                <span className="text-accent-gray">
                  (Prepare a colour photo max.5MB)
                </span>{" "}
                <Link
                  to="/register"
                  className="font-semibold text-primary-yellow hover:underline"
                >
                  Click here
                </Link>
              </span>
            </div>

            <Button
              isPrimary
              isBlock
              isDisabled={email === "" || password === ""}
              type="submit"
              className="mt-5 flex items-center justify-between"
              isLoading={isLoading}
            >
              <div>
                Masuk
                <i className="text-en">Login</i>
              </div>
              <img src={ic_hand_right} alt="Icon Hand Right" />
            </Button>
          </div>
        </form>
      </div>

      <div className="hidden items-center justify-center bg-[#19191908] lg:flex lg:w-1/2">
        <ImageAuth />
      </div>
    </div>
  );
};

export default Login;
