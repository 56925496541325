import axios from "axios";
import headers from "services/Headers";

const getRequirements = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/prodi/requirements`,
    headers()
  );
};

const getStudents = (status = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/dosen/requirements/students`,
    {
      status,
    },
    headers()
  );
};

const getStudentRequirements = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/prodi/requirements/students/${id}`,
    headers()
  );
};

const getStudentRequirementById = (id, userId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/requirements/${userId}/${id}`,
    headers()
  );
};

const exportStudentsRequirementById = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/${id}/all`,
    headers()
  );
};

const exportStudentRequirementById = (id, userId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/${userId}/${id}`,
    headers()
  );
};

const exportStudentsRequirements = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/multiple/all`,
    form,
    headers()
  );
};

export {
  getRequirements,
  getStudents,
  getStudentRequirements,
  getStudentRequirementById,
  exportStudentsRequirementById,
  exportStudentRequirementById,
  exportStudentsRequirements,
};
