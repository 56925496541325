import axios from "axios";
import headers from "services/Headers";

const getPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/student/${id}`,
    headers()
  );
};

const showPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/task/${id}`,
    headers()
  );
};

const updatePatientClinicContinue = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/${id}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueItem = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/data/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueActivityJawUpper = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/activity-jaw-upper/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueActivityJawUnder = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/activity-jaw-under/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueActivityMaterial = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/activity-material/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueRadiografy = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/date-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicContinueTool = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/patient-tools/${id}/${idData}`,
    form,
    headers()
  );
};

export {
  getPatientClinicContinue,
  showPatientClinicContinue,
  updatePatientClinicContinue,
  updatePatientClinicContinueItem,
  updatePatientClinicContinueActivityJawUpper,
  updatePatientClinicContinueActivityJawUnder,
  updatePatientClinicContinueActivityMaterial,
  updatePatientClinicContinueRadiografy,
  updatePatientClinicContinueTool,
};
