import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

// services
import {
  getRequirementById,
  createPresenceSeminar,
  getPresenceSeminar,
} from "services/Requirement/Mahasiswa";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputFolder from "components/Requirement/InputFolder";
import Status from "components/Requirement/Status";
import InputDate from "components/Form/InputDate";
import LogActivity from "components/Requirement/LogActivity";
import InputStatusBig from "components/Requirement/InputStatusBig";

const KehadiranSeminar = () => {
  const { user } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const responseProgress = await getRequirementById(
        searchParams.get("rqm-id"),
        user.id
      );
      const response = await getPresenceSeminar(user.id);

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.presence);
      // setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPresenceSeminar({
            date: moment(date).format("yyy-MM-DD HH:mm:ss"),
          });

          setDate("");
          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Kehadiran Seminar <i className="block">Seminar attendance</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Kehadiran Seminar"
            name_en="Seminar attendance"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
              <InputStatusBig
                name={
                  <>
                    Belum <i className="text-en">Incomplete</i>
                  </>
                }
                enabled={progress.unfinish}
              />
              <InputStatusBig
                name={
                  <>
                    Proses <i className="text-en">Process</i>
                  </>
                }
                enabled={progress.process}
              />
              <InputStatusBig name="100%" enabled={progress.finish} />
            </div>

            <InputFolder
              name="Kehadiran Seminar"
              name_en="Seminar attendance"
              onClick={() => setShowModal(true)}
            />

            <div className="mt-5 mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Tanggal Seminar / <i>Date of attendance</i>
                  </span>
                  <span className="mr-36 text-medium-gray md:mr-40">
                    Status
                  </span>
                </div>

                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <div className="flex items-center gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Kehadiran Seminar {index + 1}{" "}
                          <i className="text-en">
                            Seminar attendance {index + 1}
                          </i>
                        </span>
                      </div>
                    </div>

                    <div className="grid w-full grid-cols-1 md:w-auto md:grid-cols-2">
                      <Status
                        title={
                          <>
                            Validasi 1 <i className="text-en">Validation 1</i>
                          </>
                        }
                        status={value.validation_1}
                        className="my-1.5 mx-3"
                      />

                      <Status
                        title={
                          <>
                            Validasi 2 <i className="text-en">Validation 2</i>
                          </>
                        }
                        status={value.validation_2}
                        className="my-1.5 mx-3"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Log Activity */}
            {/* <LogActivity data={logs} /> */}
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Kehadiran Seminar"
        name_en="Seminar attendance"
        textSubmit="Kehadiran"
        textSubmitEn="Attendance"
        form={
          <InputDate
            name="date"
            placeholder={
              <>
                Tanggal Kehadiran / <i>Date of attendance</i>
              </>
            }
            value={date}
            onChange={(value) => setDate(value)}
          />
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default KehadiranSeminar;
