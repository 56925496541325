import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

// layouts
import Default from "layouts/Default";
import Admin from "layouts/Admin";
import ScrollToTop from "components/ScrollToTop";

// middleware
import AuthorizeUser from "middleware/Auth";

// pages

// auth
import ChooseRole from "pages/Auth/ChooseRole";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import Activation from "pages/Auth/Activation";
import ForgotPassword from "pages/Auth/ForgotPassword";
import Verification from "pages/Auth/Verification";
import ResetPassword from "pages/Auth/ResetPassword";

// mahasiswa
import MahasiswaDashboard from "pages/Mahasiswa/Dashboard";
import MahasiswaProfile from "pages/Mahasiswa/Profile";
import MahasiswaNotification from "pages/Mahasiswa/Notification";
import MahasiswaActivity from "pages/Mahasiswa/Activity";
import MahasiswaBackup from "pages/Mahasiswa/Backup";

// mahasiswa requirement
import MahasiswaRequirement from "pages/Mahasiswa/Requirement";
import MahasiswaSuratPerjanjianPesertaPPDGS from "pages/Mahasiswa/Requirement/SuratPerjanjianPesertaPPDGS";
import MahasiswaSertifikatKompetensiPPDGS from "pages/Mahasiswa/Requirement/SertifikatKompetensiPPDGS";
import MahasiswaSertifikatPendidikanPPDGS from "pages/Mahasiswa/Requirement/SertifikatPendidikanPPDGS";
import MahasiswaSIPPPDGS from "pages/Mahasiswa/Requirement/SIPPPDGS";
import MahasiswaOrientasiRSGM from "pages/Mahasiswa/Requirement/OrientasiRSGM";
import MahasiswaPresentasiSeminar from "pages/Mahasiswa/Requirement/PresentasiSeminar";
import MahasiswaKehadiranSeminar from "pages/Mahasiswa/Requirement/KehadiranSeminar";
import MahasiswaWireBending from "pages/Mahasiswa/Requirement/WireBending";
import MahasiswaWireBendingDetail from "pages/Mahasiswa/Requirement/WireBending/detail";
import MahasiswaUjianCBT from "pages/Mahasiswa/Requirement/UjianCBT";
import MahasiswaUjianMiniCEX from "pages/Mahasiswa/Requirement/UjianMiniCEX";
import MahasiswaCaseReport from "pages/Mahasiswa/Requirement/CaseReport";
import MahasiswaPresentasiPosterAtauPembicara from "pages/Mahasiswa/Requirement/PresentasiPosterAtauPembicara";
import MahasiswaManuskripJurnal from "pages/Mahasiswa/Requirement/ManuskripJurnal";
import MahasiswaBaktiSosial from "pages/Mahasiswa/Requirement/BaktiSosial";
import MahasiswaBaktiSosialDetail from "pages/Mahasiswa/Requirement/BaktiSosial/detail";
import MahasiswaUjianOSCE from "pages/Mahasiswa/Requirement/UjianOSCE";
import MahasiswaUjianDOPS from "pages/Mahasiswa/Requirement/UjianDOPS";
import MahasiswaKehadiranKuliahPakar from "pages/Mahasiswa/Requirement/KehadiranKuliahPakar";
import MahasiswaKehadiranKuliahPakarDetail from "pages/Mahasiswa/Requirement/KehadiranKuliahPakar/detail";
import MahasiswaAdministrasi from "pages/Mahasiswa/Requirement/Administrasi";
import MahasiswaAdministrasiDetail from "pages/Mahasiswa/Requirement/Administrasi/detail";
import MahasiswaPasienTypodont from "pages/Mahasiswa/Requirement/PasienTypodont";
import MahasiswaPasienTypodontDetail from "pages/Mahasiswa/Requirement/PasienTypodont/detail";
import MahasiswaPasienTypodontAktivasiRahang from "pages/Mahasiswa/Requirement/PasienTypodont/AktivasiRahang";
import MahasiswaPasienTypodontTanggalPengambilanBahan from "pages/Mahasiswa/Requirement/PasienTypodont/TanggalPengambilanBahan";
import MahasiswaPasienTypodontAlatOrtodonti from "pages/Mahasiswa/Requirement/PasienTypodont/AlatOrtodonti";
import MahasiswaPasienKlinik from "pages/Mahasiswa/Requirement/PasienKlinik";
import MahasiswaPasienKlinikDetail from "pages/Mahasiswa/Requirement/PasienKlinik/detail";
import MahasiswaPasienKlinikTanggalPengambilanBahan from "pages/Mahasiswa/Requirement/PasienKlinik/TanggalPengambilanBahan";
import MahasiswaPasienKlinikAktivasiRahang from "pages/Mahasiswa/Requirement/PasienKlinik/AktivasiRahang";
import MahasiswaPasienKlinikAlatOrtodonti from "pages/Mahasiswa/Requirement/PasienKlinik/AlatOrtodonti";
import MahasiswaPasienKlinikLanjutan from "pages/Mahasiswa/Requirement/PasienKlinikLanjutan";
import MahasiswaPasienKlinikLanjutanDetail from "pages/Mahasiswa/Requirement/PasienKlinikLanjutan/detail";
import MahasiswaPasienKlinikLanjutanTanggalPengambilanBahan from "pages/Mahasiswa/Requirement/PasienKlinikLanjutan/TanggalPengambilanBahan";
import MahasiswaPasienKlinikLanjutanAktivasiRahang from "pages/Mahasiswa/Requirement/PasienKlinikLanjutan/AktivasiRahang";
import MahasiswaPasienKlinikLanjutanAlatOrtodonti from "pages/Mahasiswa/Requirement/PasienKlinikLanjutan/AlatOrtodonti";

// dosen
import DosenDashboard from "pages/Dosen/Dashboard";
import DosenProfile from "pages/Dosen/Profile";
import DosenNotification from "pages/Dosen/Notification";
import DosenActivity from "pages/Dosen/Activity";

// dosen requirement
import DosenRequirement from "pages/Dosen/Requirement";
import DosenSuratPerjanjianPesertaPPDGS from "pages/Dosen/Requirement/SuratPerjanjianPesertaPPDGS";
import DosenSertifikatKompetensiPPDGS from "pages/Dosen/Requirement/SertifikatKompetensiPPDGS";
import DosenSertifikatPendidikanPPDGS from "pages/Dosen/Requirement/SertifikatPendidikanPPDGS";
import DosenSIPPPDGS from "pages/Dosen/Requirement/SIPPPDGS";
import DosenOrientasiRSGM from "pages/Dosen/Requirement/OrientasiRSGM";
import DosenPresentasiSeminar from "pages/Dosen/Requirement/PresentasiSeminar";
import DosenKehadiranSeminar from "pages/Dosen/Requirement/KehadiranSeminar";
import DosenWireBending from "pages/Dosen/Requirement/WireBending";
import DosenWireBendingDetail from "pages/Dosen/Requirement/WireBending/detail";
import DosenUjianCBT from "pages/Dosen/Requirement/UjianCBT";
import DosenUjianMiniCEX from "pages/Dosen/Requirement/UjianMiniCEX";
import DosenCaseReport from "pages/Dosen/Requirement/CaseReport";
import DosenPresentasiPosterAtauPembicara from "pages/Dosen/Requirement/PresentasiPosterAtauPembicara";
import DosenManuskripJurnal from "pages/Dosen/Requirement/ManuskripJurnal";
import DosenBaktiSosial from "pages/Dosen/Requirement/BaktiSosial";
import DosenBaktiSosialDetail from "pages/Dosen/Requirement/BaktiSosial/detail";
import DosenUjianOSCE from "pages/Dosen/Requirement/UjianOSCE";
import DosenUjianDOPS from "pages/Dosen/Requirement/UjianDOPS";
import DosenKehadiranKuliahPakar from "pages/Dosen/Requirement/KehadiranKuliahPakar";
import DosenKehadiranKuliahPakarDetail from "pages/Dosen/Requirement/KehadiranKuliahPakar/detail";
import DosenAdministrasi from "pages/Dosen/Requirement/Administrasi";
import DosenAdministrasiDetail from "pages/Dosen/Requirement/Administrasi/detail";
import DosenPasienTypodont from "pages/Dosen/Requirement/PasienTypodont";
import DosenPasienTypodontDetail from "pages/Dosen/Requirement/PasienTypodont/detail";
import DosenPasienTypodontAktivasiRahang from "pages/Dosen/Requirement/PasienTypodont/AktivasiRahang";
import DosenPasienTypodontTanggalPengambilanBahan from "pages/Dosen/Requirement/PasienTypodont/TanggalPengambilanBahan";
import DosenPasienTypodontAlatOrtodonti from "pages/Dosen/Requirement/PasienTypodont/AlatOrtodonti";
import DosenPasienKlinik from "pages/Dosen/Requirement/PasienKlinik";
import DosenPasienKlinikDetail from "pages/Dosen/Requirement/PasienKlinik/detail";
import DosenPasienKlinikTanggalPengambilanBahan from "pages/Dosen/Requirement/PasienKlinik/TanggalPengambilanBahan";
import DosenPasienKlinikAktivasiRahang from "pages/Dosen/Requirement/PasienKlinik/AktivasiRahang";
import DosenPasienKlinikAlatOrtodonti from "pages/Dosen/Requirement/PasienKlinik/AlatOrtodonti";
import DosenPasienKlinikLanjutan from "pages/Dosen/Requirement/PasienKlinikLanjutan";
import DosenPasienKlinikLanjutanDetail from "pages/Dosen/Requirement/PasienKlinikLanjutan/detail";
import DosenPasienKlinikLanjutanTanggalPengambilanBahan from "pages/Dosen/Requirement/PasienKlinikLanjutan/TanggalPengambilanBahan";
import DosenPasienKlinikLanjutanAktivasiRahang from "pages/Dosen/Requirement/PasienKlinikLanjutan/AktivasiRahang";
import DosenPasienKlinikLanjutanAlatOrtodonti from "pages/Dosen/Requirement/PasienKlinikLanjutan/AlatOrtodonti";

// prodi
import ProdiDashboard from "pages/Prodi/Dashboard";
import ProdiProfile from "pages/Prodi/Profile";
import ProdiNotification from "pages/Prodi/Notification";
import ProdiActivity from "pages/Prodi/Activity";
import ProdiResume from "pages/Prodi/Resume";

// prodi requirement
import ProdiRequirement from "pages/Prodi/Requirement";
import ProdiSuratPerjanjianPesertaPPDGS from "pages/Prodi/Requirement/SuratPerjanjianPesertaPPDGS";
import ProdiSertifikatKompetensiPPDGS from "pages/Prodi/Requirement/SertifikatKompetensiPPDGS";
import ProdiSertifikatPendidikanPPDGS from "pages/Prodi/Requirement/SertifikatPendidikanPPDGS";
import ProdiSIPPPDGS from "pages/Prodi/Requirement/SIPPPDGS";
import ProdiOrientasiRSGM from "pages/Prodi/Requirement/OrientasiRSGM";
import ProdiPresentasiSeminar from "pages/Prodi/Requirement/PresentasiSeminar";
import ProdiKehadiranSeminar from "pages/Prodi/Requirement/KehadiranSeminar";
import ProdiWireBending from "pages/Prodi/Requirement/WireBending";
import ProdiWireBendingDetail from "pages/Prodi/Requirement/WireBending/detail";
import ProdiUjianCBT from "pages/Prodi/Requirement/UjianCBT";
import ProdiUjianMiniCEX from "pages/Prodi/Requirement/UjianMiniCEX";
import ProdiCaseReport from "pages/Prodi/Requirement/CaseReport";
import ProdiPresentasiPosterAtauPembicara from "pages/Prodi/Requirement/PresentasiPosterAtauPembicara";
import ProdiManuskripJurnal from "pages/Prodi/Requirement/ManuskripJurnal";
import ProdiBaktiSosial from "pages/Prodi/Requirement/BaktiSosial";
import ProdiBaktiSosialDetail from "pages/Prodi/Requirement/BaktiSosial/detail";
import ProdiUjianOSCE from "pages/Prodi/Requirement/UjianOSCE";
import ProdiUjianDOPS from "pages/Prodi/Requirement/UjianDOPS";
import ProdiKehadiranKuliahPakar from "pages/Prodi/Requirement/KehadiranKuliahPakar";
import ProdiKehadiranKuliahPakarDetail from "pages/Prodi/Requirement/KehadiranKuliahPakar/detail";
import ProdiAdministrasi from "pages/Prodi/Requirement/Administrasi";
import ProdiAdministrasiDetail from "pages/Prodi/Requirement/Administrasi/detail";
import ProdiPasienTypodont from "pages/Prodi/Requirement/PasienTypodont";
import ProdiPasienTypodontDetail from "pages/Prodi/Requirement/PasienTypodont/detail";
import ProdiPasienTypodontAktivasiRahang from "pages/Prodi/Requirement/PasienTypodont/AktivasiRahang";
import ProdiPasienTypodontTanggalPengambilanBahan from "pages/Prodi/Requirement/PasienTypodont/TanggalPengambilanBahan";
import ProdiPasienTypodontAlatOrtodonti from "pages/Prodi/Requirement/PasienTypodont/AlatOrtodonti";
import ProdiPasienKlinik from "pages/Prodi/Requirement/PasienKlinik";
import ProdiPasienKlinikDetail from "pages/Prodi/Requirement/PasienKlinik/detail";
import ProdiPasienKlinikTanggalPengambilanBahan from "pages/Prodi/Requirement/PasienKlinik/TanggalPengambilanBahan";
import ProdiPasienKlinikAktivasiRahang from "pages/Prodi/Requirement/PasienKlinik/AktivasiRahang";
import ProdiPasienKlinikAlatOrtodonti from "pages/Prodi/Requirement/PasienKlinik/AlatOrtodonti";
import ProdiPasienKlinikLanjutan from "pages/Prodi/Requirement/PasienKlinikLanjutan";
import ProdiPasienKlinikLanjutanDetail from "pages/Prodi/Requirement/PasienKlinikLanjutan/detail";
import ProdiPasienKlinikLanjutanTanggalPengambilanBahan from "pages/Prodi/Requirement/PasienKlinikLanjutan/TanggalPengambilanBahan";
import ProdiPasienKlinikLanjutanAktivasiRahang from "pages/Prodi/Requirement/PasienKlinikLanjutan/AktivasiRahang";
import ProdiPasienKlinikLanjutanAlatOrtodonti from "pages/Prodi/Requirement/PasienKlinikLanjutan/AlatOrtodonti";

// admin
import AdminDashboard from "pages/Admin/Dashboard";
import AdminProfile from "pages/Admin/Profile";
import AdminNotification from "pages/Admin/Notification";
import AdminActivity from "pages/Admin/Activity";
import AdminTool from "pages/Admin/Tool";
import AdminBanner from "pages/Admin/Banner";
import AdminImageLogin from "pages/Admin/ImageLogin";

const App = () => {
  const location = useLocation();

  const transitionPage = () => {
    return !location.pathname.includes("mahasiswa") &&
      !location.pathname.includes("dosen") &&
      !location.pathname.includes("prodi") &&
      !location.pathname.includes("admin")
      ? location.pathname
      : "";
  };

  return (
    <>
      <ScrollToTop />
      <PageTransition
        preset="fadeLeftFadeRight"
        transitionKey={transitionPage()}
      >
        <Routes location={location}>
          <Route path="/" element={<Default />}>
            <Route path="/" element={<ChooseRole />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="activation" element={<Activation />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verification" element={<Verification />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>

          {/* Mahasiswa */}
          <Route
            path="/mahasiswa"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<MahasiswaDashboard />} />
            <Route path="profile" element={<MahasiswaProfile />} />
            <Route path="notification" element={<MahasiswaNotification />} />
            <Route path="activity" element={<MahasiswaActivity />} />
            <Route path="backup" element={<MahasiswaBackup />} />

            {/* Requirement */}
            <Route path="requirement" element={<MahasiswaRequirement />} />
            <Route
              path="requirement/surat-perjanjian-peserta-ppdgs"
              element={<MahasiswaSuratPerjanjianPesertaPPDGS />}
            />
            <Route
              path="requirement/sertifikat-kompetensi-ppdgs"
              element={<MahasiswaSertifikatKompetensiPPDGS />}
            />
            <Route
              path="requirement/sertifikat-pendidikan-ppdgs"
              element={<MahasiswaSertifikatPendidikanPPDGS />}
            />
            <Route
              path="requirement/sip-ppdgs"
              element={<MahasiswaSIPPPDGS />}
            />
            <Route
              path="requirement/orientasi-rsgm"
              element={<MahasiswaOrientasiRSGM />}
            />
            <Route
              path="requirement/presentasi-seminar"
              element={<MahasiswaPresentasiSeminar />}
            />
            <Route
              path="requirement/kehadiran-seminar"
              element={<MahasiswaKehadiranSeminar />}
            />
            <Route
              path="requirement/wire-bending"
              element={<MahasiswaWireBending />}
            />
            <Route
              path="requirement/wire-bending/detail"
              element={<MahasiswaWireBendingDetail />}
            />
            <Route
              path="requirement/pasien-typodont"
              element={<MahasiswaPasienTypodont />}
            />
            <Route
              path="requirement/pasien-typodont/detail"
              element={<MahasiswaPasienTypodontDetail />}
            />
            <Route
              path="requirement/pasien-typodont/detail/tanggal-pengambilan-bahan"
              element={<MahasiswaPasienTypodontTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-typodont/detail/aktivasi-rahang"
              element={<MahasiswaPasienTypodontAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-typodont/detail/alat-ortodonti"
              element={<MahasiswaPasienTypodontAlatOrtodonti />}
            />
            <Route
              path="requirement/ujian-cbt"
              element={<MahasiswaUjianCBT />}
            />
            <Route
              path="requirement/pasien-klinik"
              element={<MahasiswaPasienKlinik />}
            />
            <Route
              path="requirement/pasien-klinik/detail"
              element={<MahasiswaPasienKlinikDetail />}
            />
            <Route
              path="requirement/pasien-klinik/detail/tanggal-pengambilan-bahan"
              element={<MahasiswaPasienKlinikTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik/detail/aktivasi-rahang"
              element={<MahasiswaPasienKlinikAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik/detail/alat-ortodonti"
              element={<MahasiswaPasienKlinikAlatOrtodonti />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan"
              element={<MahasiswaPasienKlinikLanjutan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail"
              element={<MahasiswaPasienKlinikLanjutanDetail />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/tanggal-pengambilan-bahan"
              element={<MahasiswaPasienKlinikLanjutanTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/aktivasi-rahang"
              element={<MahasiswaPasienKlinikLanjutanAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/alat-ortodonti"
              element={<MahasiswaPasienKlinikLanjutanAlatOrtodonti />}
            />
            <Route
              path="requirement/ujian-mini-cex"
              element={<MahasiswaUjianMiniCEX />}
            />
            <Route
              path="requirement/case-report"
              element={<MahasiswaCaseReport />}
            />
            <Route
              path="requirement/presentasi-poster-atau-pembicara"
              element={<MahasiswaPresentasiPosterAtauPembicara />}
            />
            <Route
              path="requirement/manuskrip-jurnal"
              element={<MahasiswaManuskripJurnal />}
            />
            <Route
              path="requirement/bakti-sosial"
              element={<MahasiswaBaktiSosial />}
            />
            <Route
              path="requirement/bakti-sosial/detail"
              element={<MahasiswaBaktiSosialDetail />}
            />
            <Route
              path="requirement/ujian-osce"
              element={<MahasiswaUjianOSCE />}
            />
            <Route
              path="requirement/ujian-dops"
              element={<MahasiswaUjianDOPS />}
            />
            <Route
              path="requirement/kehadiran-kuliah-pakar"
              element={<MahasiswaKehadiranKuliahPakar />}
            />
            <Route
              path="requirement/kehadiran-kuliah-pakar/detail"
              element={<MahasiswaKehadiranKuliahPakarDetail />}
            />
            <Route
              path="requirement/administrasi"
              element={<MahasiswaAdministrasi />}
            />
            <Route
              path="requirement/administrasi/detail"
              element={<MahasiswaAdministrasiDetail />}
            />
          </Route>

          {/* Dosen */}
          <Route
            path="/dosen"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<DosenDashboard />} />
            <Route path="profile" element={<DosenProfile />} />
            <Route path="notification" element={<DosenNotification />} />
            <Route path="activity" element={<DosenActivity />} />

            {/* Requirement */}
            <Route path="requirement" element={<DosenRequirement />} />
            <Route
              path="requirement/surat-perjanjian-peserta-ppdgs"
              element={<DosenSuratPerjanjianPesertaPPDGS />}
            />
            <Route
              path="requirement/sertifikat-kompetensi-ppdgs"
              element={<DosenSertifikatKompetensiPPDGS />}
            />
            <Route
              path="requirement/sertifikat-pendidikan-ppdgs"
              element={<DosenSertifikatPendidikanPPDGS />}
            />
            <Route path="requirement/sip-ppdgs" element={<DosenSIPPPDGS />} />
            <Route
              path="requirement/orientasi-rsgm"
              element={<DosenOrientasiRSGM />}
            />
            <Route
              path="requirement/presentasi-seminar"
              element={<DosenPresentasiSeminar />}
            />
            <Route
              path="requirement/kehadiran-seminar"
              element={<DosenKehadiranSeminar />}
            />
            <Route
              path="requirement/wire-bending"
              element={<DosenWireBending />}
            />
            <Route
              path="requirement/wire-bending/detail"
              element={<DosenWireBendingDetail />}
            />
            <Route
              path="requirement/pasien-typodont"
              element={<DosenPasienTypodont />}
            />
            <Route
              path="requirement/pasien-typodont/detail"
              element={<DosenPasienTypodontDetail />}
            />
            <Route
              path="requirement/pasien-typodont/detail/tanggal-pengambilan-bahan"
              element={<DosenPasienTypodontTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-typodont/detail/aktivasi-rahang"
              element={<DosenPasienTypodontAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-typodont/detail/alat-ortodonti"
              element={<DosenPasienTypodontAlatOrtodonti />}
            />
            <Route path="requirement/ujian-cbt" element={<DosenUjianCBT />} />
            <Route
              path="requirement/pasien-klinik"
              element={<DosenPasienKlinik />}
            />
            <Route
              path="requirement/pasien-klinik/detail"
              element={<DosenPasienKlinikDetail />}
            />
            <Route
              path="requirement/pasien-klinik/detail/tanggal-pengambilan-bahan"
              element={<DosenPasienKlinikTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik/detail/aktivasi-rahang"
              element={<DosenPasienKlinikAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik/detail/alat-ortodonti"
              element={<DosenPasienKlinikAlatOrtodonti />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan"
              element={<DosenPasienKlinikLanjutan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail"
              element={<DosenPasienKlinikLanjutanDetail />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/tanggal-pengambilan-bahan"
              element={<DosenPasienKlinikLanjutanTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/aktivasi-rahang"
              element={<DosenPasienKlinikLanjutanAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/alat-ortodonti"
              element={<DosenPasienKlinikLanjutanAlatOrtodonti />}
            />
            <Route
              path="requirement/ujian-mini-cex"
              element={<DosenUjianMiniCEX />}
            />
            <Route
              path="requirement/case-report"
              element={<DosenCaseReport />}
            />
            <Route
              path="requirement/presentasi-poster-atau-pembicara"
              element={<DosenPresentasiPosterAtauPembicara />}
            />
            <Route
              path="requirement/manuskrip-jurnal"
              element={<DosenManuskripJurnal />}
            />
            <Route
              path="requirement/bakti-sosial"
              element={<DosenBaktiSosial />}
            />
            <Route
              path="requirement/bakti-sosial/detail"
              element={<DosenBaktiSosialDetail />}
            />
            <Route path="requirement/ujian-osce" element={<DosenUjianOSCE />} />
            <Route path="requirement/ujian-dops" element={<DosenUjianDOPS />} />
            <Route
              path="requirement/kehadiran-kuliah-pakar"
              element={<DosenKehadiranKuliahPakar />}
            />
            <Route
              path="requirement/kehadiran-kuliah-pakar/detail"
              element={<DosenKehadiranKuliahPakarDetail />}
            />
            <Route
              path="requirement/administrasi"
              element={<DosenAdministrasi />}
            />
            <Route
              path="requirement/administrasi/detail"
              element={<DosenAdministrasiDetail />}
            />
          </Route>

          {/* Prodi */}
          <Route
            path="/prodi"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<ProdiDashboard />} />
            <Route path="profile" element={<ProdiProfile />} />
            <Route path="notification" element={<ProdiNotification />} />
            <Route path="activity" element={<ProdiActivity />} />
            <Route path="resume" element={<ProdiResume />} />

            {/* Requirement */}
            <Route path="requirement" element={<ProdiRequirement />} />
            <Route
              path="requirement/surat-perjanjian-peserta-ppdgs"
              element={<ProdiSuratPerjanjianPesertaPPDGS />}
            />
            <Route
              path="requirement/sertifikat-kompetensi-ppdgs"
              element={<ProdiSertifikatKompetensiPPDGS />}
            />
            <Route
              path="requirement/sertifikat-pendidikan-ppdgs"
              element={<ProdiSertifikatPendidikanPPDGS />}
            />
            <Route path="requirement/sip-ppdgs" element={<ProdiSIPPPDGS />} />
            <Route
              path="requirement/orientasi-rsgm"
              element={<ProdiOrientasiRSGM />}
            />
            <Route
              path="requirement/presentasi-seminar"
              element={<ProdiPresentasiSeminar />}
            />
            <Route
              path="requirement/kehadiran-seminar"
              element={<ProdiKehadiranSeminar />}
            />
            <Route
              path="requirement/wire-bending"
              element={<ProdiWireBending />}
            />
            <Route
              path="requirement/wire-bending/detail"
              element={<ProdiWireBendingDetail />}
            />
            <Route
              path="requirement/pasien-typodont"
              element={<ProdiPasienTypodont />}
            />
            <Route
              path="requirement/pasien-typodont/detail"
              element={<ProdiPasienTypodontDetail />}
            />
            <Route
              path="requirement/pasien-typodont/detail/tanggal-pengambilan-bahan"
              element={<ProdiPasienTypodontTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-typodont/detail/aktivasi-rahang"
              element={<ProdiPasienTypodontAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-typodont/detail/alat-ortodonti"
              element={<ProdiPasienTypodontAlatOrtodonti />}
            />
            <Route path="requirement/ujian-cbt" element={<ProdiUjianCBT />} />
            <Route
              path="requirement/pasien-klinik"
              element={<ProdiPasienKlinik />}
            />
            <Route
              path="requirement/pasien-klinik/detail"
              element={<ProdiPasienKlinikDetail />}
            />
            <Route
              path="requirement/pasien-klinik/detail/tanggal-pengambilan-bahan"
              element={<ProdiPasienKlinikTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik/detail/aktivasi-rahang"
              element={<ProdiPasienKlinikAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik/detail/alat-ortodonti"
              element={<ProdiPasienKlinikAlatOrtodonti />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan"
              element={<ProdiPasienKlinikLanjutan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail"
              element={<ProdiPasienKlinikLanjutanDetail />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/tanggal-pengambilan-bahan"
              element={<ProdiPasienKlinikLanjutanTanggalPengambilanBahan />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/aktivasi-rahang"
              element={<ProdiPasienKlinikLanjutanAktivasiRahang />}
            />
            <Route
              path="requirement/pasien-klinik-lanjutan/detail/alat-ortodonti"
              element={<ProdiPasienKlinikLanjutanAlatOrtodonti />}
            />
            <Route
              path="requirement/ujian-mini-cex"
              element={<ProdiUjianMiniCEX />}
            />
            <Route
              path="requirement/case-report"
              element={<ProdiCaseReport />}
            />
            <Route
              path="requirement/presentasi-poster-atau-pembicara"
              element={<ProdiPresentasiPosterAtauPembicara />}
            />
            <Route
              path="requirement/manuskrip-jurnal"
              element={<ProdiManuskripJurnal />}
            />
            <Route
              path="requirement/bakti-sosial"
              element={<ProdiBaktiSosial />}
            />
            <Route
              path="requirement/bakti-sosial/detail"
              element={<ProdiBaktiSosialDetail />}
            />
            <Route path="requirement/ujian-osce" element={<ProdiUjianOSCE />} />
            <Route path="requirement/ujian-dops" element={<ProdiUjianDOPS />} />
            <Route
              path="requirement/kehadiran-kuliah-pakar"
              element={<ProdiKehadiranKuliahPakar />}
            />
            <Route
              path="requirement/kehadiran-kuliah-pakar/detail"
              element={<ProdiKehadiranKuliahPakarDetail />}
            />
            <Route
              path="requirement/administrasi"
              element={<ProdiAdministrasi />}
            />
            <Route
              path="requirement/administrasi/detail"
              element={<ProdiAdministrasiDetail />}
            />
          </Route>

          {/* Admin */}
          <Route
            path="/admin"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="notification" element={<AdminNotification />} />
            <Route path="activity" element={<AdminActivity />} />
            <Route path="tool" element={<AdminTool />} />
            <Route path="banner" element={<AdminBanner />} />
            <Route path="image-login" element={<AdminImageLogin />} />
          </Route>

          {/* Not Found */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </PageTransition>
    </>
  );
};

export default App;
