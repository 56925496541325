import axios from "axios";
import headers from "services/Headers";

const showExamDops = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/ujian-dops/student/${id}`,
    headers()
  );
};

export { showExamDops };
