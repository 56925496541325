import axios from "axios";
import headers from "services/Headers";

const createUser = (form) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("email", form.email);
  formData.append("password", form.password);
  formData.append("phone", form.phone);
  formData.append("role_id", form.role_id);
  formData.append("avatar", form.avatar);

  headers.contentType = "multipart/form-data";

  return axios.post(
    `${process.env.REACT_APP_API}/administrator/add-user`,
    formData,
    headers()
  );
};

const getUser = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/list-users`,
    headers()
  );
};

const deleteUser = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API}/administrator/delete-user/${id}`,
    headers()
  );
};

const getRoles = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/list-roles`,
    headers()
  );
};

const getExportRoles = () => {
  return axios.get(`${process.env.REACT_APP_API}/excel/list-roles`, headers());
};

const exportUsers = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/excel/export/users/${id}`,
    headers()
  );
};

export {
  createUser,
  getUser,
  deleteUser,
  getRoles,
  getExportRoles,
  exportUsers,
};
