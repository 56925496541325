import axios from "axios";
import headers from "services/Headers";

const showExamOsce = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/ujian-osce/student/${id}`,
    headers()
  );
};

const updateExamOsce = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/ujian-osce/${id}`,
    form,
    headers()
  );
};

export { showExamOsce, updateExamOsce };
