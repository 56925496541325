import axios from "axios";
import headers from "services/Headers";

const showPresentasionSeminar = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/prodi/presentasi-seminar/student/${id}`,
    headers()
  );
};

export { showPresentasionSeminar };
