import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { getAdministrasi } from "services/Requirement/Dosen";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import Detail from "components/Action/Detail";
import NotFound from "components/NotFound";

const Administrasi = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getAdministrasi(searchParams.get("id"));
      setData(response.data.body.data.administrasi);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <div className="mt-5 overflow-x-scroll md:overflow-visible">
            <div className="w-max md:w-full">
              <div className="mb-3 flex justify-between gap-4">
                <span className="text-medium-gray">
                  Tanggal / <i>Date</i>
                </span>
                <span className="ml-32 text-medium-gray">Status</span>
                <span className="mr-5 text-medium-gray">
                  Aksi / <i>Action</i>
                </span>
              </div>

              {data.length ? (
                data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="flex gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date_soft_copy).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Softcopy
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {moment(value.date_hard_copy).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Hardcopy
                        </span>
                      </div>
                    </div>

                    <div className="grid w-auto grid-cols-2">
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        status={value.unready}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Validasi 1 <i className="text-en">Validation 1</i>
                          </>
                        }
                        status={value.validation_1}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        status={value.process}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Validasi 2 <i className="text-en">Validation 2</i>
                          </>
                        }
                        status={value.validation_2}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        status={value.finish}
                        className="my-1.5 mx-3"
                      />
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(
                            `detail?no=${index + 1}&id=${
                              value.id
                            }&name=${searchParams.get(
                              "name"
                            )}&npm=${searchParams.get(
                              "npm"
                            )}&avatar=${searchParams.get("avatar")}`
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </TFade>
    </>
  );
};

export default Administrasi;
