import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TFade from "components/Transition/Fade";
import moment from "moment";

// services
import {
  getPresenceSeminar,
  getStudentRequirementById,
  updatePresenceSeminar,
  updateStudentProgressReport,
} from "services/Requirement/Dosen";
import { confirmModal, successModal } from "services/general";

// components
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Edit from "components/Action/Edit";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";
import InputToggleBig from "components/Form/InputToggleBig";
import NotFound from "components/NotFound";

const KehadiranSeminar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    validation_1: false,
    validation_2: false,
    date: Date.now(),
  });
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);

  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseProgress = await getStudentRequirementById(
        searchParams.get("rqm-id"),
        searchParams.get("id")
      );
      const response = await getPresenceSeminar(searchParams.get("id"));

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.presence);
      // setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePresenceSeminar(modalData.id, modalData);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const setProgressData = (key, value) => {
    const newProgressData = { ...progress };
    newProgressData[key] = value;

    // setProgress(newProgressData);

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateStudentProgressReport(
            searchParams.get("rpt-id"),
            newProgressData
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputToggleBig
              name={
                <>
                  Belum <i className="text-en">Incomplete</i>
                </>
              }
              enabled={progress.unfinish}
              onChange={(value) => setProgressData("unfinish", value)}
            />
            <InputToggleBig
              name={
                <>
                  Proses <i className="text-en">Process</i>
                </>
              }
              enabled={progress.process}
              onChange={(value) => setProgressData("process", value)}
            />
            <InputToggleBig
              name="100%"
              enabled={progress.finish}
              onChange={(value) => setProgressData("finish", value)}
            />
          </div>

          <div className="mb-7 overflow-x-scroll md:overflow-visible">
            <div className="w-max md:w-full">
              <div className="mb-3 flex justify-between gap-4">
                <span className="text-medium-gray">
                  Tanggal Seminar / <i>Date of attendance</i>
                </span>
                <span className="ml-2 text-medium-gray">Status</span>
                <span className="mr-8 text-medium-gray">
                  Aksi / <i>Action</i>
                </span>
              </div>

              {data.length ? (
                data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7"
                  >
                    {/* Title */}
                    <div className="flex flex-wrap items-center gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Kehadiran Seminar {index + 1}{" "}
                          <i className="text-en">
                            Seminar attendance {index + 1}
                          </i>
                        </span>
                      </div>
                    </div>

                    <div className="grid w-full grid-cols-1 md:w-auto md:grid-cols-2">
                      <Status
                        title={
                          <>
                            Validasi 1 <i className="text-en">Validation 1</i>
                          </>
                        }
                        status={value.validation_1}
                        className="my-1.5 mx-3"
                      />

                      <Status
                        title={
                          <>
                            Validasi 2 <i className="text-en">Validation 2</i>
                          </>
                        }
                        status={value.validation_2}
                        className="my-1.5 mx-3"
                      />
                    </div>

                    <div className="mr-5">
                      <Edit
                        onClick={() => {
                          setModalData(value);
                          setShowModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <NotFound />
              )}
            </div>
          </div>

          {/* Log Activity */}
          {/* <LogActivity /> */}
        </div>

        <Footer />
      </TFade>
      <Modal
        name="Validasi Kehadiran Seminar"
        name_en="Seminar Attendance Validation"
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <div className="grid gap-x-14 gap-y-10 lg:grid-cols-2">
            <div>
              <h3 className="text-xl font-semibold">
                {searchParams.get("name")}
              </h3>
              <span className="block text-base text-accent-gray">
                {searchParams.get("npm")}
              </span>
            </div>

            {/* Title */}
            <div>
              <h3 className="text-xl font-semibold">
                {moment(modalData.date).format("D MMMM yyy")}
              </h3>
              <span className="text-base text-accent-gray">
                Kehadiran Seminar / <i>Seminar Attendance</i>
              </span>
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Validasi 1 / <i>Validation 1</i>
                  </>
                }
                enabled={modalData.validation_1}
                onChange={(value) => setModal("validation_1", value)}
              />
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Validasi 2 / <i>Validation 2</i>
                  </>
                }
                enabled={modalData.validation_2}
                onChange={(value) => setModal("validation_2", value)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default KehadiranSeminar;
