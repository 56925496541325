import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import {
  showPatientClinic,
  updatePatientClinicActivityJawUpper,
  updatePatientClinicActivityJawUnder,
  updatePatientClinicRadiografy,
} from "services/Requirement/Dosen";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
  toCapitalizeEachWord,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputPatient from "components/Requirement/InputPatient";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";
import InputStatus from "components/Requirement/InputStatus";

const PasienKlinikAktivasiRahang = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [radiografy, setRadiografy] = useState([]);
  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalTitleEn, setModalTitleEn] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    unready: false,
    process: false,
    finish: false,
    date: 0,
    type: "",
  });

  const openModal = (name, name_en, data) => {
    setModalTitle(name);
    setModalTitleEn(name_en);
    setModalData(data);
    setShowModal(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinic(searchParams.get("id"));

      setRadiografy(response.data.body.data.patient.date_radiografy);
      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_clinic
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_clinic
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (modalData.type == "upper") {
            await updatePatientClinicActivityJawUpper(
              searchParams.get("id"),
              modalData.id,
              modalData
            );
          } else {
            await updatePatientClinicActivityJawUnder(
              searchParams.get("id"),
              modalData.id,
              modalData
            );
          }

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const submitRadiografy = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePatientClinicRadiografy(
            searchParams.get("id"),
            modalData.id,
            modalData
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div>
              <div className="mb-10 grid grid-cols-1 gap-y-5">
                {radiografy.length &&
                  (getStatus(radiografy[0]) == "Selesai" ? (
                    <InputStatus
                      name={toCapitalizeEachWord(radiografy[0].name)}
                      name_en={languages[radiografy[0].name]}
                      value={moment(radiografy[0].value).format("D MMMM yyy")}
                      status={getStatus(radiografy[0], true)}
                      data={radiografy[0]}
                    />
                  ) : (
                    <InputPatient
                      name={toCapitalizeEachWord(radiografy[0].name)}
                      name_en={languages[radiografy[0].name]}
                      value={
                        radiografy[0].value
                          ? moment(radiografy[0].value).format("D MMMM yyy")
                          : "-"
                      }
                      onClick={() => {
                        radiografy[0].type = "radiografy";
                        openModal(
                          toCapitalizeEachWord(radiografy[0].name),
                          languages[radiografy[0].name],
                          radiografy[0]
                        );
                      }}
                      isDisabled={radiografy[0].value ? false : true}
                    />
                  ))}
              </div>

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangAtas.map((value, index) =>
                  getStatus(value) == "Selesai" ? (
                    <InputStatus
                      key={`value-${index}`}
                      name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                      name_en={`Date of maxillary activation ${index + 1}`}
                      value={
                        value.date
                          ? moment(value.date).format("D MMMM yyy")
                          : "-"
                      }
                      inputClassName="mb-4"
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <InputPatient
                      key={`value-${index}`}
                      name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                      name_en={`Date of maxillary activation ${index + 1}`}
                      value={
                        value.date
                          ? moment(value.date).format("D MMMM yyy")
                          : "-"
                      }
                      onClick={() => {
                        value.type = "upper";
                        openModal(
                          `Tanggal Aktivasi Rahang Atas ${index + 1}`,
                          `Date of maxillary activation ${index + 1}`,
                          value
                        );
                      }}
                      inputClassName="mb-4"
                    />
                  )
                )}
              </div>
            </div>

            <div>
              <div className="mb-10 grid grid-cols-1 gap-y-5">
                {radiografy.length &&
                  (getStatus(radiografy[1]) == "Selesai" ? (
                    <InputStatus
                      name={toCapitalizeEachWord(radiografy[1].name)}
                      name_en={languages[radiografy[1].name]}
                      value={moment(radiografy[1].value).format("D MMMM yyy")}
                      status={getStatus(radiografy[1], true)}
                      data={radiografy[1]}
                    />
                  ) : (
                    <InputPatient
                      name={toCapitalizeEachWord(radiografy[1].name)}
                      name_en={languages[radiografy[1].name]}
                      value={
                        radiografy[1].value
                          ? moment(radiografy[1].value).format("D MMMM yyy")
                          : "-"
                      }
                      onClick={() => {
                        radiografy[1].type = "radiografy";
                        openModal(
                          toCapitalizeEachWord(radiografy[1].name),
                          languages[radiografy[1].name],
                          radiografy[1]
                        );
                      }}
                      isDisabled={radiografy[1].value ? false : true}
                    />
                  ))}
              </div>

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangBawah.map((value, index) =>
                  getStatus(value) == "Selesai" ? (
                    <InputStatus
                      key={`value-${index}`}
                      name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                      name_en={`Date of mandibular activation ${index + 1}`}
                      value={
                        value.date
                          ? moment(value.date).format("D MMMM yyy")
                          : "-"
                      }
                      inputClassName="mb-4"
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <InputPatient
                      key={`value-${index}`}
                      name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                      name_en={`Date of mandibular activation ${index + 1}`}
                      value={
                        value.date
                          ? moment(value.date).format("D MMMM yyy")
                          : "-"
                      }
                      onClick={() => {
                        value.type = "under";
                        openModal(
                          `Tanggal Aktivasi Rahang Bawah ${index + 1}`,
                          `Date of mandibular activation ${index + 1}`,
                          value
                        );
                      }}
                      inputClassName="mb-4"
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
      <Modal
        name={`Validasi ${modalTitle}`}
        name_en={`${modalTitleEn} Validation`}
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={modalData.type == "radiografy" ? submitRadiografy : submit}
        form={
          <>
            <div className="mb-6 flex gap-5 self-center">
              <div
                className={`relative z-0 block  w-3/4 cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-12 pb-2 text-base font-medium text-black transition`}
              >
                {moment(modalData.date).format("D MMMM yyy")}
                <label
                  className={`absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base text-accent-gray duration-300`}
                >
                  {modalTitle} <i className="text-en">{modalTitleEn}</i>
                </label>
              </div>
            </div>
            <div className="grid gap-x-5 lg:grid-cols-3">
              <div>
                <InputToggle
                  name={
                    <>
                      Belum / <i>Incomplete</i>
                    </>
                  }
                  enabled={modalData.unready}
                  onChange={(value) => setModal("unready", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Proses / <i>Process</i>
                    </>
                  }
                  enabled={modalData.process}
                  onChange={(value) => setModal("process", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Sudah / <i>Complete</i>
                    </>
                  }
                  enabled={modalData.finish}
                  onChange={(value) => setModal("finish", value)}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PasienKlinikAktivasiRahang;
