import { useState } from "react";
import propTypes from "prop-types";

const InputSearch = (props) => {
  const {
    value,
    type,
    placeholder,
    name,
    prepend,
    inputClassName,
    background,
    errorResponse,
    required,
    disabled,
  } = props;

  const [hasError, setHasError] = useState(null);

  let pattern = "";
  if (type === "email") pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (type === "tel") pattern = "[0-9]*";

  const onChange = (e) => {
    const target = {
      target: {
        name,
        value: e.target.value,
      },
    };

    if (type === "email") {
      if (!pattern.test(e.target.value)) setHasError(errorResponse);
      else setHasError(null);
    }

    props.onChange(target);
  };

  return (
    <>
      <div
        className={["relative z-0 w-full overflow-hidden", inputClassName].join(
          " "
        )}
      >
        {prepend && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
            {prepend}
          </div>
        )}
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          className={`block w-full rounded-lg border-2 border-transparent p-2.5 text-sm text-black outline-none transition placeholder:text-accent-gray focus:border-primary-yellow ${background} ${
            prepend && "pl-12"
          } ${hasError && "border-red focus:border-red"}`}
        />
      </div>

      {hasError && (
        <span className="mt-1 block text-sm text-red">{hasError}</span>
      )}
    </>
  );
};

export default InputSearch;

InputSearch.defaultProps = {
  type: "text",
  pattern: "",
  background: "bg-white",
  placeholder: "Please type here..",
  errorResponse: "Please match the requested format.",
  required: true,
  disabled: false,
};

InputSearch.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  prepend: propTypes.element,
  type: propTypes.string,
  placeholder: propTypes.string,
  inputClassName: propTypes.string,
  background: propTypes.string,
  required: propTypes.bool,
  disabled: propTypes.bool,
};
