import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { showSocialService } from "services/Requirement/Prodi";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Export from "components/Action/Export";

const BaktiSosialDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showSocialService(searchParams.get("id"));

      setData(response.data.body.data.social_service);
      setLogs(response.data.body.data.logs);

      setHasData(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {hasData && (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-64 text-medium-gray">Status</span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5">
                  {/* Title */}
                  <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                    <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                      {searchParams.get("no")}
                    </div>

                    <div>
                      <h3 className="text-xl font-semibold">
                        {moment(data.date).format("D MMMM yyy")}
                      </h3>
                      <span className="text-base text-accent-gray">
                        Tanggal Bakti Sosial {searchParams.get("no")}
                        <i className="text-en">
                          Date of social service {searchParams.get("no")}
                        </i>
                      </span>

                      <h3 className="mt-5 text-xl font-semibold">
                        {data.title}
                      </h3>
                      <span className="text-base text-accent-gray">
                        Judul Bakti Sosial {searchParams.get("no")}
                        <i className="text-en">
                          TItle of social service {searchParams.get("no")}
                        </i>
                      </span>
                    </div>
                  </div>

                  <div className="grid w-auto grid-cols-2">
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.unready}
                    />
                    <Status
                      title="Softcopy"
                      className="my-1.5 mx-3"
                      status={data.soft_copy}
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.process}
                    />
                    <Status
                      title="Hardcopy"
                      className="my-1.5 mx-3"
                      status={data.hard_copy}
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      className="my-1.5 mx-3"
                      status={data.finish}
                    />
                  </div>

                  <div className="mr-5">
                    <Export />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        )}
        <Footer />
      </TFade>
    </>
  );
};

export default BaktiSosialDetail;
