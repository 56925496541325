import propTypes from "prop-types";
import Edit from "components/Action/Edit";

const InputPatient = (props) => {
  const { name, name_en, value, inputClassName, isDisabled } = props;

  return (
    <div
      className={[
        "flex w-full items-center justify-between rounded-[10px] bg-[#F4AE0033] px-4 py-2",
        inputClassName,
      ].join(" ")}
    >
      <div>
        <span className="block text-base text-accent-gray">
          {name} <i className="text-en">{name_en}</i>
        </span>
        <span className="text-lg font-medium text-accent-gray">{value}</span>
      </div>

      {!isDisabled && (
        <div>
          <Edit isSmall onClick={props.onClick} />
        </div>
      )}
    </div>
  );
};

InputPatient.defaultProps = {
  isDisabled: false,
};

InputPatient.propTypes = {
  name: propTypes.string.isRequired,
  name_en: propTypes.string,
  value: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  inputClassName: propTypes.string,
  isDisabled: propTypes.bool,
};

export default InputPatient;
