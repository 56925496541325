import propTypes from "prop-types";

// assets
import ic_folder from "assets/icons/ic_folder.svg";

const InputFolder = (props) => {
  const { name, name_en, className } = props;

  return (
    <div
      onClick={props.onClick}
      className={[
        className,
        "flex cursor-pointer select-none flex-wrap items-center justify-center gap-4 rounded-[20px] border-2 border-dashed border-primary-yellow bg-cream px-4 py-8 text-center md:px-0",
      ].join(" ")}
    >
      <img src={ic_folder} alt="Icon Folder" />
      <span className="text-medium text-xl text-accent-gray underline">
        Tambah {name}
        <i className="text-en">Add {name_en}</i>
      </span>
    </div>
  );
};

export default InputFolder;

InputFolder.propTypes = {
  name: propTypes.string.isRequired,
  name_en: propTypes.string,
  onClick: propTypes.func.isRequired,
  className: propTypes.string,
};
