import axios from "axios";
import headers from "services/Headers";

const getPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/student/${id}`,
    headers()
  );
};

const showPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik-lanjutan/task/${id}`,
    headers()
  );
};

export { getPatientClinicContinue, showPatientClinicContinue };
