import axios from "axios";
import headers from "services/Headers";

const showOrientationRsgm = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/orientasi-rsgm/student/${id}`,
    headers()
  );
};

const updateOrientationRsgm = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/orientasi-rsgm/${id}`,
    form,
    headers()
  );
};

export { showOrientationRsgm, updateOrientationRsgm };
