import axios from "axios";
import headers from "services/Headers";

const showExamCbt = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/ujian-cbt/student/${id}`,
    headers()
  );
};

const updateExamCbt = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/ujian-cbt/${id}`,
    form,
    headers()
  );
};

export { showExamCbt, updateExamCbt };
