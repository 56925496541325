import axios from "axios";
import headers from "services/Headers";

const showPresentasionSeminar = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/presentasi-seminar/student/${id}`,
    headers()
  );
};

const updatePresentasionSeminar = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/presentasi-seminar/${id}`,
    form,
    headers()
  );
};

export { showPresentasionSeminar, updatePresentasionSeminar };
