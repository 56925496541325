import { useEffect, useState } from "react";

// services
import { createTool, getTool, updateTool, deleteTool } from "services/Admin";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputFolder from "components/Requirement/InputFolder";
import InputText from "components/Form/InputText";
import Edit from "components/Action/Edit";
import Delete from "components/Action/Delete";

const Tool = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    name: "",
    method: "POST",
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getTool();
      setData(response.data.body.data.tools);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e?.preventDefault();

    confirmModal().then((result) => {
      if (result.isConfirmed) {
        if (modalData.method === "POST") create();
        else if (modalData.method === "PATCH") update();
        else if (modalData.method === "DELETE") destroy();
      }
    });
  };

  const create = async () => {
    try {
      await createTool({
        name: modalData.name,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    try {
      await updateTool(modalData.id, {
        name: modalData.name,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const destroy = async () => {
    try {
      await deleteTool(modalData.id);

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil dihapus! <i class='text-en'>Data deleted successfully</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = () => {
    setModalData({
      id: "",
      name: "",
      method: "POST",
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Tool"
          subtitle={
            <span>
              Daftar Tool <i className="block">List Tools</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Tool"
            name_en="Tool"
            hasDescription={false}
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <InputFolder
              name="Tool"
              name_en="Tool"
              onClick={() => {
                reset();
                setShowModal(true);
              }}
            />

            <div className="mt-5 mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Nama Alat / <i>Tool Name</i>
                  </span>
                  <span className="mr-12 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <div className="flex items-center gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">{value.name}</h3>
                        <span className="text-base text-accent-gray">
                          Tool {index + 1}
                        </span>
                      </div>
                    </div>

                    <div className="mr-5 flex gap-3">
                      <Edit
                        onClick={() => {
                          setModalData({
                            id: value.id,
                            name: value.name,
                            method: "PATCH",
                          });
                          setShowModal(true);
                        }}
                      />
                      <Delete
                        onClick={() => {
                          setModalData(() => {
                            return {
                              id: value.id,
                              name: value.name,
                              method: "DELETE",
                            };
                          });
                          submit();
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name={modalData.method === "POST" ? "Tambah Tool" : "Ubah Tool"}
        name_en={modalData.method === "POST" ? "Add Tool" : "Edit Tool"}
        textSubmit="Tool"
        textSubmitEn="Tool"
        isSave={true}
        form={
          <InputText
            name="name"
            type="text"
            placeholder={
              <>
                Nama Alat / <i>Tool Name</i>
              </>
            }
            value={modalData.name}
            onChange={(e) => setModal("name", e.target.value)}
          />
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default Tool;
