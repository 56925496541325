import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import { showPatientClinic } from "services/Requirement/Prodi";
import { getStatus, getStatusEn, toCapitalizeEachWord } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputStatus from "components/Requirement/InputStatus";

const PasienKlinikAktivasiRahang = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [radiografy, setRadiografy] = useState([]);
  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinic(searchParams.get("id"));

      setRadiografy(response.data.body.data.patient.date_radiografy);
      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_clinic
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_clinic
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div>
              <div className="mb-10 grid grid-cols-1 gap-y-5">
                {radiografy.length && (
                  <InputStatus
                    name={toCapitalizeEachWord(radiografy[0].name)}
                    name_en={languages[radiografy[0].name]}
                    value={
                      radiografy[0].value
                        ? moment(radiografy[0].value).format("D MMMM yyy")
                        : "-"
                    }
                    status={getStatus(radiografy[0], true)}
                    data={radiografy[0]}
                  />
                )}
              </div>

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangAtas.map((value, index) => (
                  <InputStatus
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                    name_en={`Date of maxillary activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    inputClassName="mb-4"
                    status={getStatus(value, true)}
                    data={value}
                  />
                ))}
              </div>
            </div>

            <div>
              <div className="mb-10 grid grid-cols-1 gap-y-5">
                {radiografy.length && (
                  <InputStatus
                    name={toCapitalizeEachWord(radiografy[1].name)}
                    name_en={languages[radiografy[1].name]}
                    value={
                      radiografy[1].value
                        ? moment(radiografy[1].value).format("D MMMM yyy")
                        : "-"
                    }
                    status={getStatus(radiografy[1], true)}
                    data={radiografy[1]}
                  />
                )}
              </div>

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangBawah.map((value, index) => (
                  <InputStatus
                    key={`value-${index}`}
                    name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                    name_en={`Date of mandibular activation ${index + 1}`}
                    value={
                      value.date ? moment(value.date).format("D MMMM yyy") : "-"
                    }
                    inputClassName="mb-4"
                    status={getStatus(value, true)}
                    data={value}
                  />
                ))}
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienKlinikAktivasiRahang;
