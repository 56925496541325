import axios from "axios";
import headers from "services/Headers";

const showPresentasionPoster = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/presentasi-poster-atau-pembicara/student/${id}`,
    headers()
  );
};

export { showPresentasionPoster };
