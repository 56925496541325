import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

// features
import {
  ActivationUser,
  ResendOtp,
  reset,
  resetMsg,
  resetSuccess,
} from "features/authSlice";

// services
import { confirmModal } from "services/general";

// components
import InputOtp from "components/Auth/InputOtp";

// assets
import logo from "assets/images/logo.png";
import sitting from "assets/images/sitting.svg";
import ic_hand_left_black from "assets/icons/ic_hand_left_black.svg";

const Activation = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Activation success");

      if (user.role.name === "Mahasiswa") navigate("/mahasiswa/dashboard");
      else if (user.role.name === "Dosen") navigate("/dosen/dashboard");
      else if (user.role.name === "Prodi") navigate("/prodi/dashboard");
      else if (user.role.name === "Administrator") navigate("/admin/dashboard");
    }
  }, [isSuccess, navigate]);

  const activationOtp = (value) => {
    dispatch(ActivationUser(value));
  };

  const resendOtp = () => {
    dispatch(ResendOtp());
  };

  const logout = () => {
    confirmModal().then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        dispatch(reset());
        localStorage.removeItem("token");
        toast.success("Logout success");
      }
    });
  };

  return (
    <div className="auth-wrap flex flex-wrap">
      <div className="mt-5 w-full px-5 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Aktivasi Akun
            <i className="text-en">Account activation</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan masukan kode OTP yang kami kirimkan melalui email.
            <i className="text-en">
              Please enter the OTP code that we sent via email.
            </i>
          </h4>

          <div className="mt-20 flex justify-center">
            <InputOtp
              value={otp}
              onChange={(value) => setOtp(value)}
              onComplete={(value) => activationOtp(value)}
              onResend={() => resendOtp()}
            />
          </div>
        </form>
      </div>

      <div className="absolute bottom-0 right-0 -z-10 hidden w-[500px] lg:block">
        <img src={sitting} alt="sitting" />
      </div>

      <div
        onClick={logout}
        className="fixed bottom-10 left-10 flex cursor-pointer items-center"
      >
        <img src={ic_hand_left_black} alt="Icon Hand Left" className="mr-2" />
        Logout
      </div>
    </div>
  );
};

export default Activation;
