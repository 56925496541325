import axios from "axios";
import moment from "moment";
import headers from "services/Headers";

const getActivities = (start, end) => {
  if (start && end) {
    headers.start = start;
    headers.end = end;
  }

  return axios.get(`${process.env.REACT_APP_API}/user/activities`, headers());
};

const getNotifications = (start, end) => {
  const header = headers();
  if (start && end) {
    header.headers.start = moment(start).format("YYYY-MM-DD");
    header.headers.end = moment(end).format("YYYY-MM-DD");
    console.log(header);
  }

  return axios.get(`${process.env.REACT_APP_API}/user/notifications`, header);
};

const getCountNotifications = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/user/notifications/check`,
    headers()
  );
};

const updateProfile = (form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/user/profile/update`,
    form,
    headers()
  );
};

export {
  getActivities,
  getNotifications,
  getCountNotifications,
  updateProfile,
};
