import propTypes from "prop-types";

const ModalData = (props) => {
  const { showModal, setShowModal, name, body } = props;

  return (
    <>
      <div
        className={`fixed inset-0 z-[9999] flex  items-center justify-center overflow-y-auto overflow-x-hidden  outline-none transition-all duration-300 focus:outline-none ${
          showModal ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        <div
          className={`relative my-6 mx-5 w-[350px] min-w-[500px] md:mx-auto lg:min-w-[800px]`}
        >
          {/*content*/}
          <div className="relative flex max-h-[90vh] w-full flex-col overflow-y-auto rounded-[30px] bg-white p-5">
            {/*header*/}
            <div className="flex items-center justify-between">
              <h3 className="w-3/4 text-2xl font-semibold md:w-auto">{name}</h3>

              <span
                onClick={() => setShowModal(false)}
                className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full  bg-cream transition duration-300 hover:opacity-80"
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.78637 9.58245C6.37286 10.8479 4.99057 12.0736 3.62211 13.3147C2.82933 14.0336 2.06493 14.7837 1.274 15.5046C1.06334 15.7015 0.821269 15.8616 0.55777 15.9781C0.427835 16.0327 0.147249 15.9781 0.0832602 15.8782C-0.00140469 15.7456 0.0236273 15.5101 0.069551 15.337C0.10296 15.2108 0.23899 15.1063 0.341611 15.0049C2.29318 13.0834 4.24565 11.1631 6.19897 9.24404C6.41814 9.0288 6.64361 8.82012 6.89543 8.5811C6.04182 7.63506 5.18687 6.72218 4.3707 5.77538C3.06807 4.26359 1.79159 2.72931 0.504315 1.20443C0.457521 1.14902 0.417268 1.08836 0.370039 1.03294C0.144773 0.766648 -0.0140402 0.479633 0.242458 0.156613C0.434532 -0.0852427 0.893699 -0.0491196 1.21342 0.2799C2.00969 1.09907 2.78535 1.93808 3.55452 2.78354C4.959 4.32642 6.35252 5.87934 7.75199 7.4268C7.8119 7.48309 7.8756 7.53517 7.94265 7.58269C9.02479 6.54839 10.0949 5.51747 11.174 4.49539C12.4255 3.31066 13.6814 2.13059 14.9416 0.955169C15.4144 0.513349 15.7934 0.433713 16.0835 0.691496C16.3842 0.958552 16.3562 1.40472 15.8767 1.88309C14.5804 3.17658 13.2528 4.43867 11.9265 5.70216C10.916 6.665 9.88948 7.61126 8.81409 8.61698C9.68794 9.5208 10.5237 10.4217 11.4 11.2809C12.2416 12.1061 13.1303 12.8835 13.9885 13.6918C14.324 13.996 14.6381 14.323 14.9288 14.6705C15.1632 14.9621 15.1978 15.324 14.9097 15.6116C14.6257 15.8952 14.297 15.795 14.0231 15.5803C13.4992 15.191 12.9969 14.7731 12.5187 14.3287C11.0547 12.8949 9.61648 11.4348 8.1689 9.98421C8.05442 9.86967 7.94504 9.74946 7.78637 9.58245Z"
                    fill="#F4AE00"
                  />
                </svg>
              </span>
            </div>
            <hr className="my-5 h-[2px] bg-[#1919190D]" />

            {/*body*/}
            <div className="relative">{body}</div>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 z-[9998] bg-[#19191980] transition-all ${
          showModal ? "visible opacity-100" : "invisible opacity-0"
        }`}
      ></div>
    </>
  );
};

export default ModalData;

ModalData.propTypes = {
  name: propTypes.string.isRequired,
  body: propTypes.element.isRequired,
  showModal: propTypes.bool.isRequired,
  setShowModal: propTypes.func.isRequired,
};
