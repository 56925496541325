import { useEffect, useState } from "react";

// services
import {
  createBanner,
  getBanner,
  updateBanner,
  deleteBanner,
} from "services/Admin";
import { confirmModal, strippedContent, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputFolder from "components/Requirement/InputFolder";
import InputText from "components/Form/InputText";
import Edit from "components/Action/Edit";
import Delete from "components/Action/Delete";
import InputTextArea from "components/Form/InputTextArea";
import InputFile from "components/Form/InputFile";

const Banner = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    name: "",
    job: "",
    message: "",
    image: null,
    method: "POST",
  });
  const [data, setData] = useState([]);

  const content = (content, length) => {
    return strippedContent(content, length);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getBanner();
      setData(response.data.body.data.banners);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e?.preventDefault();

    confirmModal().then((result) => {
      if (result.isConfirmed) {
        if (modalData.method === "POST") create();
        else if (modalData.method === "PATCH") update();
        else if (modalData.method === "DELETE") destroy();
      }
    });
  };

  const create = async () => {
    try {
      await createBanner({
        name: modalData.name,
        job: modalData.job,
        message: modalData.message,
        image: modalData.image,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    try {
      await updateBanner(modalData.id, {
        name: modalData.name,
        job: modalData.job,
        message: modalData.message,
        image: modalData.image ?? modalData.image_temporary,
      });

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const destroy = async () => {
    try {
      await deleteBanner(modalData.id);

      reset();
      getData();
      successModal(
        "Success",
        "Data berhasil dihapus! <i class='text-en'>Data deleted successfully</i>"
      );
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = () => {
    setModalData({
      id: "",
      name: "",
      job: "",
      message: "",
      image: null,
      method: "POST",
    });
  };

  useEffect(() => {
    if (modalData.method === "DELETE") submit();
  }, [modalData]);

  return (
    <>
      <TFade>
        <Navbar
          title="Banner"
          subtitle={
            <span>
              Daftar Banner <i className="block">List Banners</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Banner"
            name_en="Banner"
            hasDescription={false}
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <InputFolder
              name="Banner"
              name_en="Banner"
              onClick={() => {
                reset();
                setShowModal(true);
              }}
            />

            <div className="mt-5 mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">Image</span>
                  <span className="text-medium-gray">
                    Nama / <i>Name</i>
                  </span>
                  <span className=" text-medium-gray">
                    Pesan / <i>Message</i>
                  </span>
                  <span className="mr-12 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <img
                      src={`${process.env.REACT_APP_STORAGE}banners/${value.image}`}
                      alt="Image"
                      className="w-[100px] rounded-sm"
                    />

                    <div className="w-1/3">
                      <h3 className="text-xl font-semibold">{value.name}</h3>
                      <span className="text-base text-accent-gray">
                        {value.job}
                      </span>
                    </div>

                    <span className="w-1/5 text-base">
                      {content(value.message)}
                    </span>

                    <div className="mr-5 flex gap-3">
                      <Edit
                        onClick={() => {
                          setModalData({
                            ...value,
                            image: null,
                            image_temporary: value.image,
                            method: "PATCH",
                          });
                          setShowModal(true);
                        }}
                      />
                      <Delete
                        onClick={() => {
                          setModalData({
                            ...value,
                            method: "DELETE",
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name={modalData.method === "POST" ? "Tambah Banner" : "Ubah Banner"}
        name_en={modalData.method === "POST" ? "Add Banner" : "Edit Banner"}
        textSubmit="Banner"
        textSubmitEn="Banner"
        isSave={true}
        form={
          <div className="grid grid-cols-2 gap-5">
            <div>
              <InputText
                name="name"
                type="text"
                inputClassName="mb-4"
                placeholder={
                  <>
                    Nama / <i>Name</i>
                  </>
                }
                value={modalData.name}
                onChange={(e) => setModal("name", e.target.value)}
              />
              <InputText
                name="job"
                type="text"
                inputClassName="mb-4"
                placeholder={
                  <>
                    Pekerjaan / <i>Job</i>
                  </>
                }
                value={modalData.job}
                onChange={(e) => setModal("job", e.target.value)}
              />
              <InputTextArea
                name="message"
                placeholder={
                  <>
                    Pesan / <i>Message</i>
                  </>
                }
                value={modalData.message}
                onChange={(e) => setModal("message", e.target.value)}
              />
            </div>

            <InputFile
              name="image"
              value={modalData.image}
              onChange={(file) => setModal("image", file)}
            />
          </div>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default Banner;
