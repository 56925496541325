import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// services
import { showExamCbt, updateExamCbt } from "services/Requirement/Dosen";
import { confirmModal, getNilai, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Edit from "components/Action/Edit";
import Modal from "components/Requirement/Modal";
import InputToggle from "components/Form/InputToggle";
import InputNilai from "components/Form/InputNilai";
import NotFound from "components/NotFound";

const UjianCBT = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    status: false,
    value: 0,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showExamCbt(searchParams.get("id"));

      setData(response.data.body.data.cbt);
      setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateExamCbt(modalData.id, modalData);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        {data ? (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Tanggal Ujian CBT / <i>Date of CBT exam</i>
                  </span>
                  <span className="mr-36 text-medium-gray">Status</span>
                  <span className="text-medium-gray">
                    Nilai / <i>Score</i>
                  </span>
                  <span className="mr-8 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                <div className="flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7">
                  {/* Title */}
                  <div>
                    <h3 className="text-xl font-semibold">
                      {moment(data.date).format("D MMMM yyy")}
                    </h3>
                    <span className="text-base text-accent-gray">
                      Ujian CBT <i className="text-en">Exam CBT</i>
                    </span>
                  </div>

                  <div>
                    <Status
                      title={
                        <>
                          Lulus <i className="text-en">Passed</i>
                        </>
                      }
                      status={data.status}
                    />
                  </div>

                  <span className="ml-16 flex flex-col items-center justify-center text-xl">
                    {data.value}
                    <svg
                      width="25"
                      height="2"
                      viewBox="0 0 25 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="25" height="1.5" fill="#FFF1CE" />
                    </svg>
                    <b className="text-primary-yellow">
                      {getNilai(data.value)}
                    </b>
                  </span>

                  <div className="mr-2">
                    <Edit
                      onClick={() => {
                        setModalData(data);
                        setShowModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        ) : (
          <NotFound />
        )}
        <Footer />
      </TFade>
      <Modal
        name="Validasi Ujian CBT"
        name_en="Exam CBT Validation"
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <div className="grid gap-x-14 gap-y-10 lg:grid-cols-2">
            <div>
              <h3 className="text-xl font-semibold">
                {searchParams.get("name")}
              </h3>
              <span className="block text-base text-accent-gray">
                {searchParams.get("npm")}
              </span>
            </div>

            {/* Title */}
            <div>
              <h3 className="text-xl font-semibold">
                {moment(modalData.date).format("D MMMM yyy")}
              </h3>
              <span className="text-base text-accent-gray">
                Ujian CBT <i className="text-en">Exam CBT</i>
              </span>
            </div>

            <div>
              <InputNilai
                name="nilai"
                placeholder={
                  <>
                    Nilai Ujian / <i>Score Exam</i>
                  </>
                }
                value={modalData.value}
                onChange={(e) => setModal("value", e.target.value)}
              />
            </div>

            <div>
              <InputToggle
                name={
                  <>
                    Lulus / <i>Passed</i>
                  </>
                }
                enabled={modalData.status}
                onChange={(value) => setModal("status", value)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default UjianCBT;
