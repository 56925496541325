import axios from "axios";
import headers from "services/Headers";

const createSipPpdgs = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/sip-ppdgs`,
    form,
    headers()
  );
};

const showSipPpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/sip-ppdgs/student/${id}`,
    headers()
  );
};

export { createSipPpdgs, showSipPpdgs };
