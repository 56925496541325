import axios from "axios";
import headers from "services/Headers";

const createPatientClinic = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik`,
    form,
    headers()
  );
};

const getPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/student/${id}`,
    headers()
  );
};

const showPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/task/${id}`,
    headers()
  );
};

const getToolsPatientClinic = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/tools`,
    headers()
  );
};

const createPatientClinicActivityJawUpper = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/activity-jaw-upper/${id}`,
    form,
    headers()
  );
};

const createPatientClinicActivityJawUnder = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/activity-jaw-under/${id}`,
    form,
    headers()
  );
};

const updateValueDataPatientClinic = (id, idData, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/data-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const updateDataRadiografyPatientClinic = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/date-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const createActivityMaterialPatientClinic = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/activity-material/${id}`,
    form,
    headers()
  );
};

const createToolPatientClinic = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik/patient-tools/${id}`,
    form,
    headers()
  );
};

export {
  createPatientClinic,
  getPatientClinic,
  showPatientClinic,
  getToolsPatientClinic,
  createPatientClinicActivityJawUpper,
  createPatientClinicActivityJawUnder,
  updateValueDataPatientClinic,
  updateDataRadiografyPatientClinic,
  createActivityMaterialPatientClinic,
  createToolPatientClinic,
};
