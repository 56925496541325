import axios from "axios";
import headers from "services/Headers";

const getSocialService = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/bakti-sosial/student/${id}`,
    headers()
  );
};

const showSocialService = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/bakti-sosial/task/${id}`,
    headers()
  );
};

export { getSocialService, showSocialService };
