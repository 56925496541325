import axios from "axios";
import headers from "services/Headers";

const showMemberPpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/surat-perjanjian-peserta-ppdgs/student/${id}`,
    headers()
  );
};

const updateMemberPpdgs = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/surat-perjanjian-peserta-ppdgs/${id}`,
    form,
    headers()
  );
};

export { showMemberPpdgs, updateMemberPpdgs };
