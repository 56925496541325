import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

// features
import {
  CheckNotification,
  ReadNotification,
} from "features/notificationSlice";

// services
import { getNotifications } from "services/User";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import InputDate from "components/Form/InputDate";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (firstDate != "" && lastDate != "") getData();
  }, [firstDate, lastDate]);

  const getData = async () => {
    try {
      const dataNotifications = await getNotifications(firstDate, lastDate);
      setNotifications(dataNotifications.data.body.data.notifications);
    } catch (error) {
      setNotifications([]);
    }
  };

  const readNotification = (notification, read = false) => {
    if (read) {
      dispatch(ReadNotification(notification.id)).then(() => {
        dispatch(CheckNotification());
        toast.success(<span>Success Read Notification!</span>);
        getData();
      });
    }

    navigate(routeNotification(notification));
  };

  const routeNotification = (notification) => {
    if (notification.category.toLowerCase() == "keamanan")
      return `/${getSlugRole()}/dashboard`;

    return `/${getSlugRole()}/requirement/${getSlugRequirement(
      notification.category,
      notification.task_id
    )}`;
  };

  const getSlugRole = () => {
    if (user?.role.name == "Mahasiswa") return "mahasiswa";
    else if (user?.role.name == "Dosen") return "dosen";
    else if (user?.role.name == "Prodi") return "prodi";
    else if (user?.role.name == "Administrator") return "admin";
  };

  const getSlugRequirement = (category, task_id) => {
    if (category.toLowerCase() == "surat perjanjian peserta ppdgs")
      return "surat-perjanjian-peserta-ppdgs";
    else if (category.toLowerCase() == "sertifikat kompetensi ppdgs")
      return "sertifikat-kompetensi-ppdgs";
    else if (category.toLowerCase() == "sertifikat pendidikan ppdgs")
      return "sertifikat-pendidikan-ppdgs";
    else if (category.toLowerCase() == "sip ppdgs") return "sip-ppdgs";
    else if (category.toLowerCase() == "orientasi rsgm")
      return "orientasi-rsgm";
    else if (category.toLowerCase() == "presentasi seminar")
      return "presentasi-seminar";
    else if (category.toLowerCase() == "kehadiran seminar")
      return "kehadiran-seminar";
    else if (category.toLowerCase() == "wire bending") return "wire-bending";
    else if (category.toLowerCase() == "pasien typodont")
      return `pasien-typodont/detail?id=${task_id}`;
    else if (category.toLowerCase() == "ujian cbt") return "ujian-cbt";
    else if (category.toLowerCase() == "pasien klinik")
      return `pasien-klinik/detail?id=${task_id}`;
    else if (category.toLowerCase() == "pasien klinik lanjutan")
      return `pasien-klinik-lanjutan/detail?id=${task_id}`;
    else if (category.toLowerCase() == "ujian mini cex")
      return "ujian-mini-cex";
    else if (category.toLowerCase() == "case report") return "case-report";
    else if (category.toLowerCase() == "presentasi poster atau pembicara")
      return "presentasi-poster-atau-pembicara";
    else if (category.toLowerCase() == "manuskrip jurnal")
      return "manuskrip-jurnal";
    else if (category.toLowerCase() == "bakti sosial") return "bakti-sosial";
    else if (category.toLowerCase() == "ujian osce") return "ujian-osce";
    else if (category.toLowerCase() == "ujian dops") return "ujian-dops";
    else if (category.toLowerCase() == "kehadiran kuliah pakar")
      return "kehadiran-kuliah-pakar";
    else if (category.toLowerCase() == "administrasi") return "administrasi";
  };

  return (
    <TFade>
      <Navbar title="Notifikasi" />

      {/* Filter */}
      <div className="mb-6 flex flex-wrap items-center justify-between gap-2">
        <h3 className="text-2xl font-medium">Filter Tanggal</h3>
        <div className="flex flex-wrap items-center gap-3 md:flex-nowrap">
          <InputDate
            name="firstDate"
            value={firstDate}
            required={false}
            placeholder="Tanggal Awal"
            inputClassName="w-full"
            isSmall
            onChange={(value) => setFirstDate(value)}
          />

          <svg
            className="mx-3 hidden md:block"
            width="20"
            height="2"
            viewBox="0 0 20 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="2" fill="#191919" fillOpacity="0.5" />
          </svg>

          <InputDate
            name="lastDate"
            value={lastDate}
            required={false}
            placeholder="Tanggal Akhir"
            inputClassName="w-full"
            isSmall
            onChange={(value) => setLastDate(value)}
          />
        </div>
      </div>

      {/* List Notification */}
      {notifications.map((notification, index) => (
        <div
          key={`notification-${index}`}
          onClick={
            notification.status
              ? () => readNotification(notification)
              : () => readNotification(notification, true)
          }
          className={`mb-4 cursor-pointer rounded-[20px] border-2 border-transparent p-5 transition hover:border-primary-yellow ${
            notification.status ? "bg-[#D7F1E3]" : "bg-white"
          }`}
        >
          <h5 className="text-xl font-medium">{notification.title}</h5>
          <span className="text-base text-accent-gray">
            {/* {moment(notification.createdAt).startOf("hour").fromNow()} -{" "}
            {notification.category} */}
            {moment(notification.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
      ))}

      {!notifications.length && (
        <span className="block text-center font-medium text-accent-gray">
          Data tidak ada.
        </span>
      )}

      <Footer />
    </TFade>
  );
};

export default Notification;
