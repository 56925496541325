import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// service
import { showPatientClinicContinue } from "services/Requirement/Prodi";
import { getStatus } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputStatus from "components/Requirement/InputStatus";

const PasienKlinikLanjutanTanggalPengambilanBahan = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bahan, setBahan] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinicContinue(searchParams.get("id"));

      setBahan(response.data.body.data.patient.activity_materials);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            {bahan.map((value, index) => (
              <InputStatus
                key={`value-${index}`}
                name={`Nama Bahan ${index + 1}`}
                name_en={`Ingredient Name ${index + 1}`}
                value={`${moment(value.date).format("DD MMMM yyy")} - ${
                  value.title
                }`}
                inputClassName="mb-4"
                status={getStatus(value, true)}
                data={value}
              />
            ))}
          </div>
        </form>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienKlinikLanjutanTanggalPengambilanBahan;
