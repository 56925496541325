import axios from "axios";
import headers from "services/Headers";

const createPresentasionSeminar = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/presentasi-seminar`,
    form,
    headers()
  );
};

const showPresentasionSeminar = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/presentasi-seminar/student/${id}`,
    headers()
  );
};

const getLectures = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/presentasi-seminar/lectures`,
    headers()
  );
};

export { createPresentasionSeminar, showPresentasionSeminar, getLectures };
