import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// service
import {
  createSocialService,
  getSocialService,
} from "services/Requirement/Mahasiswa";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputText from "components/Form/InputText";
import InputFolder from "components/Requirement/InputFolder";
import Status from "components/Requirement/Status";
import Detail from "components/Action/Detail";
import InputSearch from "components/Form/InputSearch";
import InputDate from "components/Form/InputDate";

// assets
import ic_search from "assets/icons/ic_search.svg";

const BaktiSosial = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const response = await getSocialService(user.id);

      setData(response.data.body.data.social_service);
      setFilteredData(response.data.body.data.social_service);
    } catch (error) {
      console.error(error);
    }
  };

  const filterBySearch = (query) => {
    // Create copy of item list
    var updatedList = [...data];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setSearch(query);
    setFilteredData(updatedList);
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createSocialService({
            date: moment(date).format("yyy-MM-DD"),
            title: title,
          });

          resetForm();
          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const resetForm = () => {
    setDate("");
    setTitle("");
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Bakti Sosial 1&2 <i className="block">Social services 1&2</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Topic"
            name_en="Topic"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <InputFolder
              name="Topic"
              name_en="Topic"
              onClick={() => setShowModal(true)}
            />

            {/* Filter */}
            <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
              <InputSearch
                name="search"
                value={search}
                required={false}
                placeholder="Search judul / title.."
                prepend={<img src={ic_search} alt="Icon search" />}
                onChange={(e) => filterBySearch(e.target.value)}
              />
            </div>

            <div className="mt-5 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-28 text-medium-gray lg:ml-56">
                    Status
                  </span>
                  <span className="mr-5 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {filteredData.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between gap-6 rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Tanggal Bakti Sosial {index + 1}
                          <i className="text-en">
                            Date of social service {index + 1}
                          </i>
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {value.title}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Judul Bakti Sosial {index + 1}
                          <i className="text-en">
                            Title of social service {index + 1}
                          </i>
                        </span>
                      </div>
                    </div>

                    <div className="grid w-auto grid-cols-2">
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.unready}
                      />
                      <Status
                        title="Softcopy"
                        className="my-1.5 mx-3"
                        status={value.soft_copy}
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.process}
                      />
                      <Status
                        title="Hardcopy"
                        className="my-1.5 mx-3"
                        status={value.hard_copy}
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        className="my-1.5 mx-3"
                        status={value.finish}
                      />
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(`detail?no=${index + 1}&id=${value.id}`)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Bakti Sosial 1&2"
        name_en=" Social services 1&2"
        textSubmit=""
        textSubmitEn=""
        form={
          <>
            <InputDate
              name="date"
              inputClassName="mb-4"
              placeholder={
                <>
                  Tanggal Bakti Sosial / <i>Date of social service</i>
                </>
              }
              value={date}
              onChange={(value) => setDate(value)}
            />
            <InputText
              name="title"
              type="text"
              placeholder={
                <>
                  Judul Bakti Sosial / <i>Title of social service</i>
                </>
              }
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default BaktiSosial;
