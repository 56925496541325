import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TFade from "components/Transition/Fade";
import moment from "moment";

// services
import {
  getPresenceSeminar,
  getStudentRequirementById,
} from "services/Requirement/Prodi";
import { confirmModal, successModal } from "services/general";

// components
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";
import Export from "components/Action/Export";
import InputStatusBig from "components/Requirement/InputStatusBig";
import NotFound from "components/NotFound";

const KehadiranSeminar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);

  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseProgress = await getStudentRequirementById(
        searchParams.get("rqm-id"),
        searchParams.get("id")
      );
      const response = await getPresenceSeminar(searchParams.get("id"));

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.presence);
      // setLogs(response.data.body.data.logs);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputStatusBig
              name={
                <>
                  Belum <i className="text-en">Incomplete</i>
                </>
              }
              enabled={progress.unfinish}
            />
            <InputStatusBig
              name={
                <>
                  Proses <i className="text-en">Process</i>
                </>
              }
              enabled={progress.process}
            />
            <InputStatusBig name="100%" enabled={progress.finish} />
          </div>

          <div className="mb-7 overflow-x-scroll md:overflow-visible">
            <div className="w-max md:w-full">
              <div className="mb-3 flex justify-between gap-4">
                <span className="text-medium-gray">
                  Tanggal Seminar / <i>Date of attendance</i>
                </span>
                <span className="ml-2 text-medium-gray">Status</span>
                <span className="mr-8 text-medium-gray">
                  Aksi / <i>Action</i>
                </span>
              </div>

              {data.length ? (
                data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex items-center justify-between gap-4 rounded-[20px] bg-white py-5 px-7"
                  >
                    {/* Title */}
                    <div className="flex flex-wrap items-center gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Kehadiran Seminar {index + 1}
                        </span>
                      </div>
                    </div>

                    <div className="grid w-full grid-cols-1 md:w-auto md:grid-cols-2">
                      <Status
                        title={
                          <>
                            Validasi 1 <i className="text-en">Validation 1</i>
                          </>
                        }
                        status={value.validation_1}
                        className="my-1.5 mx-3"
                      />

                      <Status
                        title={
                          <>
                            Validasi 2 <i className="text-en">Validation 2</i>
                          </>
                        }
                        status={value.validation_2}
                        className="my-1.5 mx-3"
                      />
                    </div>

                    <div className="mr-5">
                      <Export />
                    </div>
                  </div>
                ))
              ) : (
                <NotFound />
              )}
            </div>
          </div>

          {/* Log Activity */}
          {/* <LogActivity /> */}
        </div>

        <Footer />
      </TFade>
    </>
  );
};

export default KehadiranSeminar;
