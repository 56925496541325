import propTypes from "prop-types";

const InputToggleBig = (props) => {
  const { name, enabled, inputClassName } = props;

  return (
    <div
      className={[
        `flex items-center justify-between rounded-[20px] px-6 py-6 text-center text-3xl font-semibold text-white transition duration-300 md:py-8`,
        !enabled ? "bg-red" : "bg-green",
        inputClassName,
      ].join(" ")}
    >
      <span>{name}</span>

      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          checked={enabled}
          readOnly
        />
        <div
          onClick={() => props.onChange(!enabled)}
          className="peer h-[35px] w-[66px] rounded-full  bg-[#0000000D] transition duration-300 after:absolute after:top-1 after:left-[5px] after:h-[28px] after:w-[28px] after:rounded-full after:bg-red after:transition after:duration-300 after:content-[''] peer-checked:after:translate-x-[100%] peer-checked:after:bg-green"
        ></div>
      </label>
    </div>
  );
};

export default InputToggleBig;

InputToggleBig.propTypes = {
  name: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
  enabled: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  inputClassName: propTypes.string,
};
