// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import ListRequirement from "components/Prodi/ListRequirement";
import Footer from "components/Footer";

const Requirement = () => {
  return (
    <TFade>
      <HeaderRequirement />
      <ListRequirement />
      <Footer />
    </TFade>
  );
};

export default Requirement;
