import { useState } from "react";
import propTypes from "prop-types";

const InputTextArea = (props) => {
  const {
    value,
    placeholder,
    name,
    inputClassName,
    errorResponse,
    required,
    disabled,
  } = props;

  const [hasError, setHasError] = useState(null);

  const onChange = (e) => {
    const target = {
      target: {
        name,
        value: e.target.value,
      },
    };

    props.onChange(target);
  };

  return (
    <div className={[inputClassName].join(" ")}>
      <div className="relative z-0 overflow-hidden">
        <textarea
          id={name}
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          onChange={onChange}
          className={`peer block min-h-[100px] w-full appearance-none rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-2 text-base font-medium text-black transition placeholder-shown:pt-3 focus:border-gray-500 focus:pt-5 focus:outline-none focus:ring-0 ${
            hasError && "border-red focus:border-red"
          }`}
          placeholder=" "
        ></textarea>

        <label
          htmlFor={name}
          className={`absolute top-3 left-3 -z-10 origin-[0] -translate-y-2 scale-75 transform text-base text-accent-gray duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-accent-gray ${
            hasError && "text-red peer-focus:text-red"
          }`}
        >
          {placeholder}
        </label>
      </div>

      {hasError && (
        <span className="mt-1 block text-sm text-red">{hasError}</span>
      )}
    </div>
  );
};

export default InputTextArea;

InputTextArea.defaultProps = {
  placeholder: "Please type here..",
  errorResponse: "Please match the requested format.",
  required: true,
  disabled: false,
};

InputTextArea.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.element]),
  inputClassName: propTypes.string,
  required: propTypes.bool,
  disabled: propTypes.bool,
};
