import { useSearchParams } from "react-router-dom";

// components
import Navbar from "components/Navbar";

const HeaderRequirement = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Navbar />
      <div className="mb-4 flex items-center gap-5 rounded-[20px] bg-white px-3 py-4">
        <img
          src={`${process.env.REACT_APP_STORAGE}avatars/${searchParams.get(
            "avatar"
          )}`}
          alt="Avatar"
          className="h-[100px] w-[100px] rounded-full bg-cream object-cover"
        />
        <div>
          <h2 className="text-2xl font-semibold">{searchParams.get("name")}</h2>
          <span className="block text-accent-gray">
            {searchParams.get("npm")}
          </span>

          <div
            className={`mt-2 inline-block rounded-[10px] px-4 py-1 font-medium capitalize text-accent-gray ${
              searchParams.get("status_login") == "login"
                ? "bg-[#59CE8F1A]"
                : "bg-[#F3787833]"
            }`}
          >
            {searchParams.get("status_login") == "login" ? "Online" : "Offline"}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderRequirement;
