import axios from "axios";
import headers from "services/Headers";

const createWireBending = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/wire-bending`,
    form,
    headers()
  );
};

const getWireBending = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/wire-bending/student/${id}`,
    headers()
  );
};

const showWireBending = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/wire-bending/task/${id}`,
    headers()
  );
};

export { createWireBending, getWireBending, showWireBending };
