import axios from "axios";
import headers from "services/Headers";

const getPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/student/${id}`,
    headers()
  );
};

const showPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/task/${id}`,
    headers()
  );
};

const updatePatientTypodont = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/${id}`,
    form,
    headers()
  );
};

const updatePatientTypodontItem = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/data/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientTypodontActivityJawUpper = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/activity-jaw-upper/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientTypodontActivityJawUnder = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/activity-jaw-under/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientTypodontActivityMaterial = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/activity-material/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientTypodontTool = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/patient-tools/${id}/${idData}`,
    form,
    headers()
  );
};

export {
  getPatientTypodont,
  showPatientTypodont,
  updatePatientTypodont,
  updatePatientTypodontItem,
  updatePatientTypodontActivityJawUpper,
  updatePatientTypodontActivityJawUnder,
  updatePatientTypodontActivityMaterial,
  updatePatientTypodontTool,
};
