import axios from "axios";
import headers from "services/Headers";

const showExamMiniCex = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/ujian-mini-cex/student/${id}`,
    headers()
  );
};

const updateExamMiniCex = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/ujian-mini-cex/${id}`,
    form,
    headers()
  );
};

export { showExamMiniCex, updateExamMiniCex };
