import axios from "axios";
import headers from "services/Headers";

const showCompetencePpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/sertifikat-kompetensi-ppdgs/student/${id}`,
    headers()
  );
};

export { showCompetencePpdgs };
