import axios from "axios";
import headers from "services/Headers";

const showCompetencePpdgs = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/sertifikat-kompetensi-ppdgs/student/${id}`,
    headers()
  );
};

const updateCompetencePpdgs = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/sertifikat-kompetensi-ppdgs/${id}`,
    form,
    headers()
  );
};

export { showCompetencePpdgs, updateCompetencePpdgs };
