import axios from "axios";
import headers from "services/Headers";

const getStudents = (status = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/dosen/requirements/students`,
    {
      status,
    },
    headers()
  );
};

const getStudentRequirements = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/requirements/students/${id}`,
    headers()
  );
};

const getStudentRequirementById = (id, userId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/requirements/${userId}/${id}`,
    headers()
  );
};

const updateStudentStatusReport = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/requirements/reports/change-status/${id}`,
    form,
    headers()
  );
};

const updateStudentProgressReport = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/requirements/reports/change-progress/${id}`,
    form,
    headers()
  );
};

export {
  getStudents,
  getStudentRequirements,
  getStudentRequirementById,
  updateStudentStatusReport,
  updateStudentProgressReport,
};
