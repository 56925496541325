import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// service
import { showWireBending } from "services/Requirement/Mahasiswa";
import { getNilai } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Status from "components/Requirement/Status";
import LogActivity from "components/Requirement/LogActivity";

const WireBendingDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showWireBending(searchParams.get("id"));

      setData(response.data.body.data.wire_bending);
      setLogs(response.data.body.data.logs);

      setHasData(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <Navbar title="Requirement" subtitle={<span>Wire Bending</span>} />

        {hasData && (
          <div>
            <div className="mb-7 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Keterangan / <i>Information</i>
                  </span>
                  <span className="ml-60 text-medium-gray">Status</span>
                  <span className="mr-5 text-medium-gray">
                    Nilai / <i>Score</i>
                  </span>
                </div>

                <div className="mb-4 flex flex-wrap items-center justify-between rounded-[20px] bg-white px-4 py-5">
                  {/* Title */}
                  <div className="grid w-1/3 grid-cols-[0.3fr_2fr] gap-3">
                    <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                      {searchParams.get("no")}
                    </div>

                    <div>
                      <h3 className="text-xl font-semibold">{data.name}</h3>
                      <span className="text-base text-accent-gray">
                        Nama Pola <i className="text-en">Name of pattern</i>
                      </span>

                      <h3 className="mt-5 text-xl font-semibold">
                        {moment(data.date).format("D MMMM yyy")}
                      </h3>
                      <span className="text-base text-accent-gray">
                        Tanggal Mulai <i className="text-en">Date of start</i>
                      </span>
                    </div>
                  </div>

                  <div>
                    <Status
                      title={
                        <>
                          Belum <i className="text-en">Incomplete</i>
                        </>
                      }
                      status={data.unready}
                      className="mx-3 mb-3"
                    />
                    <Status
                      title={
                        <>
                          Proses <i className="text-en">Process</i>
                        </>
                      }
                      status={data.process}
                      className="mx-3 mb-3"
                    />
                    <Status
                      title={
                        <>
                          Sudah <i className="text-en">Complete</i>
                        </>
                      }
                      status={data.finish}
                      className="mx-3 mb-3"
                    />
                  </div>

                  <div className="mr-5">
                    <span className="flex flex-col items-center justify-center text-xl">
                      {data.value}
                      <svg
                        width="25"
                        height="2"
                        viewBox="0 0 25 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="25" height="1.5" fill="#FFF1CE" />
                      </svg>
                      <b className="text-primary-yellow">
                        {getNilai(data.value)}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Log Activity */}
            <LogActivity data={logs} />
          </div>
        )}
        <Footer />
      </TFade>
    </>
  );
};

export default WireBendingDetail;
