import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Requirement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/mahasiswa", { replace: true });
  }, [navigate]);

  return <div></div>;
};

export default Requirement;
