import { useEffect, useState } from "react";

// services
import { showImageLogin } from "services/Admin";

const ImageAuth = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showImageLogin();
      setData(response.data.body.data.image);
    } catch (error) {
      console.error(error);
    }
  };

  if(data) {
    return (
      <img   src={`${process.env.REACT_APP_STORAGE}image-logins/${data.image}`} alt="Image Login" />
    )
  }
}

export default ImageAuth