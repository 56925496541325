import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// service
import {
  showPatientClinic,
  createActivityMaterialPatientClinic,
} from "services/Requirement/Mahasiswa";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import InputDate from "components/Form/InputDate";
import InputText from "components/Form/InputText";
import InputFolder from "components/Requirement/InputFolder";
import InputStatus from "components/Requirement/InputStatus";
import Modal from "components/Requirement/Modal";

// assets
import ic_calendar from "assets/icons/ic_calendar.svg";
import Min from "components/Action/Min";

const PasienKlinikTanggalPengambilanBahan = () => {
  const [hasData, setHasData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [bahan, setBahan] = useState([
    {
      date: "",
      title: "",
    },
  ]);

  const [showModalBahan, setShowModalBahan] = useState(false);
  const [bahanTemp, setBahanTemp] = useState({
    date: "",
    title: "",
  });

  const addBahan = () => {
    const newValues = [...bahan];
    newValues.push({ date: "", title: "" });

    setBahan(newValues);
  };

  const removeBahan = (index) => {
    const newValues = [...bahan];
    newValues.splice(index, 1);

    setBahan(newValues);
  };

  const submitBahan = (e) => {
    e.preventDefault();

    if (bahanTemp.date == "" || bahanTemp.title == "") {
      toast.error(
        <span>
          Harap mengisi bahan terlebih dahulu!
          <i className="text-en">Please fill in the ingredients first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createActivityMaterialPatientClinic(searchParams.get("id"), {
            date: moment(bahanTemp.date).format("yyy-MM-DD"),
            title: bahanTemp.title,
          });

          getData();
          setShowModalBahan(false);
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientClinic(searchParams.get("id"));
      setBahan(response.data.body.data.patient.activity_materials);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Pasien Klinik <i className="block">Clinic Patient</i>
            </span>
          }
        />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <InputFolder
            name="Topik"
            name_en="Topic"
            className="mb-8 !py-6"
            onClick={() => addBahan()}
          />

          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            {bahan.map((value, index) =>
              value.id ? (
                <InputStatus
                  key={`bahan-${index}`}
                  name={`Nama Bahan ${index + 1}`}
                  name_en={`Ingredient Name ${index + 1}`}
                  value={`${moment(value.date).format("DD MMMM yyy")} - ${
                    value.title
                  }`}
                  status={getStatus(value, true)}
                  data={value}
                />
              ) : (
                <div className="flex items-center gap-3">
                  <div
                    key={`bahan-${index}`}
                    className={`relative z-0 block h-14 w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-14 text-base font-medium text-black transition ${
                      !value.date || !value.title ? "pt-3" : ""
                    }`}
                    onClick={() => {
                      value.index = index;
                      setBahanTemp(value);
                      setShowModalBahan(true);
                    }}
                  >
                    {value.date && value.title
                      ? `${moment(value.date).format("DD MMMM yyy")} - ${
                          value.title
                        }`
                      : ""}
                    <label
                      className={`absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base text-accent-gray duration-300 ${
                        !value.date || !value.title
                          ? "top-3.5 translate-y-0 scale-100 text-accent-gray"
                          : ""
                      }`}
                    >
                      Nama Bahan {index + 1}
                      <i className="text-en">Ingredient Name {index + 1}</i>
                    </label>
                    <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] items-center justify-center">
                      <img src={ic_calendar} alt="Icon Calendar" />
                    </div>
                  </div>

                  <Min onClick={() => removeBahan(index)} />
                </div>
              )
            )}
          </div>
        </form>

        <Footer />
      </TFade>
      <Modal
        name="Bahan"
        name_en="Ingredient"
        textSubmit=""
        textSubmitEn=""
        form={
          <>
            <InputDate
              name="date"
              placeholder={
                <>
                  Tanggal Bahan / <i>Material date</i>
                </>
              }
              inputClassName="mb-4"
              value={bahanTemp.date}
              onChange={(value) => {
                const newValues = { ...bahanTemp };
                newValues.date = value;
                setBahanTemp(newValues);
              }}
            />
            <InputText
              name="name"
              type="text"
              placeholder={
                <>
                  Nama Bahan / <i>Name of material</i>
                </>
              }
              value={bahanTemp.title}
              onChange={(e) => {
                const newValues = { ...bahanTemp };
                newValues.title = e.target.value;
                setBahanTemp(newValues);
              }}
            />
          </>
        }
        showModal={showModalBahan}
        setShowModal={(value) => setShowModalBahan(value)}
        onSubmit={submitBahan}
      />
    </>
  );
};

export default PasienKlinikTanggalPengambilanBahan;
