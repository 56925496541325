import axios from "axios";
import headers from "services/Headers";

const getWireBending = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/wire-bending/student/${id}`,
    headers()
  );
};

const showWireBending = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/wire-bending/task/${id}`,
    headers()
  );
};

export { getWireBending, showWireBending };
