import axios from "axios";
import headers from "services/Headers";

const showExamMiniCex = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/ujian-mini-cex/student/${id}`,
    headers()
  );
};

export { showExamMiniCex };
