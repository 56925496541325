import axios from "axios";
import headers from "services/Headers";

const createAdministrasi = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/administrasi`,
    form,
    headers()
  );
};

const getAdministrasi = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/administrasi/student/${id}`,
    headers()
  );
};

const showAdministrasi = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/administrasi/task/${id}`,
    headers()
  );
};

export { createAdministrasi, getAdministrasi, showAdministrasi };
