import propTypes from "prop-types";

const InputToggle = (props) => {
  const { name, enabled, inputClassName } = props;

  return (
    <div
      className={[
        `flex items-center justify-between rounded-[5px] px-3 py-2 transition duration-300`,
        !enabled ? "bg-[#F3787833]" : "bg-[#59CE8F33]",
        inputClassName,
      ].join(" ")}
    >
      <span className="text-base font-medium text-accent-gray">{name}</span>

      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          checked={enabled}
          readOnly
        />
        <div
          onClick={() => props.onChange(!enabled)}
          className="peer h-[30px] w-[57px] rounded-full  bg-[#0000000D] transition duration-300 after:absolute after:top-1 after:left-[5px] after:h-[22px] after:w-[22px] after:rounded-full after:bg-red after:transition after:duration-300 after:content-[''] peer-checked:after:translate-x-[120%] peer-checked:after:bg-green"
        ></div>
      </label>
    </div>
  );
};

export default InputToggle;

InputToggle.propTypes = {
  name: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
  enabled: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  inputClassName: propTypes.string,
};
