import axios from "axios";
import headers from "services/Headers";

const getPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/student/${id}`,
    headers()
  );
};

const showPatientTypodont = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-typodont/task/${id}`,
    headers()
  );
};

const exportToolsPatient = (id, all = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/tools`,
    {
      student: all,
      requirement_id: id,
    },
    headers()
  );
};

const exportMaterialsPatient = (id, all = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/materials`,
    {
      student: all,
      requirement_id: id,
    },
    headers()
  );
};

const exportJawUndersPatient = (id, all = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/jaw-unders`,
    {
      student: all,
      requirement_id: id,
    },
    headers()
  );
};

const exportJawUppersPatient = (id, all = "all") => {
  return axios.post(
    `${process.env.REACT_APP_API}/excel/export/users/requirement/patient/jaw-uppers`,
    {
      student: all,
      requirement_id: id,
    },
    headers()
  );
};

export {
  getPatientTypodont,
  showPatientTypodont,
  exportToolsPatient,
  exportMaterialsPatient,
  exportJawUndersPatient,
  exportJawUppersPatient,
};
