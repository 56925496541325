// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";

import Footer from "components/Footer";
import ListRequirementResume from "components/Prodi/ListRequirementResume";

const Resume = () => {
  return (
    <TFade>
      <Navbar title="Resume Tugas" />

      <ListRequirementResume />
      <Footer />
    </TFade>
  );
};

export default Resume;
