import axios from "axios";
import headers from "services/Headers";

const createExamDops = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-dops`,
    form,
    headers()
  );
};

const showExamDops = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-dops/student/${id}`,
    headers()
  );
};

export { createExamDops, showExamDops };
