import axios from "axios";
import headers from "services/Headers";

const getPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/student/${id}`,
    headers()
  );
};

const showPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/task/${id}`,
    headers()
  );
};

const updatePatientClinic = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/${id}`,
    form,
    headers()
  );
};

const updatePatientClinicItem = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/data/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicActivityJawUpper = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/activity-jaw-upper/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicActivityJawUnder = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/activity-jaw-under/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicActivityMaterial = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/activity-material/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicRadiografy = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/date-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const updatePatientClinicTool = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/patient-tools/${id}/${idData}`,
    form,
    headers()
  );
};

export {
  getPatientClinic,
  showPatientClinic,
  updatePatientClinic,
  updatePatientClinicItem,
  updatePatientClinicActivityJawUpper,
  updatePatientClinicActivityJawUnder,
  updatePatientClinicActivityMaterial,
  updatePatientClinicRadiografy,
  updatePatientClinicTool,
};
