import axios from "axios";
import headers from "services/Headers";

const createBanner = (form) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("job", form.job);
  formData.append("message", form.message);
  formData.append("image", form.image);

  headers.contentType = "multipart/form-data";

  return axios.post(
    `${process.env.REACT_APP_API}/administrator/banners`,
    formData,
    headers()
  );
};

const getBanner = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/banners/list-banners`,
    headers()
  );
};

const updateBanner = (id, form) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("job", form.job);
  formData.append("message", form.message);
  formData.append("image", form.image);

  headers.contentType = "multipart/form-data";

  return axios.patch(
    `${process.env.REACT_APP_API}/administrator/banners/${id}`,
    formData,
    headers()
  );
};

const deleteBanner = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API}/administrator/banners/${id}`,
    headers()
  );
};

export { createBanner, getBanner, updateBanner, deleteBanner };
