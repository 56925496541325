import axios from "axios";
import headers from "services/Headers";

const getPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/student/${id}`,
    headers()
  );
};

const showPatientClinic = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/dosen/pasien-klinik/task/${id}`,
    headers()
  );
};

export { getPatientClinic, showPatientClinic };
