import axios from "axios";
import headers from "services/Headers";

const createCaseReport = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/case-report`,
    form,
    headers()
  );
};

const showCaseReport = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/case-report/student/${id}`,
    headers()
  );
};

export { createCaseReport, showCaseReport };
