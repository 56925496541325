import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// language
import languages from "languages/id_en";

// service
import {
  showPatientTypodont,
  updateValueDataPatientTypodont,
} from "services/Requirement/Mahasiswa";
import {
  confirmModal,
  getStatus,
  successModal,
  toCapitalizeEachWord,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import InputDateTypodont from "components/Form/InputDateTypodont";
import InputText from "components/Form/InputText";
import InputRadio from "components/Form/InputRadio";
import InputStatus from "components/Requirement/InputStatus";
import Modal from "components/Requirement/Modal";

// assets
import ic_pencil_yellow from "assets/icons/ic_pencil_yellow.svg";

const PasienTypodontDetail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);

  // Form
  const [jenisAlatOrtodonti, setJenisAlatOrtodonti] = useState("");
  const [klasifikasiMaloklusiAngle, setKlasifikasiMaloklusiAngle] =
    useState("");
  const [klasifikasiSkeletal, setKlasifikasiSkeletal] = useState("");

  // Modal
  const [
    showModalKlasifikasiMaloklusiAngle,
    setShowModalKlasifikasiMaloklusiAngle,
  ] = useState(false);
  const [
    klasifikasiMaloklusiAngleKelasTemp,
    setKlasifikasiMaloklusiAngleKelasTemp,
  ] = useState("");
  const [
    klasifikasiMaloklusiAngleTipeTemp,
    setKlasifikasiMaloklusiAngleTipeTemp,
  ] = useState("");

  const [showModalKlasifikasiSkeletal, setShowModalKlasifikasiSkeletal] =
    useState(false);
  const [klasifikasiSkeletalTemp, setKlasifikasiSkeletalTemp] = useState("");

  const [showModalJenisAlatOrtodonti, setShowModalJenisAlatOrtodonti] =
    useState(false);
  const [jenisAlatOrtodontiTemp, setJenisAlatOrtodontiTemp] = useState("");

  // Function
  const changeKlasifikasiMaloklusiAngleKelasTemp = (value) => {
    setKlasifikasiMaloklusiAngleKelasTemp(value);
    setKlasifikasiMaloklusiAngleTipeTemp("");
  };

  const submitKlasifikasiMaloklusiAngle = (e) => {
    e.preventDefault();

    if (
      !klasifikasiMaloklusiAngleKelasTemp ||
      !klasifikasiMaloklusiAngleTipeTemp
    ) {
      toast.error(
        <span>
          Harap pilih klasifikasi maloklusi angle terlebih dahulu!
          <i className="text-en">
            Please select the angle malocclusion classification first!
          </i>
        </span>
      );
      return;
    }

    changeData(
      `${klasifikasiMaloklusiAngleKelasTemp} - ${klasifikasiMaloklusiAngleTipeTemp}`,
      54,
      true
    );

    setShowModalKlasifikasiMaloklusiAngle(false);
  };

  const submitKlasifikasiSkeletal = (e) => {
    e.preventDefault();

    if (!klasifikasiSkeletalTemp) {
      toast.error(
        <span>
          Harap pilih klasifikasi skeletal terlebih dahulu!
          <i className="text-en">
            Please select a skeletal classification first!
          </i>
        </span>
      );
      return;
    }

    changeData(klasifikasiSkeletalTemp, 55, true);
    setShowModalKlasifikasiSkeletal(false);
  };

  const submitJenisAlatOrtodonti = (e) => {
    e.preventDefault();

    changeData(jenisAlatOrtodontiTemp, 54, true);
    setShowModalJenisAlatOrtodonti(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientTypodont(searchParams.get("id"));
      const data = response.data.body.data.patient.data_patient_typodont;

      setData(data);
      // setJenisAlatOrtodonti(data[54].value ?? "");
      setKlasifikasiMaloklusiAngle(data[54].value ?? "");
      setKlasifikasiSkeletal(data[55].value ?? "");
    } catch (error) {
      console.error(error);
    }
  };

  const changeData = async (value, index, isString = false) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateValueDataPatientTypodont(
            searchParams.get("id"),
            data[index].id,
            {
              value: isString ? value : moment(value).format("yyy-MM-DD"),
            }
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Pasien Typodont <i className="block">Typodont patient</i>
            </span>
          }
        />

        {data.length ? (
          <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
            <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
              {data.map(
                (item, index) =>
                  index < 54 &&
                  (item.value ? (
                    <InputStatus
                      key={`item-${index}`}
                      name={toCapitalizeEachWord(item.key)}
                      name_en={languages[item.key]}
                      value={moment(item.value).format("D MMMM yyy")}
                      status={getStatus(item, true)}
                      data={item}
                    />
                  ) : (
                    <InputDateTypodont
                      key={`item-${index}`}
                      name={item.key}
                      value={item.value ? item.value : ""}
                      placeholder={
                        <>
                          {toCapitalizeEachWord(item.key)}
                          <i className="text-en">{languages[item.key]}</i>
                        </>
                      }
                      onChange={(value) => changeData(value, index)}
                    />
                  ))
              )}

              <div
                className={`text-gray-550 relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 py-3.5 text-base font-medium  transition`}
                onClick={() =>
                  navigate(
                    `tanggal-pengambilan-bahan?id=${searchParams.get("id")}`
                  )
                }
              >
                Tanggal Pengambilan Bahan
                <i className="text-en">Date of material pickup</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>
            </div>

            <hr className="my-5" />

            <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
              <div
                className={`text-gray-550 relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 py-3.5 text-base font-medium  transition`}
                onClick={() =>
                  navigate(`alat-ortodonti?id=${searchParams.get("id")}`)
                }
              >
                Jenis Peranti Ortodonti
                <i className="text-en">Type of orthodontics appliance</i>
                <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                  <img src={ic_pencil_yellow} alt="Icon Pencil" />
                </div>
              </div>

              {data[54].value ? (
                <InputStatus
                  name="Klasifikasi maloklusi angle"
                  name_en="Angle Malocclusion"
                  value={klasifikasiMaloklusiAngle}
                  status={getStatus(data[54], true)}
                  data={data[54]}
                />
              ) : (
                <div
                  className={`relative z-0 block w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-14 text-base font-medium text-black transition ${
                    !klasifikasiMaloklusiAngle && "pt-3"
                  }`}
                  onClick={() => {
                    let value = klasifikasiMaloklusiAngle;

                    if (value) {
                      value = value.split(" - ");
                      setKlasifikasiMaloklusiAngleKelasTemp(value[0]);
                      setKlasifikasiMaloklusiAngleTipeTemp(value[1]);
                    }

                    setShowModalKlasifikasiMaloklusiAngle(true);
                  }}
                >
                  {klasifikasiMaloklusiAngle}

                  <label
                    className={`text-gray-550 absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base duration-300 ${
                      !klasifikasiMaloklusiAngle &&
                      "top-3.5 translate-y-0 scale-100 text-accent-gray"
                    }`}
                  >
                    Klasifikasi Maloklusi Angle
                    <i className="text-en">Angle Malocclusion</i>
                  </label>
                  <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                    <img src={ic_pencil_yellow} alt="Icon Pencil" />
                  </div>
                </div>
              )}

              {data[55].value ? (
                <InputStatus
                  name="Klasifikasi skeletal"
                  name_en="Skeletal classification"
                  value={klasifikasiSkeletal}
                  status={getStatus(data[55], true)}
                  data={data[55]}
                />
              ) : (
                <div
                  className={`relative z-0 block h-14 w-full cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-14 text-base font-medium text-black transition ${
                    !klasifikasiSkeletal && "pt-3"
                  }`}
                  onClick={() => {
                    setKlasifikasiSkeletalTemp(klasifikasiSkeletal);
                    setShowModalKlasifikasiSkeletal(true);
                  }}
                >
                  {klasifikasiSkeletal}

                  <label
                    className={`text-gray-550 absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base duration-300 ${
                      !klasifikasiSkeletal &&
                      "top-3.5 translate-y-0 scale-100 text-accent-gray"
                    }`}
                  >
                    Klasifikasi Skeletal
                    <i className="text-en">Skeletal classification</i>
                  </label>
                  <div className="absolute top-1.5 right-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                    <img src={ic_pencil_yellow} alt="Icon Pencil" />
                  </div>
                </div>
              )}
            </div>

            <div
              onClick={() =>
                navigate(`aktivasi-rahang?id=${searchParams.get("id")}`)
              }
              className="mt-5 flex cursor-pointer select-none items-center justify-between rounded-[10px] bg-cream px-2 py-2 text-xl font-medium"
            >
              <span>
                Tanggal Aktivasi Rahang Atas & Bawah
                <i className="text-en">Upper & Lower Activation Dates</i>
              </span>
              <div className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-[10px] bg-[#F4AE0036] transition duration-300 hover:opacity-80">
                <img src={ic_pencil_yellow} alt="Icon Pencil" />
              </div>
            </div>
          </form>
        ) : (
          <span className="block text-center font-medium text-accent-gray">
            Loading...
          </span>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Klasifikasi Maloklusi Angle"
        name_en="Angle Malocclusion"
        textSubmit=""
        textSubmitEn=""
        form={
          <>
            <div className="grid grid-cols-3 gap-4">
              <InputRadio
                name="kelasI"
                value="Kelas I"
                placeholder={
                  <>
                    Kelas I <i className="text-en">Class I</i>
                  </>
                }
                onClick={(value) =>
                  changeKlasifikasiMaloklusiAngleKelasTemp(value)
                }
                selected={
                  klasifikasiMaloklusiAngleKelasTemp === "Kelas I"
                    ? true
                    : false
                }
              />

              <InputRadio
                name="kelasII"
                value="Kelas II"
                placeholder={
                  <>
                    Kelas II <i className="text-en">Class II</i>
                  </>
                }
                onClick={(value) =>
                  changeKlasifikasiMaloklusiAngleKelasTemp(value)
                }
                selected={
                  klasifikasiMaloklusiAngleKelasTemp === "Kelas II"
                    ? true
                    : false
                }
              />

              <InputRadio
                name="kelasIII"
                value="Kelas III"
                placeholder={
                  <>
                    Kelas III <i className="text-en">Class III</i>
                  </>
                }
                onClick={(value) =>
                  changeKlasifikasiMaloklusiAngleKelasTemp(value)
                }
                selected={
                  klasifikasiMaloklusiAngleKelasTemp === "Kelas III"
                    ? true
                    : false
                }
              />
            </div>

            {klasifikasiMaloklusiAngleKelasTemp === "Kelas I" ? (
              <div className="mt-4 grid grid-cols-3 gap-x-4">
                <InputRadio
                  name="tipe1"
                  value="Tipe 1"
                  placeholder={
                    <>
                      Tipe 1 <i className="text-en">Type 1</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 1"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe2"
                  value="Tipe 2"
                  placeholder={
                    <>
                      Tipe 2 <i className="text-en">Type 2</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 2"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe3"
                  value="Tipe 3"
                  placeholder={
                    <>
                      Tipe 3 <i className="text-en">Type 3</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 3"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe4"
                  value="Tipe 4"
                  placeholder={
                    <>
                      Tipe 4 <i className="text-en">Type 4</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 4"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe5"
                  value="Tipe 5"
                  placeholder={
                    <>
                      Tipe 5 <i className="text-en">Type 5</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 5"
                      ? true
                      : false
                  }
                />
              </div>
            ) : klasifikasiMaloklusiAngleKelasTemp === "Kelas II" ? (
              <div className="mt-4 grid grid-cols-3 gap-x-4">
                <InputRadio
                  name="divisi1"
                  value="Divisi 1"
                  placeholder={
                    <>
                      Divisi 1 <i className="text-en">Division 1</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Divisi 1"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="divisi2"
                  value="Divisi 2"
                  placeholder={
                    <>
                      Divisi 2 <i className="text-en">Division 2</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Divisi 2"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="subDivisiKanan"
                  value="Sub Divisi Kanan"
                  placeholder={
                    <>
                      Sub Divisi Kanan{" "}
                      <i className="text-en">Right sub-division</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Sub Divisi Kanan"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="subDivisiKiri"
                  value="Sub Divisi Kiri"
                  placeholder={
                    <>
                      Sub Divisi Kiri{" "}
                      <i className="text-en">Left sub-division</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Sub Divisi Kiri"
                      ? true
                      : false
                  }
                />
              </div>
            ) : klasifikasiMaloklusiAngleKelasTemp === "Kelas III" ? (
              <div className="mt-4 grid grid-cols-3 gap-x-4">
                <InputRadio
                  name="tipe1"
                  value="Tipe 1"
                  placeholder={
                    <>
                      Tipe 1 <i className="text-en">Type 1</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 1"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe2"
                  value="Tipe 2"
                  placeholder={
                    <>
                      Tipe 2 <i className="text-en">Type 2</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 2"
                      ? true
                      : false
                  }
                />

                <InputRadio
                  name="tipe3"
                  value="Tipe 3"
                  placeholder={
                    <>
                      Tipe 3 <i className="text-en">Type 3</i>
                    </>
                  }
                  isSmall
                  onClick={(value) =>
                    setKlasifikasiMaloklusiAngleTipeTemp(value)
                  }
                  selected={
                    klasifikasiMaloklusiAngleTipeTemp === "Tipe 3"
                      ? true
                      : false
                  }
                />
              </div>
            ) : (
              ""
            )}
          </>
        }
        showModal={showModalKlasifikasiMaloklusiAngle}
        setShowModal={(value) => setShowModalKlasifikasiMaloklusiAngle(value)}
        onSubmit={submitKlasifikasiMaloklusiAngle}
      />

      <Modal
        name="Klasifikasi Skeletal"
        name_en="Skeletal classification"
        textSubmit=""
        textSubmitEn=""
        form={
          <div className="grid grid-cols-3 gap-4">
            <InputRadio
              name="kelasI"
              value="Kelas I"
              placeholder={
                <>
                  Kelas I <i className="text-en">Class I</i>
                </>
              }
              onClick={(value) => setKlasifikasiSkeletalTemp(value)}
              selected={klasifikasiSkeletalTemp === "Kelas I" ? true : false}
            />

            <InputRadio
              name="kelasII"
              value="Kelas II"
              placeholder={
                <>
                  Kelas II <i className="text-en">Class II</i>
                </>
              }
              onClick={(value) => setKlasifikasiSkeletalTemp(value)}
              selected={klasifikasiSkeletalTemp === "Kelas II" ? true : false}
            />

            <InputRadio
              name="kelasIII"
              value="Kelas III"
              placeholder={
                <>
                  Kelas III <i className="text-en">Class III</i>
                </>
              }
              onClick={(value) => setKlasifikasiSkeletalTemp(value)}
              selected={klasifikasiSkeletalTemp === "Kelas III" ? true : false}
            />
          </div>
        }
        showModal={showModalKlasifikasiSkeletal}
        setShowModal={(value) => setShowModalKlasifikasiSkeletal(value)}
        onSubmit={submitKlasifikasiSkeletal}
      />

      <Modal
        name="Jenis Peranti Ortodonti"
        name_en="Type of orthodontics appliance"
        textSubmit=""
        textSubmitEn=""
        form={
          <InputText
            name="jenisAlatOrtodonti"
            type="text"
            placeholder={
              <>Jenis Peranti Ortodonti / Type of Orthodontic Appliance</>
            }
            inputClassName="mb-4"
            value={jenisAlatOrtodontiTemp}
            onChange={(e) => setJenisAlatOrtodontiTemp(e.target.value)}
          />
        }
        showModal={showModalJenisAlatOrtodonti}
        setShowModal={(value) => setShowModalJenisAlatOrtodonti(value)}
        onSubmit={submitJenisAlatOrtodonti}
      />
    </>
  );
};

export default PasienTypodontDetail;
