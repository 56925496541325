import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// service
import {
  createAdministrasi,
  getAdministrasi,
} from "services/Requirement/Mahasiswa";
import { confirmModal, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Input from "components/Requirement/Input";
import Modal from "components/Requirement/Modal";
import InputDate from "components/Form/InputDate";
import InputFolder from "components/Requirement/InputFolder";
import Status from "components/Requirement/Status";
import Detail from "components/Action/Detail";

const Administrasi = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [date2, setDate2] = useState("");

  useEffect(() => {
    if (user) getData();
  }, [user]);

  const getData = async () => {
    try {
      const response = await getAdministrasi(user.id);
      setData(response.data.body.data.administrasi);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (date === "" || date2 === "") {
      toast.error(
        <span>
          Masukkan tanggal terlebih dahulu!
          <i className="text-en">Enter the date first!</i>
        </span>
      );
      return;
    }

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createAdministrasi({
            date_soft_copy: moment(date).format("yyy-MM-DD"),
            date_hard_copy: moment(date2).format("yyy-MM-DD"),
          });

          resetForm();
          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const resetForm = () => {
    setDate("");
    setDate2("");
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Administrasi <i className="block">Administration</i>
            </span>
          }
        />

        {!data.length ? (
          <Input
            name="Data"
            name_en="Data"
            onClick={() => setShowModal(true)}
          />
        ) : (
          <div>
            <InputFolder
              name="Data"
              name_en="Data"
              onClick={() => setShowModal(true)}
            />

            <div className="mt-5 overflow-x-scroll md:overflow-visible">
              <div className="w-max md:w-full">
                <div className="mb-3 flex justify-between gap-4">
                  <span className="text-medium-gray">
                    Tanggal / <i>Date</i>
                  </span>
                  <span className="ml-28 text-medium-gray">Status</span>
                  <span className="mr-5 text-medium-gray">
                    Aksi / <i>Action</i>
                  </span>
                </div>

                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="mb-4 flex flex-wrap items-center justify-between gap-16 rounded-[20px] bg-white px-4 py-5"
                  >
                    {/* Title */}
                    <div className="flex gap-3">
                      <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-cream text-2xl font-medium text-primary-yellow">
                        {index + 1}
                      </div>

                      <div>
                        <h3 className="text-xl font-semibold">
                          {moment(value.date_soft_copy).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Softcopy
                        </span>

                        <h3 className="mt-5 text-xl font-semibold">
                          {moment(value.date_hard_copy).format("D MMMM yyy")}
                        </h3>
                        <span className="text-base text-accent-gray">
                          Hardcopy
                        </span>
                      </div>
                    </div>

                    <div className="grid w-auto grid-cols-2">
                      <Status
                        title={
                          <>
                            Belum <i className="text-en">Incomplete</i>
                          </>
                        }
                        status={value.unready}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Validasi 1 <i className="text-en">Validation 1</i>
                          </>
                        }
                        status={value.validation_1}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Proses <i className="text-en">Process</i>
                          </>
                        }
                        status={value.process}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Validasi 2 <i className="text-en">Validation 2</i>
                          </>
                        }
                        status={value.validation_2}
                        className="my-1.5 mx-3"
                      />
                      <Status
                        title={
                          <>
                            Sudah <i className="text-en">Complete</i>
                          </>
                        }
                        status={value.finish}
                        className="my-1.5 mx-3"
                      />
                    </div>

                    <div className="mr-5">
                      <Detail
                        onClick={() =>
                          navigate(`detail?no=${index + 1}&id=${value.id}`)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </TFade>
      <Modal
        name="Administrasi"
        name_en="Administration"
        textSubmit=""
        form={
          <>
            <InputDate
              name="date"
              inputClassName="mb-4"
              placeholder={
                <>
                  Tanggal Softcopy / <i>Date of softcopy submission</i>
                </>
              }
              value={date}
              onChange={(value) => setDate(value)}
            />
            <InputDate
              name="date2"
              placeholder={
                <>
                  Tanggal Hardcopy / <i>Date of hardcopy submission</i>
                </>
              }
              value={date2}
              onChange={(value) => setDate2(value)}
            />
          </>
        }
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
      />
    </>
  );
};

export default Administrasi;
