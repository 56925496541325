import axios from "axios";
import headers from "services/Headers";

const createOrientationRsgm = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/orientasi-rsgm`,
    form,
    headers()
  );
};

const showOrientationRsgm = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/orientasi-rsgm/student/${id}`,
    headers()
  );
};

export { createOrientationRsgm, showOrientationRsgm };
