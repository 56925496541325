import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

// Service
import { strippedContent } from "services/general";
import { getActivities, getNotifications } from "services/User";

// components
import NotFound from "components/NotFound";

// assets
import ic_eye from "assets/icons/ic_eye.svg";
import ic_pencil from "assets/icons/ic_pencil.svg";
import ic_list from "assets/icons/ic_list.svg";

const SidebarProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [activities, setActivities] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const dataActivities = await getActivities();
      const dataNotifications = await getNotifications();

      setActivities(dataActivities.data.body.data.activities.slice(0, 3));
      setNotifications(
        dataNotifications.data.body.data.notifications.slice(0, 3)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getSlugRole = () => {
    if (user?.role.name == "Mahasiswa") return "mahasiswa";
    else if (user?.role.name == "Dosen") return "dosen";
    else if (user?.role.name == "Prodi") return "prodi";
    else if (user?.role.name == "Administrator") return "admin";
  };

  const getRoleName = () => {
    if (user?.role.name == "Mahasiswa")
      return (
        <>
          Mahasiswa <i className="text-en">Student</i>
        </>
      );
    else if (user?.role.name == "Dosen")
      return (
        <>
          Dosen <i className="text-en">Lecture</i>
        </>
      );
    else if (user?.role.name == "Prodi")
      return (
        <>
          Prodi <i className="text-en">Study Programme</i>
        </>
      );
    else if (user?.role.name == "Administrator") return <>Admin</>;
  };

  return (
    <>
      {/* Profile */}
      {user && (
        <div className="rounded-[20px] bg-white p-3 text-center">
          <div className="relative h-[100px] rounded-[15px] bg-cream">
            <Link
              to={`/${getSlugRole()}/profile`}
              className="absolute top-3 right-3 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-white transition hover:bg-gray-50"
            >
              <img
                src={user?.role.name == "Mahasiswa" ? ic_eye : ic_pencil}
                alt="Icon"
              />
            </Link>
          </div>
          <img
            src={`${process.env.REACT_APP_STORAGE}avatars/${user.avatar}`}
            alt="Avatar"
            className="relative z-[1] mx-auto -mt-12 h-[90px] w-[90px] rounded-full border-2 border-white bg-cream object-cover"
          />

          <h3 className="mt-2 text-xl font-medium">
            {strippedContent(user.name, 30)}
          </h3>
          <span className="text-base text-accent-gray">{getRoleName()}</span>
        </div>
      )}

      {/* Notification */}
      <div className="my-6">
        <h4 className="mb-4 text-xl font-semibold">
          Notifikasi <i className="text-en">Notifications</i>
        </h4>

        {notifications.map((notification, index) => (
          <Link
            key={`notification-${index}`}
            to={`/${getSlugRole()}/notification`}
            className="mb-3 block w-full rounded-[20px] border-2 border-transparent bg-white p-5 transition hover:border-primary-yellow"
          >
            <h5 className="text-base font-medium">{notification.title}</h5>
            <span className="text-sm font-medium text-accent-gray">
              {moment(notification.createdAt).startOf("hour").fromNow()} -{" "}
              {notification.category}
            </span>
          </Link>
        ))}

        {notifications.length ? (
          <Link
            to={`/${getSlugRole()}/notification`}
            className="flex items-center justify-center"
          >
            <img src={ic_list} alt="Icon List" />
            <span className="ml-3 text-sm text-accent-gray">
              Lihat Selengkapnya <i className="text-en">View More</i>
            </span>
          </Link>
        ) : (
          <NotFound />
        )}

        <hr className="mt-5 border border-[#00000030]" />
      </div>

      {/* Activities */}
      <div className="my-6">
        <h4 className="mb-4 text-xl font-semibold">
          Aktivitas <i className="text-en">Activities</i>
        </h4>

        {activities.map((activity, index) => (
          <Link
            key={`activity-${index}`}
            to={`/${getSlugRole()}/activity`}
            className="mb-3 block w-full rounded-[20px] border-2 border-transparent bg-white p-5 transition hover:border-primary-yellow"
          >
            <h5 className="text-base font-medium">{activity.title}</h5>
            <span className="text-sm font-medium text-accent-gray">
              {moment(activity.createdAt).startOf("hour").fromNow()}
            </span>
          </Link>
        ))}

        {activities.length ? (
          <Link
            to={`/${getSlugRole()}/activity`}
            className="flex items-center justify-center"
          >
            <img src={ic_list} alt="Icon List" />
            <span className="ml-3 text-sm text-accent-gray">
              Lihat Selengkapnya <i className="text-en">View More</i>
            </span>
          </Link>
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
};

export default SidebarProfile;
