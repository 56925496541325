import axios from "axios";
import headers from "services/Headers";

const createPatientClinicContinue = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan`,
    form,
    headers()
  );
};

const getPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/student/${id}`,
    headers()
  );
};

const showPatientClinicContinue = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/task/${id}`,
    headers()
  );
};

const getToolsPatientClinicContinue = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/tools`,
    headers()
  );
};

const createPatientClinicContinueActivityJawUpper = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/activity-jaw-upper/${id}`,
    form,
    headers()
  );
};

const createPatientClinicContinueActivityJawUnder = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/activity-jaw-under/${id}`,
    form,
    headers()
  );
};

const updateDataRadiografyPatientClinicContinue = (id, idData, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/date-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const updateValueDataPatientClinicContinue = (id, idData, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/data-patient/${id}/${idData}`,
    form,
    headers()
  );
};

const createActivityMaterialPatientClinicContinue = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/activity-material/${id}`,
    form,
    headers()
  );
};

const createToolPatientClinicContinue = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/pasien-klinik-lanjutan/patient-tools/${id}`,
    form,
    headers()
  );
};

export {
  createPatientClinicContinue,
  getPatientClinicContinue,
  showPatientClinicContinue,
  getToolsPatientClinicContinue,
  createPatientClinicContinueActivityJawUpper,
  createPatientClinicContinueActivityJawUnder,
  updateDataRadiografyPatientClinicContinue,
  updateValueDataPatientClinicContinue,
  createActivityMaterialPatientClinicContinue,
  createToolPatientClinicContinue,
};
