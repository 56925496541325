import axios from "axios";
import headers from "services/Headers";

const createTool = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/administrator/tools`,
    form,
    headers()
  );
};

const getTool = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/administrator/tools/list-tools`,
    headers()
  );
};

const updateTool = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/administrator/tools/${id}`,
    form,
    headers()
  );
};

const deleteTool = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API}/administrator/tools/${id}`,
    headers()
  );
};

export { createTool, getTool, updateTool, deleteTool };
