import axios from "axios";
import headers from "services/Headers";

const createExamOsce = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-osce`,
    form,
    headers()
  );
};

const showExamOsce = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/mahasiswa/ujian-osce/student/${id}`,
    headers()
  );
};

export { createExamOsce, showExamOsce };
