import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import moment from "moment";

// service
import {
  showPatientTypodont,
  createPatientTypodontActivityJawUpper,
  createPatientTypodontActivityJawUnder,
} from "services/Requirement/Mahasiswa";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import InputFolder from "components/Requirement/InputFolder";
import InputStatus from "components/Requirement/InputStatus";
import InputDateTypodont from "components/Form/InputDateTypodont";
import Min from "components/Action/Min";

const PasienTypodontAktivasiRahang = () => {
  const [hasData, setHasData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [aktivasiRahangAtas, setAktivasiRahangAtas] = useState([{ date: "" }]);
  const [aktivasiRahangBawah, setAktivasiRahangBawah] = useState([
    { date: "" },
  ]);

  const changeAktivasiRahangAtas = (value, index) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPatientTypodontActivityJawUpper(searchParams.get("id"), {
            date: moment(value).format("yyy-MM-DD"),
          });

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const addAktivasiRahangAtas = () => {
    const newValues = [...aktivasiRahangAtas];
    newValues.push({ date: "" });

    setAktivasiRahangAtas(newValues);
  };

  const removeAktivasiRahangAtas = (index) => {
    const newValues = [...aktivasiRahangAtas];
    newValues.splice(index, 1);

    setAktivasiRahangAtas(newValues);
  };

  const changeAktivasiRahangBawah = (value, index) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createPatientTypodontActivityJawUnder(searchParams.get("id"), {
            date: moment(value).format("yyy-MM-DD"),
          });

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });

    // setAktivasiRahangBawah(newValues);
  };

  const addAktivasiRahangBawah = () => {
    const newValues = [...aktivasiRahangBawah];
    newValues.push({ date: "" });

    setAktivasiRahangBawah(newValues);
  };

  const removeAktivasiRahangBawah = (index) => {
    const newValues = [...aktivasiRahangBawah];
    newValues.splice(index, 1);

    setAktivasiRahangBawah(newValues);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await showPatientTypodont(searchParams.get("id"));

      setAktivasiRahangAtas(
        response.data.body.data.patient.activity_jaw_upper_typodont
      );
      setAktivasiRahangBawah(
        response.data.body.data.patient.activity_jaw_under_typodont
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TFade>
        <Navbar
          title="Requirement"
          subtitle={
            <span>
              Pasien Typodont <i className="block">Typodont patient</i>
            </span>
          }
        />

        <form className="mb-4 rounded-[20px] bg-white px-4 py-5">
          <div className="grid grid-cols-1 gap-x-10 gap-y-5 md:grid-cols-2">
            <div>
              <InputFolder
                name="Aktivasi Rahang Atas"
                name_en="Maxillary activation"
                className="mb-8 !py-6"
                onClick={() => addAktivasiRahangAtas()}
              />

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangAtas.map((value, index) =>
                  value.id ? (
                    <InputStatus
                      key={`aktivasiRahangAtas-${index}`}
                      name={`Tanggal Aktivasi Rahang Atas ${index + 1}`}
                      name_en={`Date of maxillary activation ${index + 1}`}
                      // value={moment(value.date.split("T")[0]).format(
                      //   "D MMMM yyy"
                      // )}
                      value={moment(value.date).format("D MMMM yyy")}
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <div className="flex gap-3">
                      <InputDateTypodont
                        key={`aktivasiRahangAtas-${index}`}
                        name={`aktivasiRahangAtas-${index}`}
                        value={value.date ? value.date : ""}
                        inputClassName="mb-4 w-full"
                        placeholder={
                          <>
                            Tanggal Aktivasi Rahang Atas {index + 1}
                            <i className="text-en">
                              Date of maxillary activation {index + 1}
                            </i>
                          </>
                        }
                        onChange={(value) =>
                          changeAktivasiRahangAtas(value, index)
                        }
                      />
                      <Min onClick={() => removeAktivasiRahangAtas(index)} />
                    </div>
                  )
                )}
              </div>
            </div>

            <div>
              <InputFolder
                name="Aktivasi Rahang Bawah"
                name_en="Mandibular activation"
                className="mb-8 !py-6"
                onClick={() => addAktivasiRahangBawah()}
              />

              <div className="grid grid-cols-1 gap-y-5">
                {aktivasiRahangBawah.map((value, index) =>
                  value.id ? (
                    <InputStatus
                      key={`aktivasiRahangBawah-${index}`}
                      name={`Tanggal Aktivasi Rahang Bawah ${index + 1}`}
                      name_en={`Date of mandibular activation ${index + 1}`}
                      value={moment(value.date).format("D MMMM yyy")}
                      status={getStatus(value, true)}
                      data={value}
                    />
                  ) : (
                    <div className="flex items-center gap-3">
                      <InputDateTypodont
                        key={`aktivasiRahangBawah-${index}`}
                        name={`aktivasiRahangBawah-${index}`}
                        value={value.date ? value.date : ""}
                        inputClassName="mb-4 w-full"
                        placeholder={
                          <>
                            Tanggal Aktivasi Rahang Bawah {index + 1}
                            <i className="text-en">
                              Date of mandibular activation {index + 1}
                            </i>
                          </>
                        }
                        onChange={(value) =>
                          changeAktivasiRahangBawah(value, index)
                        }
                      />

                      <Min onClick={() => removeAktivasiRahangBawah(index)} />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienTypodontAktivasiRahang;
